import * as React from "react"

import { Box, Item, Button, Icon } from "."
import * as util from "../util"
import { actions } from "../flux"

interface Props {
    item: any
    preview?: boolean
}

interface State {
    session: any
    signup: boolean
    tab: number
    showTag: boolean
}

export default class Detail extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            session: null,
            signup: false,
            tab: 0,
            showTag: false,
        }
    }

    componentDidMount() {
        if (this.props.preview) return
        actions.login.get(session => {
            this.setState({ session })
            if (session && session.profileId) {
                actions.api.meeting.signUpStatus({
                    id: this.props.item.id
                }, res => {
                    this.setState({ signup: res.status })
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState && prevState.tab !== this.state.tab) {
            let root = document.querySelector("body>div#root") as HTMLElement
            if (root) root.scrollTop = 0
        }
    }

    render() {
        let item = this.props.item
        let visible = item.process === "已结束" || this.state.signup
        return (
            <div style={{ background: "white" }}>
                <div style={{ padding: 24, borderBottom: "1px solid #ebebeb" }}>
                    <div style={{ fontSize: 28, lineHeight: "40px", marginTop: -6 }}>
                        {item.title}
                    </div>
                    <Box end>
                        <Item flex style={{ width: "100%" }}>
                            <div className="text-minor" style={{ fontSize: 14, lineHeight: 2, marginTop: "1em" }}>
                                <div>
                                    时间：{util.formatSystemDate(item.startDate)} - {util.formatSystemDate(item.endDate)}
                                </div>
                                <div style={{ display: item.location ? "block" : "none" }}>
                                    地点：{item.location}
                                </div>
                                <div>
                                    主办方：{[...item.hosts].join("、")}
                                </div>
                            </div>
                            <Box center style={{ lineHeight: 1, marginTop: 6, color: "#999", fontSize: 14 }}>
                                标签：
                                <Box flex style={{ overflow: "hidden", flexWrap: this.state.showTag ? "wrap" : "nowrap" }}>
                                    {[...item.tags].map(t =>
                                        <a key={t} className="text-primary border-color-primary nowrap"
                                            href={`/searchall?tab=${util.getEventInfoByPathname()[1] === "/meetings" ? "meetings" : "events"}&query=${encodeURIComponent(t)}`}
                                            target="_blank" style={{
                                                fontSize: 12, borderWidth: 1, borderStyle: "solid", borderRadius: 2,
                                                margin: "3px 10px", lineHeight: "20px", padding: "0 4px", display: "block",
                                            }}>
                                            {t}
                                        </a>
                                    )}
                                </Box>
                                {   
                                    <div 
                                        onClick={() => {
                                            this.setState({
                                                showTag: !this.state.showTag,
                                            })
                                        }}>
                                        <span className="text-gray">
                                            {this.state.showTag ? "收起" : "更多"}
                                        </span>
                                        {this.state.showTag ?
                                            <Icon name="caret up ml05 text-primary" /> :
                                            <Icon name="caret down ml05 text-primary" />
                                        }
                                    </div>
                                }
                            </Box>
                            <div className="text-minor" style={{ fontSize: 14, lineHeight: 1, marginTop: 10 }}>
                                {util.formatTs(item.onlineTime)} 更新
                            </div>
                        </Item>

                        {(item.type === "LIVE" || item.type === "TRAINING" || item.type === "LECTURE") && item.process ? [
                            <div key="2" style={{ minWidth: 24 }} />
                            ,
                            <Button key="0" primary basic
                                style={{
                                    height: 42, width: 114,
                                    display: this.state.session && visible ? "inline" : "none",
                                }}
                                onClick={() => this.setState({ tab: 0 })}>
                                活动介绍
                            </Button>
                            ,
                            <Button key="1" primary style={{ height: 42, width: 114, marginLeft: 12 }}
                                onClick={() => {
                                    if (!this.state.session) {
                                        location.href = "/login?redirect=" + encodeURIComponent(location.pathname)
                                    } else if (visible) {
                                        this.setState({ tab: 1 })
                                    } else {
                                        actions.api.meeting.signUp({ id: item.id }, () => {
                                            this.setState({ signup: true, tab: 1 })
                                            util.toast.success(`您已成功报名该${util.getEventInfoByPathname()[0]}！`, 5000)
                                        })
                                    }
                                }}>
                                {visible ? "进入" + util.getEventInfoByPathname()[0] : "立即报名"}
                            </Button>
                        ] : null}
                    </Box>
                </div>

                <div style={{ padding: 24 }}>
                    <Box style={{ lineHeight: 1, fontSize: 18 }}>
                        <div className="bg-color-primary" style={{ width: 4, marginRight: 10 }} />
                        {this.state.tab === 0 ? "活动介绍" : "活动详情"}
                    </Box>
                    {util.minHeight(16)}
                    {this.state.tab === 0 ?
                        <div className="text-gray user-html">
                            <div style={{ whiteSpace: "normal" }}
                                dangerouslySetInnerHTML={{ __html: util.filterXSS(item.content) }} />
                            {util.wechatQRCode(item.source, {}, item.onlineTime)}
                        </div>
                        :
                        <div className="text-gray user-html">
                            <div style={{ whiteSpace: "normal" }}
                                dangerouslySetInnerHTML={{ __html: util.filterXSS(item.specification) }} />
                            {item.attachments && item.attachments.length ? (
                                <div className="inner-line-height" style={{ marginTop: 16 }}>
                                    {item.attachments.map(att =>
                                        <p key={att.url} style={{ marginTop: ".5em" }}>
                                            <a target="_blank" href={att.url + "?attname=" + att.filename}>
                                                <Icon name={`file ${util.getIconName(att.filename)} outline`}
                                                    style={{ fontSize: "1.2em", marginRight: ".5em" }} />
                                                {att.filename}
                                            </a>
                                        </p>
                                    )}
                                </div>
                            ) : null}
                        </div>
                    }
                </div>
            </div >
        )
    }
}
