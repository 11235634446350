export default `（本协议于2017年11月20日最新修订）
《领研网用户协议》（以下简称“本协议”）作为领研网（以下简称“本网站”）提供服务的依据，确定用户在何种条件、以何种方式使用本网站及本网站的服务（具体载体包括但不限于网页、微信小程序、微信公众平台等）。在使用本网站及相关服务前，您应当注册成为本网站用户。进行用户注册时，请您认真阅读本协议，一旦完成注册即表示您已经知悉并了解本协议，接受本协议的条款约束，本协议当即具有合同效力。如您对本协议的任何条款表示异议，您应当选择不使用本网站。
本协议包括基于本协议制定的各项规则，所有规则为本协议不可分割的一部分，与本协议具有同等效力。本网站有权随时变更本协议并在本页面发布。当您继续使用本网站及相关服务，则视为您完全接受协议的变更。领研网的所有权、运营权、解释权归北京辰星联合科技有限公司所有。

一、服务描述及定义
本网站是科研求职者的科研雇主的信息平台及学术社区。 “本网站”“本平台”“领研”：指领研网，载体包括但不限于网页（www.linkresearcher.com、www.keyanquan.net）、微信小程序、微信公众号等。 “用户”：指具有完全民事行为能力的领研网使用者。用户身份包括“求职者”、“雇主”等。“雇主”是指有用人需求，具有用人资格，在本网站上发布招聘及相关信息的企事业单位或其他组织。“求职者”指利用本网站浏览职位，投递职位信息，上传研究论文，或在社区进行信息交流等行为的个人。 “帐号”：指用户为使用本网站及相关服务而登记注册的用户身份识别信息。用户只有注册帐号才能使用本网站的服务。
二、成为注册用户
（一）用户主体适格
符合下列条件之一且未曾被本网站封禁账户的自然人或者组织可以注册成为本网站用户，但本网站不保证一定能够通过注册。
ㆍ年满十六周岁，并且具有民事权利能力和民事行为能力的自然人。
ㆍ依据中华人民共和国法律、法规、部门规章等规范性文件成立并合法存续的企业、机关、社团、事业单位和其他组织。具有能使用本网站服务的民事权利能力和民事行为能力并且具有合法用人资格。
（二）用户信息完备
用户可能需要提供明确的联系方式、通讯地址、真实姓名或名称、职业单位、职业状态、个人资质文件等信息，方能完成注册或使用本网站的服务。为了合法、合规地为用户提供更好的服务，本网站将不时增加或删除用户需提交的信息。如用户不同意使用提供上述信息，则应当停止使用本网站的服务。
（三）平台审核
用人者成为注册用户，入驻本平台或完成平台认证之前，需经过本平台审核。用人者的经营内容不得违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗，包括但不限于以下情形：
ㆍ涉低俗、淫秽、色情、赌博、毒品、迷信、邪教等信息的推广；
ㆍ涉及兼职欺诈的推广；
ㆍ涉及暴力犯罪的；
ㆍ涉及违反国家民族及宗教政策的。
本网站有权要求经营者提供相应的资质文件（包括但不限于营业执照、经营许可证等），并依据常识及社会大众通常智力水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。对于涉及经营人力资源外包业务、猎头业务、招聘业务等可能与本网站业务存在竞争关系或利益冲突的企业，本网站保留拒绝其注册、继续使用本网站的权利。
三、本网站的使用
用户有权依据协议及本网站发布的相关规则，在本站进行找工作、招聘、发布研究成果，进行学术讨论、交流职业发展等活动，享受本网站的用户福利、接受本网站的咨询信息及其他服务，具体形式包括但不限于上传简历、发布招聘岗位、发布研究论文、在线交流 、社区交流等。用户与平台基于服务各自享有必要的权利，承担相应的义务。
(一)用户承诺
ㆍ用户承诺使用“领研网”仅用于用户自身招聘或找工作以及进行学术交流。
ㆍ为了使用本网站的服务，用户应当提供真实、合法、准确的信息，包括但不限于姓名或名称、个人身份证明、资质证明、通讯地址、职位信息、公司介绍等。用户保证如实描述和及时更新，否则承担因信息不真实、不合法、不准确所造成的任何损失。
ㆍ用户承诺使用以上内容均出于自愿并已得到有效授权，并承担相应责任。
ㆍ用户同意为了进行招聘活动，另一方用户通过本网站的特定功能可以查看上述信息。
ㆍ用户同意本网站对其注册资料进行审查并要求用户对任何问题或疑问做出解释、更正；本网站保留拒绝用户注册并且不做出任何解释、说明或通知的权利。
ㆍ用户承诺知悉并了解基于本协议制定的各项规则，同意所有规则为本协议不可分割的一部分，与本协议具有同等效力。
ㆍ用户应妥善保管自己的账户和密码，该账户在本网站进行的所有操作和活动（包括但不限于信息浏览、上传简历、发布招聘职位、发布研究论文等）均视为用户自身真实的意思表示。任何因用户过错导致的帐号安全问题以及由此引起的损失，均由用户自行承担。
ㆍ用户承诺在使用本网站服务的过程中，严格遵守法律、法规等规范性文件、本网站的各项规则和协议以及社会公序良俗。用户违反上述规范产生的任何责任、损失和法律后果，均由用户承担。
ㆍ用户承诺在使用本网站的服务过程中将最大程度的遵守诚实信用原则。
ㆍ用户承诺一旦使用本网站的服务即视为同意接受来自本网站的信息、资讯，包括但不限于职位信息、研究资讯、活动信息、推广信息等。
ㆍ本网站将不时地指定本网站（所有者）的关联公司或者第三方作为本网站的代理、服务商、技术支持方等，为用户提供本平台相应的服务。用户使用相关服务即同意上述关联公司或第三方公司就提供相关服务事项享有与本网站同等权利，承担相应义务。
(二)用户行为限制
ㆍ用户的行为不得违反法律、行政法规、部门规章、地方性法规等规范性文件，本网站的各项规则和协议以及社会公序良俗。本网站有权依据常识及社会大众通常智力水平做出是否合规的判断而不必须依据公权力机关事后通知、查处、判决等文件。
ㆍ用户不得破坏网站运营秩序，不得从事与本网站服务无关的行为，不得发布除招聘信息、自身简历、研究论文所必需信息外的其他内容。
ㆍ用户不得以虚假信息注册帐号，不得冒用、盗用、借用他人、关联机构或社会名人的名义注册帐号名称。
ㆍ用户在未经本网站同意的情况下，不得转让或授权他人使用其所有的本网站帐号。
ㆍ用户不得使用任何机器人软件、脚本等其他方式，自动访问或登录本网站。
ㆍ用户不得以任何技术手段恶意破坏网站或以大量发布垃圾信息等方式干扰网站运营。
ㆍ用户不得以任何方式对本网站（以及已有或可能发布的相关小程序、微信公众号等平台服务载体）进行反向工程，且在使用过程中需遵守本网站有关数据爬虫、抓取等行为的限制规则。
ㆍ用户不得在未经本网站和招聘找工作行为相对方的同意下，将本网站的任何信息、资料以及在招聘找工作过程中任何信息、资料用作其他商业用途、出售或者传播（包括但不限于以复制、修改、转发、收集、翻译等形式制作复制品、衍生品，或使其公布于众）。
(三)用户信息的收集及使用
1.本协议所称之用户信息包括但不限于：
ㆍ用户注册本网站时，根据应用要求提供的个人信息；
ㆍ在用户使用本网站服务、参加网站活动、或访问网站网页时，网站自动接收并记录的用户浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及用户要求取用的网页记录；
ㆍ用户在使用本网站服务时，发布、上传、评论、撰写的各类信息，包括但不限于简历、职位信息、公司简介、论文信息等。
2. 本网站不会将任何个人信息以出售、交换等方式提供给任何与本网站服务无关的第三方，除非事先得到用户的许可。
3. 本网站有权收集和使用用户在使用本网站服务的过程中产生的数据、使用痕迹等信息，包括但不限于求职者用户数量、求职者学历构成、雇主用户数量、职位行业分布、行业薪资状况、用户活跃度、用户人均在线时长等。为服务用户的目的，领研网可能通过使用用户的个人信息，向用户提供服务，包括但不限于向用户推荐职位、发出活动和服务信息等。用户在享用本网站服务的同时，同意接受本网站服务提供的各类信息服务。
4.本网站承诺不公开或透露用户的密码、姓名、手机号码等在本站的非公开信息，除非因用户本人的需要、法律或其他合法程序的要求、服务条款的改变或修订等。
5.在下述情况，本网站保留部分或全部披露用户个人信息的权利：
ㆍ经用户同意，向第三方披露；
ㆍ受到第三方有关知识产权、名誉权等投诉，本网站有合理理由认定用户行为涉嫌侵权；
ㆍ根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
ㆍ如果用户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；
ㆍ为提供用户所要求的产品和服务，而必须和第三方分享用户的个人信息；
ㆍ其他本网站根据法律或者网站政策认为合适的披露。
(四)找工作行为
ㆍ用户（求职者）可发布找工作相关信息，通过本网站简历模块完善个人简历及相关信息，也可上传独立简历。上述行为均为用户自行操作，本网站不对用户的简历做任何审查、修改或指导。
ㆍ用户发布的找工作相关信息均应遵守法律、行政法规、部门规章等规范性文件，本网站的各项规则和协议以及社会公序良俗，并确保不侵犯任何第三人的合法权益（包括但不限于知识产权、商业秘密等）。
ㆍ本网站不对简历的内容做实质性审查，不保证简历的真实、完整、准确。
ㆍ用户找工作信息不实或违规的，用户自行承担相应的不利后果，包括被用人者不予录用或录用后解除劳动关系等。因此给用人者、本网站或第三人造成损失的，还应承担相应的赔偿责任。
ㆍ本网站将不时举办某些专项活动，用于满足用人者特殊的招聘需求和求职者的职业发展需求。用户可能需要满足一定的要求，如工作经验、技能证书等，经过本网站一定审查，方可获得参与资格。
(五)招聘行为
ㆍ用户（雇主）通过本平台审核并完成注册后，即可发布招聘信息、进行招聘活动。
ㆍ用户在本网站指定位置（包括本网站各级页面，为用人者生成的公司主页等）发布招聘相关信息，包括但不限于职位信息、单位简介（包含公司商标、名称、Logo、文字说明等）、配图等。上述行为均为用户自行操作。
ㆍ用户发布的相关信息均应遵守法律、行政法规、部门规章等规范性文件，本网站的各项规则和协议以及社会公序良俗，并确保不侵犯任何第三人的合法权益（包括但不限于知识产权、商业秘密等）。
ㆍ用人者发布的信息除用于招聘所需的必要信息外不得有其他内容，包括但不限于自身产品或服务的推广、销售等。
ㆍ本网站认为有必要的情况下，有权对用户发布的信息做出审查、指导，并有权要求用户做出解释、更正或说明。
ㆍ本网站不对用户实际经营状况做任何实质审查，不对用户的资信状况做出保证。
ㆍ用户招聘信息不实或违规的，用户自行承担相应的不利后果。因此给候选人、本网站或第三人造成损失的，还应承担相应的赔偿责任。涉及违法或构成刑事犯罪的，依据法律法规承当相应的责任。
ㆍ用户发布的招聘信息违反上述要求或涉及以下内容的，本网站有权对相关信息予以下线、删除或要求整改：非互联网行业职位；涉及性别/地域歧视；涉及薪酬不实、联系方式内容；涉及夸张性描述；职位内容重复描述、无意义描述；多个职位相似；职位要求与职位描述不一致；恶意诋毁、攻击第三人的；代招聘服务等。
(六)论文发布及社区交流行为
ㆍ本网站“论文”、“资讯”频道为本网站下设频道，为用户提供资讯服务、学术成果展示及研讨平台。用户在该板块进行上传、浏览、评论及回复时，均应遵守本用户协议相关规定及《社区管理规范》。
ㆍ用户在本网站“论文”、“资讯”频道上发布的信息均应遵守法律、行政法规、部门规章等规范性文件，本网站的各项规则和协议以及社会公序良俗，并确保不侵犯任何第三人的合法权益（包括但不限于知识产权、商业秘密等）。
ㆍ本网站不对用户上传的论文内容做实质性审查，不保证论文信息的真实、完整、准确。
ㆍ用户上传论文、发布的评论及回复不实或违规的，用户自行承担相应的不利后果，包括被用人者不予录用或录用后解除劳动关系等。因此给用人者、本网站或第三人造成损失的，还应承担相应的赔偿责任。
ㆍ本网站有权对用户发布的信息做出审查、指导、删除、屏蔽等，并要求用户做出相应的修改。
ㆍ用户在本网站“论文”、“资讯”频道上发表的信息为公开的信息（包括实名与匿名信息），其他第三方均可以通过上述途径获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在上述平台进行发表。
ㆍ用户不得利用本网站“论文”、“资讯”频道制作、上载、复制、发布、传播或者转载如下内容：
1.反对宪法所确定的基本原则的；
2.损害国家荣誉和利益的、破坏民族团结的、破坏国家宗教政策，宣扬邪教和封建迷信的、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
3.散布谣言，扰乱社会秩序，破坏社会稳定的；
4.侮辱或者诽谤他人，侵害他人合法权益的；
5.含有法律、行政法规禁止的其他内容的信息；
6.其他本网站认为不合适的内容。
ㆍ用户在本网站“论文”、“资讯”频道上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到本网站的同意。
ㆍ为了促进知识的分享和传播，用户将其在本网站“论文”、“资讯”频道上发表的全部内容，授予本网站免费的、不可撤销的、非独家使用许可，本网站有权将该内容用于本网站各种形态的产品和服务上，包括但不限于网站以及发表的应用或其他互联网产品。
ㆍ第三方若出于非商业目的，将用户在本网站“论文”、“资讯”频道上发表的内容转载在本网站之外的地方，应当在作品正文显著位置注明原作者姓名（或原作者在本网站上使用的帐号名称），给出原始链接，并注明「原载于领研」，且不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。
(七)用户间保密
用户对利用本网站服务进行找工作、招聘和论文上传、学术交流活动，从而获取的其他用户的任何信息，负有保密的义务。保密的程度依据法律法规、用户间的合意以及一般人的合理注意义务决定。
(八)用户间纠纷
ㆍ求职者与雇主之间的线下活动，包括沟通、面试、入职、建立劳动关系等，不受本网站的约束。双方发生纠纷的，依据法律法规、双方合同处理，本网站不负责处理。
ㆍ本网站提供用户反馈渠道，包括网站特定模块或功能，用户可按特定方式表达、反映用户间的纠纷，但本网站不保证做出反馈或协助处理。
(九)用户反馈
ㆍ用户在使用本网站服务的过程中，可通过本网站提供的反馈渠道对本网站及本网站的服务提出任何形式的评价、建议或其他意见。
ㆍ用户的上述反馈应当真实、客观，不得违反法律法规等规范性文件、本网站的各项规则和协议以及社会公序良俗，否则本网站有权进行相应处理。
(十)推广和宣传
除用户特别要求且本网站接受该要求外，本网站有权在不做出任何通知的情况下，在本网站页面或者与本网站有关的市场宣传、推广材料上公开使用用户的公开信息，包括但不限于名称、商号、商标、标语等。本网站承诺上述信息不用于与本网站服务无关的事项上。
四、服务的中断或终止
ㆍ用户在使用本网站服务的所有行为，产生的所有信息，本网站有权但无义务依据法律法规、社会公序良俗、本网站的规则和协议，做出审查。本网站有权中断或终止对违规用户的服务。该用户以其他名义再次注册账户的，本网站有权再次中断或终止对该用户的服务。
ㆍ用户长时间未登录账户超过6个月的，本网站有权中断或终止对该用户的服务。
ㆍ用户申请终止服务的，经本网站同意后可以解除该用户协议。
ㆍ本网站中断或终止对用户提供服务后，有权但并无义务继续保留用户在本网站的全部资料和信息（包括但不限于用户的帐号信息、交易信息等）。
ㆍ中断或终止对用户的服务并不免除用户因违反法律法规、社会公序良俗、本网站的规则和协议，而对本网站、网站其他用户或第三方应负的损失赔偿责任。
五、责任声明
ㆍ本网站作为信息服务与交流平台，所有职位信息、找工作信息、论文信息及学术交流信息均由用户自行上传、发布。本网站对用户的真实资信状况、用户发布信息的真实性、合法性、准确性等不作保证。因上述原因导致的对其他用户或第三方造成的损失，本网站不承担责任。
ㆍ本网站致力于为用户提供优质、稳定的服务。但本网站不保证本网站系统：能够满足所有用户需求；不出现任何的系统宕机、波动；不出现任何的数据丢失。因网络运营商原因、本网站进行网络调整、正常的系统维护升级等造成的本网站无法正常访问，本网站不承担任何责任
ㆍ用户通过本网站获取的任何资料和信息，无论其来自于本网站或任何外部链接，本网站对其不做出任何保证。本网站提供与其它互联网上的网站或资源的链接，用户可能会因此跳转至其它运营商经营的网站，但不表示本网站与这些运营商有任何关系。其它运营商经营的网站均由各经营者自行负责，不属于本网站控制及负责范围之内。对于存在或来源于此类网站或资源的任何内容、广告、物品或其它资料，本网站亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、物品或服务所产生的任何损害或损失，本网站不负任何直接或间接的责任。涉及上述条款中的情形，而造成的用户利润、商誉等损失，本网站不承担任何直接、间接、附带、惩罚性的赔偿。
六、陈述与保证
（一）知识产权
ㆍ本协议条款明确规定的，以及任何与本网站服务有关的内容的知识产权，由本网站或相关权利人所享有。该条款所指内容包括但不限于本网站设计、本网站代码、本网站内容、本网站资讯、用户信息等。该条款所指知识产权包括但不限于专利权、著作权、商标权等。
ㆍ用户应确保本网站的完整性，不得擅自修改本网站，不得反向编译本网站
（二）隐私权
ㆍ本网站承诺不将用户个人信息用于非本网站服务的用途。
ㆍ本网站不向任何第三方提供用户个人信息，无论出于营利或非营利的目的。但属于为用户提供服务所必需，委托第三方为用户提供服务或经过用户同意的除外。
ㆍ因第三方提出的涉及知识产权的权利主张或者行政、司法机关的介入，本网站有权披露用户的个人信息。
ㆍ用户使用本网站所产生的不含个人特征的信息，本网站有权收集和利用，详见本协议“用户信息的收集与使用”部分。
（三）保密义务
用户保证在使用本网站过程中所获悉的属于本网站及他方的且无法自公开渠道获得的文件及资料（包括但不限于个人信息、商业秘密、公司计划、运营活动、财务信息、技术信息、经营信息及其他商业秘密）属于保密信息。对于此等信息，本网站对用户负有保密义务，用户之间也负有保密义务。未经该资料和文件的原提供方同意，任何一方不得向第三方泄露该保密信息的全部或者部分内容。但法律、法规、行政规章另有规定或者双方另有约定的除外。
七、协议的更新及修改
本网站保留随时修正、更新本协议的权利。一旦发生相关修订或更新，本网站会将修订和更新的内容及时在本页面发布，用户如认为变更无法接受，应该停止使用本网站相关服务。如果继续使用本网站相关服务的，则视为用户接受变更条款并愿意受其约束。
八、协议解除、终止
有以下情形的，本协议归于解除或终止：
ㆍ本网站基于法律法规等规范性文件、本网站的各项规则和协议以及社会公序良俗，终止对用户提供服务的。该情形的协议终止并不免除用户应承担的相应责任。
ㆍ一方主体归于消灭、撤销等无法有效存续状态的。依据法律法规的规定处理未决问题。
ㆍ因不可抗力使本协议无法继续履行或者没有履行必要和意义。不可抗力是指不能遇见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。
ㆍ本协议终止或更新时，用户不接受新的协议。用户应当立即停止使用本网站的服务。
九、违约责任
ㆍ任何一方违反本协议约定的行为均构成违约行为，均应承担相应的责任。
ㆍ用户未按照本协议约定使用本网站的服务，本网站有权对用户停止服务或对用户发布的信息进行删除。
ㆍ对于用户的任何违反本协议规定的行为，本网站有权采取相应处理措施，方式包括但不限于记录不良行为、调整用户信用等级、黑白名单限制、封禁用户帐号等，形式包括系统后台记录或网站公示公开。该等处理措施将影响用户使用本网站的服务，涉及违法、犯罪的，本网站将移交司法机关处理。
ㆍ遭受不可抗力事件的一方可暂行中止履行本合同项下的义务直至不可抗力事件的影响消除为止，并且无需为此而承担违约责任；但应尽最大努力克服该事件，减轻其负面影响。
十、其他
ㆍ领研网拥有对本协议的最终解释权。
ㆍ用户与本平台的任何纠纷，可以通过协商的途径解决。协商不成的，任何一方可向本网站登记住所地的人民法院提起诉讼。
ㆍ本协议的订立、生效、解释、执行、管辖、争议的解决均适用中华人民共和国法律。
`
