import * as React from "react"

import * as util from "../util"
import { Item, Box, Button } from "./index"

interface Props {
    title: string
    addon?: any
    addonAction?: any
    style?: any
}

interface State {
}

export function tab(that, i: number, title: string, onChange?) {
    return (
        <div className={(that.state.tab === i ? "text-primary" : "text-gray") + " clickable " +
            (that.state.tab === i ? "border-color-primary" : "border-color-none")}
            style={{ lineHeight: "3.75rem", borderBottomWidth: 2, borderBottomStyle: "solid", zIndex: 1 }}
            onClick={() => that.setState({ tab: i }, () => {
                if (onChange) onChange(i)
            })}>
            {title}
        </div>
    )
}

export function tabHeader(that, titles: any[], onChange?) {
    let tabs = [tab(that, 0, titles[0], onChange)]
    for (let i = 1; i < titles.length; i++) {
        tabs.push(util.minWidth("2em"))
        tabs.push(<div className="bg-color-2" style={{ width: 1, height: "2rem" }} />)
        tabs.push(util.minWidth("2em"))
        tabs.push(tab(that, i, titles[i], onChange))
    }
    return (
        <Box center relative centerJustified style={{ height: "3.75rem" }}>
            {tabs}
            <div className="bg-color-2"
                style={{ height: 1, position: "absolute", left: 0, width: "100%", bottom: -1 }} />
        </Box>
    )
}

export function tabHeaderLeft(that, titles: string[], onChange?) {
    let tabs = [
        util.minWidth("1.25rem"),
        tab(that, 0, titles[0], onChange),
    ]
    for (let i = 1; i < titles.length; i++) {
        tabs.push(util.minWidth("2rem"))
        tabs.push(<div className="bg-color-2" style={{ width: 1, height: "2rem" }} />)
        tabs.push(util.minWidth("2rem"))
        tabs.push(tab(that, i, titles[i], onChange))
    }
    return (
        <Box center relative style={{ height: "3.75rem" }}>
            {tabs}
            <div className="bg-color-2"
                style={{ height: 1, position: "absolute", left: 0, width: "100%", bottom: -1 }} />
        </Box>
    )
}

export default class SettingPanel extends React.Component<Props, State> {
    render() {
        let { title, addon, addonAction, style } = this.props
        return (
            <Box centerJustified relative>
                <div style={{ width: "48.75rem" }}>
                    {util.minHeight("2rem")}
                    <Box center className="panel2" style={{ height: "3.75rem", fontWeight: 700 }}>
                        {addon ? (
                            <Button size="mini" style={{ marginLeft: "1.875rem", opacity: 0, cursor: "default" }}>
                                {addon}
                            </Button>
                        ) : null}
                        <Item flex />
                        {title}
                        <Item flex />
                        {addon ? (
                            <Button primary size="mini" style={{ marginRight: "1.875rem" }} onClick={addonAction}>
                                {addon}
                            </Button>
                        ) : null}
                    </Box>
                    {util.minHeight("1.25rem")}
                    <Item relative className="panel2" style={style}>
                        {this.props.children}
                    </Item>
                    {util.minHeight("2.5rem")}
                </div>
            </Box>
        )
    }
}
