import * as React from "react"

import * as OPTIONS from "../misc/options"
import * as cache from "../util/cache"
import * as util from "../util"
import Detail from "./JobDetail2"
import FormChecker, { ErrorTooltip } from "./FormChecker"
import Modal from "./Modal"
import { Icon, Box, Item, Button, elements } from "./index"
import { actions } from "../flux"

interface Props {
    job: any
    onFinish: any
    adminMode?: boolean     // 普通管理员
    hideCancel?: boolean
    floatLeft?: boolean
    subjects: string[]
}

interface State {
    preview: boolean
    form: any
    editor: any
    employer: any
    reload: boolean
    descriptionEmails: string[]
}

export default class Edit extends React.Component<Props, State> {
    formChecker = new FormChecker()

    constructor(props: Props) {
        super(props)
        this.state = {
            preview: false,
            form: this.props.job.id ? this.props.job :
                (location.hash === "#/Crawler" ? this.props.job : cache.ensure("JobEdit", this.props.job)),
            editor: null,
            employer: null,
            reload: false,
            descriptionEmails: this.props.job && this.props.job.description ?
                this.parseEmailsFromHTML(this.props.job.description) : [],
        }
    }

    componentDidMount() {
        if (!this.props.job.id && location.hash !== "#/Crawler") {
            setInterval(() => cache.set("JobEdit", this.state.form), 1000)
        }
        actions.api.employer.get({ id: this.props.job.employerId }, resp => {
            this.setState({ employer: resp }, () => this.loadEditor())
        })
    }

    loadEditor() {
        this.setState({
            editor: util.createWangEditor(this, "editor", "form.description"),
        }, () => {
            this.state.editor.txt.html(this.state.form.description)
        })
    }

    parseEmailsFromHTML(html) {
        let emails: string[] = []
        let root = document.createElement("span")
        root.innerHTML = html
        let innerText = root.innerText
        let regex = /[a-zA-Z0-9_.+-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
        let match
        while (match = regex.exec(innerText)) emails.push(match[0])
        return emails
    }

    render() {
        (window as any).JobEdit = this.state
        if (this.state.reload) {
            this.setState({ reload: false }, () => this.loadEditor())
            return null
        }

        let employer = this.state.employer
        if (!employer) return null
        this.state.form.employerType = employer.type

        let that = this
        let isCollege = employer.type === "COLLEGE"
        let isAdmin = location.pathname === "/admin"
        let isSuperAdmin = isAdmin && !this.props.adminMode
        let isModify = !!this.state.form.id

        let space1 = util.minHeight("1rem")
        let space2 = util.minHeight("1.5rem")

        let deadline = util.formatTs(Date.now() + 864e5 * 365, "%Y-%m-%d")
        if (!this.state.form.salaryType) this.state.form.salaryType = "range"
        if (!this.state.form.attachments) this.state.form.attachments = []

        let edit = this.state.preview ? null : (
            <div className="hint-red" style={{ padding: "2.5rem" }}>
                <div style={{ position: "relative" }}>
                    {this.props.adminMode ? (
                        <div style={{
                            position: "absolute",
                            width: "100%",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            background: "rgba(255,255,255,0.5)",
                            zIndex: 20,
                        }} />
                    ) : null}
                    <Box>
                        <div style={{ width: "25rem" }}>
                            <Box center style={{ fontSize: "1.05rem", fontWeight: 700 }}>
                                发布职位
                                <Item flex />
                                {isModify ? null : (
                                    <span className="clickable" style={{ fontSize: ".85rem", color: "#999" }}
                                        onClick={() => {
                                            this.setState({
                                                form: { id: this.state.form.id, employerId: this.state.form.employerId },
                                                reload: true,
                                            })
                                        }}>
                                        <Icon name="undo" className="mr05" />
                                        清除全部
                                    </span>
                                )}
                            </Box>
                            {space1}
                            {space2}
                            <ErrorTooltip checker={this.formChecker} id="form.name"
                                getTip={() => this.state.form.name ? null : "必填"} />
                            {elements.settingTitle(true, "招聘职位名称", "（限填一个职位，提交后不能修改）")}
                            {space1}

                            {!isCollege || isModify ?
                                [
                                    elements.formInput2(this, "form.name", {
                                        disabled: isModify && !isSuperAdmin,
                                        onBlur: () => that.state.form.name = (that.state.form.name || "").trim()
                                    }),
                                    space1,
                                    <div className="text-minor" style={{ fontSize: ".75rem", marginLeft: "1.25rem" }}>
                                        限填一个职位
                                    </div>
                                ] : [
                                    elements.formSelect2(this, "form._name", {
                                        _onChange(e, { value }) {
                                            that.state.form.name = value === "_" ? "" : value
                                            that.setState({})
                                        },
                                        options: [
                                            { "value": "博士" },
                                            { "value": "博士后" },
                                            { "value": "科研助理" },
                                            { "value": "讲师/助理研究员" },
                                            { "value": "副研究员/副教授" },
                                            { "value": "研究员/教授" },
                                            { "value": "学科带头人" },
                                            { "value": "技术人员" },
                                            { "value": "行政人员" },
                                            { "value": "_", text: "其他" },
                                        ]
                                    }),
                                    this.state.form._name === "_" ? space1 : null,
                                    this.state.form._name === "_" ? (
                                        elements.formInput2(this, "form.name", {
                                            onBlur: () => that.state.form.name = (that.state.form.name || "").trim()
                                        })
                                    ) : null,
                                ]
                            }
                            {space2}
                            <ErrorTooltip checker={this.formChecker} id="form.form"
                                getTip={() => this.state.form.form ? null : "必填"} />
                            <Box center className="nowrap">
                                {elements.settingTitle(true, "职位性质", isAdmin ? "" : "（提交后不能修改）")}
                                {util.minWidth("1.25rem")}
                                <span style={{ fontSize: ".85rem" }}>
                                    {isModify && !isAdmin ? this.state.form.form : elements.radioGroup(that, "form.form", [
                                        { value: "全职" },
                                        { value: "兼职" },
                                        { value: "实习" },
                                    ])}
                                </span>
                            </Box>
                            {space2}
                            {elements.settingTitle(
                                false, "所属二级部门",
                                "（不要与招聘主体重复，提交后不能修改）",
                                { className: "nowrap" }
                            )}
                            {space1}
                            {elements.formInput2(this, "form.department", {
                                disabled: isModify && !isSuperAdmin,
                                onBlur: () => that.state.form.department = (that.state.form.department || "").trim()
                            })}
                            {!isCollege ? [
                                space2,
                                <ErrorTooltip checker={this.formChecker} id="form.classification"
                                    getTip={() => this.state.form.classification ? null : "必填"} />,
                                elements.settingTitle(true, "职位类型", ""),
                                space1,
                                elements.formSelect2(this, "form.classification", {
                                    options: [
                                        { value: "技术" },
                                        { value: "研发" },
                                        { value: "运营" },
                                        { value: "销售" },
                                        { value: "编辑" },
                                        { value: "其他" },
                                    ]
                                })
                            ] : null}
                        </div>
                        <Item flex />
                        <div className="inner-line-height text-center" style={{ width: "7.5rem" }}>
                            {util.imageCenter(employer.logo, {
                                height: "7.5rem",
                                width: "7.5rem",
                            })}
                            {util.minHeight("1.25rem")}
                            <p style={{ fontWeight: 700, fontSize: ".95rem" }}>{employer.name}</p>
                            {util.minHeight("1.25rem")}
                            <p style={{ fontSize: ".8rem" }}>
                                {employer.type === "COLLEGE" ? "高校院所" : "其他"}
                            </p>
                        </div>
                    </Box>
                    {!isCollege ? [
                        space2,
                        <ErrorTooltip checker={this.formChecker} id="form.industries"
                            getTip={() => this.state.form.industries && this.state.form.industries.length ? null : "必填"} />,
                        elements.settingTitle(true, "职位所属行业（多选）", "（提交后不能修改）"),
                        space1,
                        isModify ? (
                            <div style={{ paddingLeft: "1.25rem", fontSize: ".85rem" }}>
                                {this.state.form.industries.join("，")}
                            </div>
                        ) :
                            elements.checkboxGroup2(this, "form.industries", [
                                "生物医学", "化工材料", "油汽新能源", "保险", "教育培训", "制造业", "大众传媒",
                                "学术出版", "咨询服务", "建筑规划", "金融投资", "消费零售", "人工智能",
                                "数据挖掘", "政府机关", "互联网",
                            ].map(v => ({ value: v })), true)
                    ] : null}
                    {isCollege ? [
                        space2,
                        elements.settingTitle(false, "职位所属的引进人才计划（多选）", ""),
                        space1,
                        elements.checkboxGroup2(this, "form.talentPlans", [
                            "院士", "长江学者", "千人计划", "青年千人", "杰青", "优青", "领军人才",
                        ].map(v => ({ value: v })), true),
                    ] : null}
                    {isCollege ? [
                        space2,
                        <ErrorTooltip checker={this.formChecker} id="form.subjects"
                            getTip={() => this.state.form.subjects && this.state.form.subjects.length ? null : "必填"} />,
                        elements.settingTitle(true, "职位所属的学科大类（多选）", "（提交后不能修改）"),
                        space1,
                        isModify && !isSuperAdmin ? (
                            <div style={{ paddingLeft: "1.25rem", fontSize: ".85rem" }}>
                                {this.state.form.subjects.join("，")}
                            </div>
                        ) :
                            elements.checkboxGroup2(this, "form.subjects", this.props.subjects.map(v => ({ value: v })), true, true)
                    ] : null}
                    <div style={{ width: "25rem" }}>
                        {isCollege ? [
                            space2,
                            <ErrorTooltip checker={this.formChecker} id="form.researchFields"
                                getTip={() => this.state.form.researchFields && this.state.form.researchFields.length ? null : "必填"} />,
                            elements.settingItem2(
                                this, "form.researchFields", true,
                                "研究方向", "（提交后不能修改）", {
                                    type: "tags",
                                    disabled: isModify && !!this.state.form.lastAuthorId && !isSuperAdmin,
                                }
                            )
                        ] : null}
                        {space2}
                        <ErrorTooltip checker={this.formChecker} id="form.requirements"
                            getTip={() => {
                                if (this.state.form.requirement) return null
                                if (!this.state.form.requirements) return "必填"
                                if (this.state.form.requirements.length === 0) return "必填"
                                return null
                            }} />
                        {this.state.form.requirement ?
                            (
                                <Item relative>
                                    {elements.settingItem2(
                                        this, "form.requirement", true,
                                        "职位要求的专业或背景", "", {}, "",
                                    )}
                                    <div className="text-primary clickable"
                                        style={{ position: "absolute", fontSize: ".85rem", bottom: "1em", right: "-7em" }}
                                        onClick={() => {
                                            this.state.form.requirements = this.state.form.requirement.split(/[,;，；、]/)
                                            this.state.form.requirement = ""
                                            this.setState({})
                                        }}>
                                        拆分成标签
                                    </div>
                                </Item>
                            ) : (
                                elements.settingItem2(
                                    this, "form.requirements", true,
                                    "职位要求的专业或背景", "", { type: "tags" }, "",
                                )
                            )}
                        {space2}
                        <ErrorTooltip checker={this.formChecker} id="form.minDegree"
                            getTip={() => this.state.form.minDegree ? null : "必填"} />
                        {elements.settingTitle(true, "职位要求的最低学历", "")}
                        {space1}
                        {elements.formSelect2(this, "form.minDegree", {
                            options: OPTIONS.DEGREE,
                        })}
                        {space2}
                        <ErrorTooltip checker={this.formChecker} id="form.experience"
                            getTip={() => this.state.form.experience ? null : "必填"} />
                        {elements.settingTitle(true, "工作经验", "")}
                        {space1}
                        {elements.formSelect2(this, "form.experience", {
                            options: OPTIONS.EXPERIENCE.slice(0, isCollege ? 2 : 99),
                        })}
                        {space2}
                        <ErrorTooltip checker={this.formChecker} id="form.region"
                            getTip={() => this.state.form.region ? null : "必填"} />
                        {elements.settingTitle(true, "职位所在地域", "")}
                        {space1}
                        {elements.formSelect2(this, "form.region", {
                            options: [
                                { value: "大陆" },
                                { value: "港澳台" },
                                { value: "海外" },
                            ]
                        })}
                        {space2}
                        <ErrorTooltip checker={this.formChecker} id="form.city"
                            getTip={() => this.state.form.city ? null : "必填"} />
                        {elements.settingTitle(true, "职位所在城市", "")}
                        {space1}
                        {elements.formInput2(this, "form.city", {})}
                    </div>
                    {space2}
                    <ErrorTooltip checker={this.formChecker} id="form.salaryType"
                        getTip={() => {
                            if (this.state.form.maxSalary) this.state.form.maxSalary = parseFloat(this.state.form.maxSalary)
                            if (this.state.form.minSalary) this.state.form.minSalary = parseFloat(this.state.form.minSalary)
                            if (this.state.form.salaryType === "negotiate") {
                                return null
                            } else if (!this.state.form.maxSalary && this.state.form.maxSalary !== 0) {
                                return "请填写年薪范围"
                            } else if (!this.state.form.minSalary && this.state.form.minSalary !== 0) {
                                return "请填写年薪范围"
                            } else if (this.state.form.minSalary > this.state.form.maxSalary) {
                                return "无效的年薪范围"
                            } else {
                                return null
                            }
                        }} />
                    {elements.settingTitle(true, "年薪（税前）", "")}
                    {space1}
                    <div style={{ padding: "0 1.25rem", fontSize: ".85rem" }}>
                        {elements.radioGroup(this, "form.salaryType", [{
                            text: (
                                <span style={{ display: "inline-block" }}>
                                    <Box center>
                                        年薪范围
                                    {util.minWidth("1.25rem")}
                                        {elements.formInput(this, "form.minSalary", {
                                            type: "parseFloat",
                                            placeholder: "年薪下限",
                                            style: { minWidth: "6.4rem" },
                                            _onChange: util.rangeFix(0, 1e8),
                                        })}
                                        {util.minWidth(".625rem")}
                                        万
                                    {util.minWidth(".625rem")}
                                        {util.textMinor("—")}
                                        {util.minWidth(".625rem")}
                                        {elements.formInput(this, "form.maxSalary", {
                                            type: "parseFloat",
                                            placeholder: "年薪上限",
                                            style: { minWidth: "6.4rem" },
                                            _onChange: util.rangeFix(0, 1e8),
                                        })}
                                        {util.minWidth(".625rem")}
                                        万
                                    <div style={{ minWidth: "1.25rem", marginRight: "-0.5em" }} />
                                    </Box>
                                </span>
                            ),
                            value: "range",
                        }, {
                            text: "面议",
                            value: "negotiate",
                        }])}
                    </div>
                    {space2}
                    {elements.settingItem2(
                        this, "form.benefit", false, "福利待遇", "", {
                            type: "textarea",
                            placeholder: "如五险一金，补充医保，解决子女上学等，不同条目间用逗号隔开",
                        },
                    )}
                    {space2}
                    {elements.settingItem2(
                        this, "form.advantage", false, "职位亮点", "", {
                            placeholder: "如待遇优厚，发展空间大，有出国机会等，不同条目间用逗号隔开",
                        },
                    )}
                    {space2}
                    <ErrorTooltip checker={this.formChecker} id="form.description"
                        getTip={() => {
                            if (!this.state.form.description) {
                                return "必填"
                            } else if (util.containsEmail(this.state.form.description)) {
                                return "不能出现邮箱"
                            } else {
                                return null
                            }
                        }} />
                    {elements.settingTitle(
                        true, "职位的更多描述与要求",
                        "（请您不要输入邮箱、联系电话、薪资面议、性别歧视等字样，管理员发现后将下线该职位）"
                    )}
                    {space1}
                    <div style={{ paddingLeft: "1.25rem" }}>
                        <div ref="editor">
                            <p></p>
                        </div>
                    </div>
                    {space2}
                    {elements.settingTitle(false, "附件", "")}
                    {space1}
                    {elements.uploadAttachments(this)}
                    {space2}
                    <ErrorTooltip checker={this.formChecker} id="form.applicationMaterial"
                        getTip={() => {
                            if (!this.state.form.applicationMaterial) {
                                return null
                            } else if (util.containsEmail(this.state.form.applicationMaterial)) {
                                return "不能出现邮箱"
                            } else {
                                return null
                            }
                        }} />
                    {elements.settingItem2(
                        this, "form.applicationMaterial", false, "申请材料", "",
                        { type: "textarea" },
                    )}
                    {space2}
                    {isAdmin ? [
                        elements.settingItem2(
                            this, "form.applicationEmail", false, "申请方式", "",
                            { type: "textarea" },
                        ),
                        space2
                    ] : null}
                    <ErrorTooltip checker={this.formChecker} id="form.redirectEmails"
                        getTip={() => {
                            this.state.form.redirectEmails = this.state.form.redirectEmails || []
                            if (this.state.form.redirectEmails.length === 0) return "必填"
                            let ok = true
                            this.state.form.redirectEmails.forEach((email, i) => {
                                this.state.form.redirectEmails[i] = (email || "").trim()
                                if (!util.isEmail(email)) ok = false
                            })
                            return ok ? null : "无效的邮箱格式"
                        }} />
                    {elements.settingItem2(
                        this, "form.redirectEmails", true, "简历自动转发邮箱", "", {
                            type: "tags",
                        }
                    )}
                    {space2}
                    {elements.settingItem2(
                        this, "form.applicationPhone", false, "联系电话（选填）", "",
                    )}
                    {space2}
                    {elements.settingItem2(
                        this, "form.applicationAddress", false, "联系地址（选填）", "",
                    )}
                    {space2}
                    <ErrorTooltip checker={this.formChecker} id="form.applicationDeadline"
                        getTip={() => {
                            if (!this.state.form.applicationDeadline) {
                                return "必填"
                            } else if (this.state.form.applicationDeadline > deadline) {
                                return "截止日期超出允许范围"
                            } else if (this.state.form.applicationDeadline < util.formatTs(Date.now(), "%Y-%m-%d")) {
                                return "不能选择过去的日期"
                            } else {
                                return null
                            }
                        }} />
                    {elements.settingItem2(
                        this, "form.applicationDeadline", true, "申请截止日期",
                        `（职位有效期最多一年，请您选择不晚于${deadline.replace(/-/g, "/")}的日期）`,
                        { type: "calendar" }, ""
                    )}
                    {space2}
                    {elements.settingItem2(
                        this, "form.wxShareTitle", false, "微信分享标题（选填）", "", {
                            placeholder: util.fullEmployerName(employer.name, this.state.form.department) + "招聘" + this.state.form.name,
                            onFocus(e) {
                                if (!that.state.form.wxShareTitle) {
                                    that.state.form.wxShareTitle = e.target.placeholder
                                    that.setState({})
                                }
                            }
                        }
                    )}
                    {space2}
                    {elements.settingItem2(
                        this, "form.wxShareDescription", false, "微信分享描述（选填）", "", {
                            placeholder: `年薪：${util.salaryText(this.state.form)}；${util.researchFields(this.state.form)} - 分享自领研`,
                            onFocus(e) {
                                if (!that.state.form.wxShareDescription) {
                                    that.state.form.wxShareDescription = e.target.placeholder
                                    that.setState({})
                                }
                            }
                        }
                    )}
                </div>
                <div style={{ width: "25rem" }}>
                    {isAdmin ? this.props.children : null}
                </div>
                {util.minHeight("2.5rem")}
                <Box center>
                    {util.minWidth("1.25rem")}
                    <Button primary size="small" style={{ width: "11rem" }}
                        onClick={() => {
                            this.formChecker.check(() => {
                                this.setState({ preview: true }, () => {
                                    (document.querySelector("#root") as HTMLElement).scrollTop = 0
                                    if (!this.state.form.id) setTimeout(() => (this.refs as any).checkFields.show(), 250)
                                })
                            }, () => {
                                let error = document.querySelector(".form-error") as HTMLElement
                                error.scrollIntoView()
                            })
                        }}>
                        提交
                    </Button>
                    {util.minWidth("1.25rem")}
                    {this.props.hideCancel ? null : [
                        <Button basic style={{ width: "11rem" }} onClick={this.props.onFinish}>
                            取消
                        </Button>
                        ,
                        util.minWidth("1.25rem")
                    ]}
                    <div className="text-primary clickable"
                        onClick={() => {
                            this.formChecker.check(() => {
                                this.setState({ preview: true }, () => {
                                    (document.querySelector("#root") as HTMLElement).scrollTop = 0
                                    if (!this.state.form.id) setTimeout(() => (this.refs as any).checkFields.show(), 250)
                                })
                            }, () => {
                                let error = document.querySelector(".form-error") as HTMLElement
                                error.scrollIntoView()
                            })
                        }}>
                        <u>预览</u>
                    </div>
                </Box>
            </div>
        )

        let preview = this.state.preview ? [
            <Box centerJustified className="bg-color-primary">
                <Box center centerJustified style={{ fontSize: ".95rem" }}>
                    <div style={{ color: "white", lineHeight: 3, margin: "0 0.5em" }}>
                        确认职位信息无误后请点击
                    </div>
                    <div className="line-height text-primary clickable"
                        style={{ padding: ".5em 1em", background: "white", borderRadius: 3, fontSize: ".9em" }}
                        onClick={() => {
                            if (isCollege) {
                                this.state.form.classification = null
                                this.state.form.industries = null
                            } else {
                                this.state.form.talentPlans = null
                                this.state.form.subjects = null
                                this.state.form.researchFields = null
                            }
                            if (this.state.form.id) {
                                actions.api.job.update(this.state.form, this.props.onFinish)
                            } else {
                                actions.api.job.create(this.state.form, this.props.onFinish)
                            }
                        }}>
                        确认提交
                    </div>
                    <div style={{ color: "white", lineHeight: 3, margin: "0 0.5em" }}>
                        ，如需修改请点击
                    </div>
                    <div className="line-height text-primary clickable"
                        style={{ padding: ".5em 1em", background: "white", borderRadius: 3, fontSize: ".9em" }}
                        onClick={() => this.setState({ preview: false }, () => this.loadEditor())}>
                        返回编辑
                    </div>
                </Box>

                <Modal ref="checkFields" innerWidth="30rem" title="发布职位" confirmText="预览"
                    content={
                        <div className="text-center text-gray" style={{ lineHeight: 2 }}>
                            建议您先预览职位，重点检查
                            <b> 职位名称、职位性质 </b>
                            这两项内容，提交后不可修改！
                        </div>
                    } />
            </Box>
            ,
            this.props.floatLeft ?
                <Box centerJustified style={{ background: "#fff", paddingBottom: 24 }}>
                    <Item flex style={{ maxWidth: 850 }}>
                        <Detail item={this.state.form} employer={this.state.employer} preview />
                    </Item>
                </Box>
                :
                <Box centerJustified style={{ paddingBottom: 24 }}>
                    <div style={{ width: 850 }}>
                        <Detail item={this.state.form} employer={this.state.employer} preview />
                    </div>
                </Box>
        ] : null

        return this.state.preview ? preview : (
            <Box centerJustified className="bg-color-1" style={{ padding: "2.5rem" }}>
                <div className="panel2 line-height" style={{ width: "48.75rem" }}>
                    {edit}
                </div>
            </Box>
        )
    }
}
