import * as React from "react"
import * as ReactDOM from "react-dom"

import * as util from "../util"
import { Box, Item, Button, TextArea } from "."
import { actions } from "../flux"

interface Props {
    id: string
    style?: React.CSSProperties
    onCommentUpdate?: any
}

interface State {
    comments: any[]
    total: number
    newComment: string
    anonymous: boolean
    session: any
}

export default class Comments extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            comments: [],
            total: 0,
            newComment: localStorage && localStorage.getItem(this.localStorageKey()) || "",
            anonymous: false,
            session: undefined,
        }
    }

    componentDidMount() {
        actions.login.get(res => {
            this.setState({ session: res })
        }, () => {
            this.setState({ session: null })
        })
        actions.api.thesis.comments({
            id: this.props.id,
            from: 0,
            size: 1000,
        }, res => {
            this.setState({ comments: res.hits, total: res.total })
            if (this.props.onCommentUpdate) this.props.onCommentUpdate(res)
        })

        let root = ReactDOM.findDOMNode(this.refs.root) as HTMLElement
        if (this.state.newComment) {
            setTimeout(() => root.scrollIntoView(), 1000)
        }
    }

    localStorageKey() {
        return "comment:" + this.props.id
    }

    render() {
        let session = this.state.session
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId

        return (
            <div id="comments" style={{ padding: 24, background: "white", ...this.props.style }}>
                <div ref="root" style={{ height: 98, marginTop: -98 }} />
                <TextArea ref="textarea" rows={3} placeholder="留下你的评论吧"
                    value={this.state.newComment} onChange={(_, data: any) => {
                        this.setState({ newComment: data.value })
                        if (localStorage) localStorage.setItem(this.localStorageKey(), data.value)
                    }}
                    style={{
                        background: "#f5f5f5", padding: "10px 15px", fontSize: 14,
                        width: "100%", border: "none", lineHeight: "24px",
                    }} />

                <Box center style={{ marginTop: 14, fontSize: 14 }}>
                    {session ? null :
                        <div>
                            <a className="text-primary" href={"/login?redirect=" + encodeURIComponent(location.pathname)}>
                                登录
                            </a>
                            <span className="text-minor">后查看</span>
                        </div>
                    }
                    <Item flex />
                    <i className={`fa fa${this.state.anonymous ? "-check" : ""}-square-o mr05`}
                        style={{ fontSize: 16, marginTop: 2 }}
                        onClick={() => this.setState({ anonymous: !this.state.anonymous })} />
                    <span className="text-gray">匿名</span>
                    <Button primary size="mini" style={{ marginLeft: 20, borderRadius: 1 }}
                        disabled={!session || !this.state.newComment || !this.state.newComment.trim()}
                        onClick={() => {
                            if (isVisitor) {
                                location.href = "/join"
                            }
                            actions.api.thesis.comment({
                                id: this.props.id,
                                contextId: location.pathname.substr(1),
                                content: this.state.newComment,
                                anonymous: this.state.anonymous,
                            }, () => {
                                if (localStorage) localStorage.removeItem(this.localStorageKey())
                                location.reload()
                            })
                        }}>
                        发表评论
                    </Button>
                </Box>

                {this.state.total > 0 ? (
                    <div style={{ marginTop: 30 }}>
                        <div style={{ fontSize: 16, lineHeight: 1 }}>
                            最新评论
                        </div>
                        {this.state.comments.map((c, i) =>
                            session === undefined ? null :
                                <Comment key={c.id} index={i} comment={c} session={session} />
                        )}
                    </div>
                ) : null}
            </div>
        )
    }
}

interface CommentProps {
    index: number
    comment: any
    session: any
}

interface CommentState {
    senderName: string
    senderAvatar: String
}

class Comment extends React.Component<CommentProps, CommentState> {
    constructor(props) {
        super(props)
        this.state = {
            senderAvatar: "",
            senderName: "",
        }
    }

    componentDidMount() {
        let comment = this.props.comment
        if (comment.anonymous && (!this.props.session || comment.senderId !== this.props.session.profileId)) {
            this.setState({
                senderAvatar: "",
                senderName: "匿名用户" + (parseInt(comment.senderId) + 1),
            })
        } else {
            actions.api.nickname.get({
                id: comment.senderId
            }, res => {
                this.setState({
                    senderAvatar: res.avatar,
                    senderName: res.nickname,
                })
            })
        }
    }

    render() {
        return (
            <Box
                style={{
                    paddingTop: 16,
                    borderTop: this.props.index > 0 ? "1px solid #ebebeb" : "none",
                    marginTop: this.props.index > 0 ? 20 : 10,
                }}>
                <div
                    style={{
                        height: 40, width: 40, fontSize: 40, color: "#290382", textAlign: "center",
                        borderRadius: 999, marginTop: 5,
                        backgroundImage: `url(${this.state.senderAvatar})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                    }}>
                    <i className="fa fa-user-secret" style={{ opacity: this.state.senderAvatar ? 0 : 1 }} />
                </div>
                {util.minWidth(15)}
                <Item flex style={{ overflow: "hidden", fontSize: 14, lineHeight: "24px" }}>
                    <div className="ellipsis">
                        <span className="text-gray">
                            {this.state.senderName}
                        </span>
                        &nbsp;
                        &middot;
                        &nbsp;
                        <span className="text-minor">
                            {util.formatTs(this.props.comment.createdAt)}
                        </span>
                    </div>
                    <div style={{ whiteSpace: "pre-wrap", marginTop: 6 }}>
                        {this.props.comment.content}
                    </div>
                </Item>
            </Box>
        )
    }
}
