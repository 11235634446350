import * as React from "react"

import Modal from "./Modal"
import TERMS from "../misc/terms"

interface IProps {
    isVisitor?: boolean
    style?: any
}

export default class Terms extends React.Component<IProps, {}> {
    render() {
        return (
            <div style={{ fontSize: ".7rem", textAlign: "center", marginTop: ".5rem", ...this.props.style }}>
                <span className="text-minor">
                    {this.props.isVisitor ? "绑定" :"注册"}代表您已同意
                </span>
                <span className="text-primary clickable"
                    onClick={() => {
                        (this.refs.modal as any).show()
                    }}>
                    “领研”用户协议
                </span>

                <Modal ref="modal" title={"“领研”用户协议"} innerWidth="42rem"
                    scrollContent content={
                        <div className="pre-wrap inner-line-height text-minor"
                            style={{ fontSize: ".75rem", textAlign: "left", lineHeight: 1.6 }}>
                            {TERMS}
                        </div>
                    }
                    confirmText="关 闭" />
            </div>
        )
    }
}
