import * as React from "react"
import * as _ from 'lodash'
import * as util from "../util"
import { Box, Item, Popup } from "./index"
import { actions } from "../flux"
import { Menu, Dropdown, Icon } from 'antd'

import "../styles/desktop/Header2.less"

interface Props {
    tab: string
    search?: string
    eventText?: string
    style?: any
}

interface State {
    session: any
    profile: any
    menu: boolean
    eventMenu: boolean
    height: number
    rightMenuOpt: any[]
}

export default class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            session: null,
            profile: null,
            menu: false,
            eventMenu: false,
            height: 64,
            rightMenuOpt: [
                {
                    text: '消息',
                    href: '/my/messages',
                },
                {
                    text: '收藏夹',
                    href: '/my/stars',
                },
                {
                    text: '投递箱',
                    href: '/my/applications',
                },
                {
                    text: '我的简历',
                    href: '/profile/resume',
                },
                {
                    text: '我的论文',
                    href: '/my/theses',
                },
                {
                    text: '帐号设置',
                    href: '/profile/account',
                },
                {
                    text: '发布职位',
                    href: '/login/hr',
                },
                {
                    text: '退出',
                    href: '/logout?redirect=/login',
                },
            ],
        }
    }

    componentDidMount() {
        actions.login.get(session => this.setState({ session }))
        actions.api.profile.get(profile => this.setState({ profile }))

        let root = document.body.querySelector("#root") as HTMLElement
        root.addEventListener("scroll", () => {
            this.setState({ height: root.scrollTop > 0 ? 50 : 64 })
        })
    }

    entry(text: any, tab: string, href: string, menu?: any, expandClassName?: string, extendUrl?: string, extendStyle?: any) {
        let style = this.props.style || {}
        let active = this.props.tab === tab
        return (
            <div className="head-menu-tab">
                {expandClassName ? 
                    <a 
                    className={expandClassName} 
                    style={{
                        transformOrigin: "right",
                        transform: `scale(${this.state.height == 64 ? 1 : 0.74 })`
                    }} 
                    href={extendUrl} /> : null
                }
                <a key={tab} href={href === "/search" && location.protocol === "https:" ? "https://jobs.linkresearcher.com/search" : href}
                    style={{ display: "block", height: this.state.height, marginRight: 28, transtion: "height 200ms" }}>
                    <Box relative vertical justified style={{ height: "100%", color: style.color || "white" }}
                        onMouseEnter={() => menu ? this.setState({ eventMenu: true }) : 0}
                        onMouseLeave={() => menu ? this.setState({ eventMenu: false }) : 0}>
                        {util.minHeight(4)}
                        <div className={style.textHover || "text-hover-h2"} style={{ fontSize: 16, color: active ? "#45ccfa" : extendStyle ? extendStyle.color : undefined }}>
                            {text}
                        </div>
                        <div style={{ minHeight: 4, background: active ? "#45ccfa" : undefined }} />
                        {menu}
                    </Box>
                </a>
            </div>
        )
    }

    render() {
        let { rightMenuOpt, profile, session } = this.state
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId
        let style = this.props.style || {}

        return [
            <div key="0" style={{ height: this.state.height }} />
            ,
            <div key="1" className="min-view-center bg-color-v2"
                style={{
                    height: this.state.height, position: "fixed", top: 0, left: 0, color: "#fff", zIndex: 10,
                    background: style.background,
                    transition: "height 200ms",
                }}>
                <Box center style={{
                    minWidth: style.minWidth,
                    maxWidth: style.maxWidth,
                }}>
                    <div style={{ lineHeight: 1, width: 174 }}>
                        {util.logo({
                            height: this.state.height * 0.72,
                            transition: "height 200ms",
                        }, typeof style.logo === "undefined" ? "-light" : style.logo)}
                    </div>
                    {this.entry("首页", "INDEX", "/", null)}
                    {this.entry("论文", "THESIS", "/theses", null, "subscribe", `${location.origin}/information/5252ebfc-1c74-46fe-ab62-f2720afa32cc`)}
                    {this.entry("新闻", "NEWS", "/information", null)}
                    {this.entry("招聘", "JOB", "/search", null)}
                    {this.entry("职业生涯", "CAREER", "/careers", null)}
                    {this.entry("论文润色", "POLISH", `${location.origin}/verify/pagejumper?hmsr=1&hmpl=11&hmcu=&hmkw=&hmci=`, null, undefined, undefined)}
                    {/* {this.entry("活动", "EVENT", "/meetings")} */}
                    {this.entry(
                        <span>
                            {this.props.eventText || "会议"}
                            <Icon type="caret-down" />
                        </span>
                        , "EVENT", "/meetings",
                        this.state.eventMenu ? (
                            <div className="box-shadow"
                                style={{
                                    width: 150, background: "#fff", position: "absolute", lineHeight: 1,
                                    left: -21, top: this.state.height, border: "1px solid #e5e5e5", fontSize: 16,
                                }}>
                                <a href="/meetings"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    会议
                                </a>
                                <a href="/lectures"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    讲座
                                </a>
                                <a href="/trainings"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    培训
                                </a>
                                <a href="/lives"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    直播
                                </a>
                                <a href="/studytours"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    游学
                                </a>
                                <a href="/contests"
                                    className="hover-bg-e5" style={{ display: "block", color: "#666", padding: "15px 20px" }}>
                                    比赛
                                </a>
                            </div>
                        ) : <Item fit hidden />
                    )}
                    <Item flex='0 0 97px' />
                    {this.props.search ?
                        <div>
                            <div
                                onClick={() => {
                                    window.location.href = `/searchall?tab=${this.props.search}`
                                }}
                                style={{
                                    display: 'block',
                                    height: `${this.state.height}px`,
                                    width: '68px',
                                    lineHeight: `${this.state.height}px`,
                                    marginRight: '29px',
                                    padding: '0 15px',
                                    borderLeft: '1px solid #fff',
                                    borderRight: '1px solid #fff',
                                    color: '#fff',
                                    fontSize: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <img style={{width: '36px', height: '36px'}} className='icon' src='/assets/icons/magnifier.svg' />
                            </div>
                        </div>
                        : null
                    }
                    <Popup position="bottom center" trigger={
                        <div style={{ marginRight: 29, fontSize: this.state.profile ? 16 : 14 }}>
                            官方微信
                        </div>
                    } content={
                        <Box style={{ fontSize: 16 }}>
                            <div className="text-center">
                                <img src={util.imageURL("qrcode-old.jpg")} style={{ width: 126, height: 126 }} />
                                <div>订阅号</div>
                            </div>
                            <div className="text-center" style={{ marginLeft: 10 }}>
                                <img src={util.imageURL("qrcode-new.jpg")} style={{ width: 126, height: 126 }} />
                                <div>服务号</div>
                            </div>
                        </Box>
                    } />
                    <a 
                        href="/login/hr"
                        style={{
                            padding: '3px 10px',
                            marginRight: '29px',
                            fontSize: '14px',
                            border: '1px solid #fff',
                            color: '#fff',
                        }}
                    >
                        发布职位
                    </a>
                    {profile ? 
                        <Dropdown overlay={
                            <Menu style={{width: '130px'}}>
                            {_.map(rightMenuOpt, i => {
                                return (
                                <Menu.Item key={i.href}>
                                    <a href={i.href}>
                                    {i.text}
                                    </a>
                                </Menu.Item>
                                )
                            })}
                            </Menu>
                        }>
                            <img style={{width: '20px', height: '24px'}} className='icon' src='/assets/icons/user.svg' />
                        </Dropdown> :
                        <a href={isVisitor ? '/join' : `/login?redirect${encodeURIComponent(location.pathname)}`}>
                            <img style={{width: '20px', height: '24px'}} className='icon' src='/assets/icons/user.svg' />
                        </a>
                    }
                </Box>
            </div>
        ]
    }
}
