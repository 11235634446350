import * as React from "react"
import * as timeFormat from "d3-time-format"

export function dateString(ts: number) {
    return timeFormat.timeFormat(`%Y-%m-%d`)(new Date(ts))
}

export function timeAgo(ts) {
    let diff = (Date.now() - ts) / 1000
    if (diff > 86400 * 30) {
        return `${Math.floor(diff / 86400 / 30)}个月前`
    } else if (diff > 86400) {
        return `${Math.floor(diff / 86400)}天前`
    } else if (diff > 3600) {
        return `${Math.floor(diff / 3600)}小时前`
    } else if (diff > 60) {
        return `${Math.floor(diff / 60)}分钟前`
    } else {
        return "刚刚"
    }
}

export function formatTs(ts, format = "%Y/%m/%d") {
    if (!ts) return ""
    return timeFormat.timeFormat(format)(new Date(ts))
}

export function formatTimestampCN(ts, wrap = false) {
    if (!ts) return ""
    return (
        <span style={{ whiteSpace: "pre" }}>
            {timeFormat.timeFormat(`%Y年%m月%d日${wrap ? "\n" : " "}%H:%M:%S`)(new Date(ts))}
        </span>
    )
}

export function formatTimestamp(ts, wrap = false) {
    if (!ts) return ""
    return (
        <span style={{ whiteSpace: "pre" }}>
            {timeFormat.timeFormat(`%Y/%m/%d${wrap ? "\n" : " "}%H:%M:%S`)(new Date(ts))}
        </span>
    )
}

export function formatSystemDate(dateString) {
    return typeof dateString === "string" ? dateString.replace(/-/g, "/") : "无效日期"
}

export function shortTimeFormat(ts) {
    let today = timeFormat.timeFormat("%Y/%m/%d")(Date.now())
    let dateString = timeFormat.timeFormat("%Y/%m/%d")(ts)
    if (dateString === today) {
        return timeFormat.timeFormat("今天 %H:%M")(ts)
    } else {
        return dateString
    }
}
