import * as React from "react"
import Dropzone from "react-dropzone-component"

import { Box, Item, Icon } from "./index"
import * as util from "../util"

interface pixabay {
    largeImageURL: string,
    pageURL: string,
    previewURL: string,
    userLink: string,
    userName: string,
    webFormatHeight: number,
    webFormatURL: string,
    webFormatWidth: number
}

interface Props {
    title: string
    reUploadTitle?: string
    url?: string
    uploadMultiple?: boolean
    maxFilesize?: number
    acceptedFiles?: string
    style?: React.CSSProperties
    onChange?: any
    disabled?: boolean
    errorMessage?: string
    watermark?: boolean
    imgType?: "logo" | "qrCode"
    sourceid?: string
    newUrl?: string
    isAdmin?: boolean
    tags?: string[]
    textElemId?: string
}

interface State {
    id: string
    url: any
    hover: boolean
    isOpenCoverGallery: boolean
    isOpenCoverContent: boolean
    activeNavNum: number
    hoverNavNum: number
    activeTagNum: number
    hoverResultImgNum: number
    tagResult: pixabay[]
    tagResultImgPage: number
    isLoadTagResult: boolean
    isLoadSearchResult: boolean
    searchKey: string
    searchResult: pixabay[]
    searchResultImgPage: number
    contentImg: string[]
}

export default class UploadFile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            id: "",
            url: "",
            hover: false,
            isOpenCoverGallery: false,
            isOpenCoverContent: false,
            // 当前被悬浮结果图片
            hoverResultImgNum: 0,
            // 当前被选中菜单
            activeNavNum: 1,
            //  当前被悬浮菜单
            hoverNavNum: 0,
            // 当前被选中标签的索引
            activeTagNum: 0,
            // 标签结果
            tagResult: [],
            // 标签结果页数
            tagResultImgPage: 1,
            // 标签：当前是否正在请求结果
            isLoadTagResult: false,
            // 搜索：当前是否正在请求拉底下一页接口
            isLoadSearchResult: false,
            // 搜索关键词存储
            searchKey: "",
            // 搜索结果
            searchResult: [],
            // 搜索结果页数
            searchResultImgPage: 1,
            // 正文可插入的图片
            contentImg: [],
        }
    }

    fakeFormDataUploadImg(file: File) {
        const formData = new FormData()
        formData.append('_csrf', (document.querySelector("meta[name='_csrf']") as any)["content"])
        formData.append('file', file)
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/upload/compress")
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Cache-Control", "no-cache");
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.onload = () => {
            if(xhr.status === 200) {
                let resp = JSON.parse(xhr.responseText)
                this.setState({ id: resp.id, url: resp.url })
                if (this.props.onChange) this.props.onChange(resp)
            } else {
                console.error("/api/upload/compress 上传图片失败")
            }
        }
        xhr.send(formData)
    }

    selectImgFromContent(id: string, url: string) {
        this.setState({ id, url })
        if (this.props.onChange) this.props.onChange({ url })
    }


    componentDidMount(): void {
        const fileEl = document.querySelector("#yyt-from-pc") as HTMLElement
        if(fileEl) {
            fileEl.addEventListener("change", (e: Event) => {
                const theTarget = e.target as HTMLInputElement
                const theFiles = theTarget.files
                if(theFiles) {
                    this.fakeFormDataUploadImg(theFiles[0])
                }
            })
        }

        window.addEventListener('beforeunload', (event) => {
            event.preventDefault();
            event.returnValue = "您所做的更改可能不会被保存。"
        })

        const tagsResultEl = (this.refs as any).tagsResult
        if(!tagsResultEl) return
        tagsResultEl.addEventListener("scroll", () => {
            if(tagsResultEl.scrollHeight === tagsResultEl.clientHeight) return
            if(tagsResultEl.scrollHeight - tagsResultEl.scrollTop === tagsResultEl.clientHeight && !this.state.isLoadTagResult ) {
                // 当滚动到底部时执行相关操作
                const tags = this.props.tags
                if(!tags) return 
                const nextPage = this.state.tagResultImgPage + 1 
                let query = tags[this.state.activeTagNum]
                this.setState({
                    // 切换为正在请求状态
                    isLoadTagResult: true
                })
                this.fetchByTag( query, nextPage )
                .then((data) => {
                    if(!data) return 
                    if(data.length === 0) {
                        // 返回的结果无值，下次请求还是当前页
                        this.setState({
                            // 切换为结束请求状态
                            isLoadTagResult: false,
                        })
                    } else {
                        // 返回的结果有值，下次请求则是下一页
                        this.setState({
                            // 切换为结束请求状态
                            isLoadTagResult: false,
                            tagResultImgPage: nextPage,
                            tagResult: this.state.tagResult.concat(data)
                        })
                    }
                    
                })

            }
        })

        const searchResultEl = (this.refs as any).searchResult
        if(!searchResultEl) return
        searchResultEl.addEventListener("scroll", () => {
            if(searchResultEl.scrollHeight - searchResultEl.scrollTop === searchResultEl.clientHeight && !this.state.isLoadSearchResult ) {
                const nextPage = this.state.searchResultImgPage + 1
                this.setState({
                    isLoadSearchResult: true
                })
                this.fetchByTag( this.state.searchKey, nextPage )
                .then((data) => {
                    if(!data) return
                    if(data.length === 0) {
                        // 返回的结果无值，下次请求还是当前页
                        this.setState({
                            // 切换为结束请求状态
                            isLoadSearchResult: false,
                        })
                    } else {
                        // 返回的结果有值，下次请求则是下一页
                        this.setState({
                            isLoadSearchResult: false,
                            searchResultImgPage: nextPage,
                            searchResult: this.state.searchResult.concat(data)
                        })
                    }
                })
            }
        })
    }

    fetchByTag(query: string, page: number) {
        return fetch(`/api/material/image/search?type=pixabay&query=${query}&page=${page}&lang=zh`).then(res => res.json())     
    }

    doSearch(inputValue: string) {
        if(inputValue.length > 0) {
            this.setState({
                isLoadSearchResult: true
            })
            // 搜索关键词有值
            this.fetchByTag(inputValue, 1)
            .then((data) => {
                if(!data) return
                if(data.length === 0) {
                    // 返回的结果无值，则无
                    this.setState({
                        searchKey: inputValue,
                        searchResult: [],
                        // 下次请求还是第一页
                        searchResultImgPage: 1,
                        isLoadSearchResult: false
                    })
                } else {
                    // 返回的结果有值，显示第一页的结果
                    this.setState({
                        searchKey: inputValue,
                        searchResult: data,
                        // 下次请求则是下一页
                        searchResultImgPage: 1,
                        isLoadSearchResult: false
                    })
                    const searchResultEl = (this.refs as any).searchResult
                    searchResultEl.scrollTop = 0
                }
            })
        } else {
            // 搜索关键词为空
            this.setState({
                searchKey: "",
                searchResult: []
            })
        }
    }

    pickImg( pixabay: pixabay ) {
        // 拿到当前640px规格的online imgURL
        const imgURL = pixabay.webFormatURL
        // 将URL转为base64后得到dataURL
        this.imgUrl2Base64(imgURL, pixabay.webFormatWidth, pixabay.webFormatHeight).then((data: string) => {
            const base64 = data.split(',')[1]
            const fileName = imgURL.split('/').pop()
            // 将dataURL转为File文件
            this.dataUrl2File(base64, fileName ? fileName : "").then((file : File) => {
                // 转换后的blob对象
                this.fakeFormDataUploadImg(file)
                this.closeCoverGallery()
            })
        })
    }



    renderTagNoFound( tagName: string, smallTagName: string, tipContent: string) {
        return <div className="yyt-cg-result-no-found">
        <div className="yyt-cg-no-found-icon">
            <div className="yyt-cg-tag-icon">
                <Icon name={tagName} />
            </div>
            <div className="yyt-cg-close-icon">
                <Icon name={smallTagName}/>
            </div>
        </div>
        <div className="yyt-cg-no-found-tip">
            {tipContent}
        </div>
    </div>
    }

    // 图片url转base64编码
    imgUrl2Base64(url: string, imgWidth: number, imgHeight: number) {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = imgWidth;        
                canvas.height = imgHeight;
                // 将图片插入画布并开始绘制
                const ctx = canvas.getContext("2d");
                if(!ctx) return;
                ctx.drawImage(image, 0, 0);
                const result = canvas.toDataURL();
                resolve(result);
            }
            image.setAttribute('crossOrigin', 'Anonymous');
            image.src = url;
            // 图片加载失败的错误处理
            image.onerror = () => {
                reject(new Error('urlToBase64 error'))
            }
        })
    }

    dataUrl2File(b64data: string, fileName: string, sliceSize: number = 512) {
        return new Promise((resolve, reject) => {
            const byteCharacters = atob(b64data);
            const byteArrays:any[] = [];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers:number[] = [];
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers.push(slice.charCodeAt(i))
                }
                byteArrays.push(new Uint8Array(byteNumbers))
            }
            const contentType = `image/${fileName.split('.').pop()}`
            const result = new File(byteArrays, fileName, {
                type: contentType
            })
            resolve(result)
        })
    }

    closeCoverGallery() {
        this.setState({
            isOpenCoverGallery: false,
            // 关闭图库时重置tag结果
            tagResult: [],
            // 重置标签选中索引
            activeTagNum: 0,
            hoverResultImgNum: 0
        })
    }

    closeCoverContent() {
        this.setState({
            isOpenCoverContent: false
        })
    }


    render() {
        let that = this
        let url = this.state.url || this.props.url
        let { isOpenCoverGallery, isOpenCoverContent, tagResult, contentImg } = this.state
        let { tags } = this.props
        let style = { background: "#f5f5f5", ...this.props.style }
        const menuArr = [{id: 1, icon: "tags", name: "标签"}, {id: 2, icon: "search", name: "搜索"}]
        return (
            <>
                <Box relative center centerJustified style={style}
                    onMouseEnter={this.props.disabled ? undefined : () => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}>

                    {url ? <img src={url} style={{ position: "absolute", maxWidth: "100%", maxHeight: "100%" }} /> : null}
                    <Item fit style={{ background: "rgba(0,0,0,0.7)", display:  url && this.state.hover && !this.props.disabled ? "block" : "none"}} />

                    <Item className={"text-center clickable text-" + (url ? "white" : "minor")}
                        style={{ fontSize: ".8rem", lineHeight: 2, position: "absolute", inset: 0, display: "flex", justifyContent: "center" }}>
                        {this.props.isAdmin ? <div className="yyt-expand-wrapper" style={{ display: this.state.hover ? "flex" : "none" }}>
                            <div className="yyt-expand-container">
                                <div className="yyt-expand-item-container" 
                                    onClick={() => {
                                        // 获取pixabay在线图片资源，用于标签推荐首屏显示
                                        // 条件：标签有值
                                        if(tags && tags.length > 0) {
                                            let query = tags[0]
                                            this.setState({
                                                isLoadTagResult: true
                                            })
                                            this.fetchByTag(query, 1).then(data => {
                                                this.setState({
                                                    tagResult: data,
                                                    isLoadTagResult: false
                                                }) 
                                            })
                                        }
                                        // 打开图库
                                        const thesisTagsEl = document.querySelector("#thesis-tags")
                                        thesisTagsEl && thesisTagsEl.scrollIntoView()
                                        this.setState({
                                            isOpenCoverGallery: true
                                        })
                                    }}
                                >
                                    <Icon name="cloud upload" />
                                    <div>来自图库</div>
                                </div>
                                <div className="yyt-expand-item-container"
                                    onClick={() => {
                                        // 滚动至正文
                                        const thesisTagsEl = document.querySelector("#thesis-tags")
                                        thesisTagsEl && thesisTagsEl.scrollIntoView()
                                        const textAreaId = this.props.textElemId
                                        if (textAreaId) {
                                            const textArea = document.querySelector(`#${textAreaId}`)
                                            if(!textArea) return
                                            const imgs = textArea.querySelectorAll("img")
                                            this.setState({
                                                isOpenCoverContent: true,
                                                contentImg: Array.from(imgs).map((img) => img.src)
                                            })
                                        } else {
                                            this.setState({
                                                isOpenCoverContent: true,
                                            })
                                        }
                                    }}
                                >
                                    <Icon name="chart area" />
                                    <div>来自正文</div>
                                    <div className="yyt-admire-container">
                                        <Icon name="lightbulb outline"/>
                                        {/* <div>推荐</div> */}
                                    </div>
                                </div>
                                <div className="yyt-expand-item-container" 
                                    onClick={() => {
                                        const fileEl = document.querySelector("#yyt-from-pc") as HTMLElement
                                        if(fileEl) {
                                            fileEl.click()
                                        }
                                    }}
                                >
                                    <Icon name="upload" />
                                    <div>来自本地</div>
                                </div>
                                <input id="yyt-from-pc" className="yyt-from-pc" type="file"  style={{ display: 'none' }}/>
                            </div>
                        </div>
                        :
                        <div style={{ display: !url || this.state.hover ? "flex" : "none", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Icon name="upload" />
                            <div style={{ marginTop: "8px" }}>{url ? (this.props.reUploadTitle || "重新上传") : this.props.title}</div>
                        </div>}

                        {this.props.disabled ? null : !this.props.isAdmin && (
                            <Item fit className="yyt-fix-dropzone" style={{ opacity: 0 }}>
                                <Dropzone disablePreview
                                    config={{ postUrl: this.props.newUrl ? this.props.newUrl : (this.props.watermark ? '/api/upload/watermark' : `/api/upload/compress`) }}
                                    djsConfig={{
                                        uploadMultiple: this.props.uploadMultiple || false,
                                        maxFilesize: this.props.maxFilesize || 10,
                                        acceptedFiles: this.props.acceptedFiles || ".jpg,.jpeg,.png",
                                        params: {
                                            _csrf: (document.querySelector("meta[name='_csrf']") as any)["content"],
                                            sourceid: this.props.sourceid,
                                            type: this.props.imgType,
                                        },
                                    }}
                                    eventHandlers={{
                                        success(file) {
                                            let resp = JSON.parse(file.xhr.responseText)
                                            that.setState({ id: resp.id, url: resp.url })
                                            if (that.props.onChange) that.props.onChange(resp)
                                        },
                                        error: util.uploadErrorHandler(that.props.errorMessage),
                                    }}>
                                </Dropzone>
                            </Item>
                        )}
                    </Item>
                </Box>
                {/* 来自图库 */}
                <div className="yyt-cover-gallery-undertone" style={{ display: isOpenCoverGallery ? "block" : "none" }} >
                </div>
                <div className="yyt-cover-gallery-wrapper" style={{ display: isOpenCoverGallery ? "flex" : "none" }} >
                    <div className="yyt-cover-gallery-container">
                        <div className="yyt-cover-gallery-main">
                            <div className="yyt-cg-container">
                                <div className="yyt-cg-close" onClick={() => {
                                    this.closeCoverGallery()
                                }}>
                                    <Icon name="close icon" />
                                </div>
                                <div className="yyt-cg-header">
                                    <div className="yyt-current-theme">
                                        <Icon name="cloud upload" />
                                        <div className="yyt-theme-text">来自图库</div>
                                    </div>
                                    <div className="yyt-cg-spirit-island">
                                        {
                                            menuArr.map((item) => 
                                            <button key={item.id} className="yyt-cg-spirit-btn" onMouseEnter={() => this.setState({ hoverNavNum: item.id })} onMouseLeave={() => this.setState({ hoverNavNum: 0 })} 
                                                onClick={() => {
                                                    this.setState({activeNavNum: item.id})
                                                }}>
                                                <div className="yyt-cg-spirit-name" style={{ display: this.state.hoverNavNum === item.id ? "flex":"none" }}>{item.name}</div>
                                                <Icon name={item.icon} />
                                                <div className="yyt-cg-spirit-dot" style={{ opacity: this.state.activeNavNum === item.id ? 1:0 }}></div>
                                            </button>)
                                        }
                                    </div>
                                </div>
                                <div className="yyt-cg-showcase">
                                    <div className="yyt-cg-tag" style={{ visibility: this.state.activeNavNum === 1 ? "visible":"hidden" }}>
                                        <div className="yyt-cg-left-side">
                                            <div className="yyt-cg-use-case">
                                                <div className="yyt-cg-uc-title-container">
                                                    <Icon name="caret down" />
                                                    <div className="yyt-cg-article-tag">
                                                        文章标签
                                                    </div>
                                                    <div className="yyt-tag-loading-container">
                                                        { this.state.isLoadTagResult && <Icon name="notched circle loading icon" /> }
                                                    </div>
                                                </div>
                                                <div className="yyt-cg-uc-item-container">
                                                    {
                                                        tags && tags.map((tag, index) => <div key={index} className="yyt-cg-tag-item" 
                                                            onClick={() => {
                                                                // 切换标签
                                                                if(!tags) return
                                                                this.setState({
                                                                    isLoadTagResult: true
                                                                })
                                                                this.fetchByTag(tags[index], 1).then((data) => {
                                                                    this.setState({
                                                                        activeTagNum: index,
                                                                        tagResult: data,
                                                                        tagResultImgPage: 1,
                                                                        isLoadTagResult: false,
                                                                    })
                                                                    const tagsResultEl = (this.refs as any).tagsResult
                                                                    tagsResultEl.scrollTop = 0
                                                                })
                                                            }}>
                                                            <div className="yyt-cg-radio-box" style={{ background: this.state.activeTagNum === index ? "#000":"none", color: this.state.activeTagNum === index ? "hsl(0, 0%, 100%)":"hsl(0, 0%, 98%)" }}>
                                                                <Icon name="check" />
                                                            </div>
                                                            <div className="yyt-cg-tag-content">{tag}</div>
                                                        </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="yyt-cg-right-side">
                                            {
                                            tags && tags.length > 0 ? 
                                            <div className="yyt-cg-result-tags" ref="tagsResult">
                                                {
                                                    tagResult.length > 0 ? tagResult.map((res, index) => 
                                                    <div key={index} className="yyt-cg-result-item-container" onMouseEnter={() => this.setState({hoverResultImgNum: index + 1 })} onMouseLeave={() => this.setState({ hoverResultImgNum: 0 })} >
                                                        <div className="yyt-cg-result-item-mask" style={{ display: this.state.hoverResultImgNum === index + 1 ? "flex" : "none" }}>
                                                            <div className="yyt-cg-choose-icon" onClick={() => {
                                                                this.pickImg(res)
                                                            }}>
                                                                <Icon name="thumbs up" />
                                                                <div className="yyt-cg-choose-tip">
                                                                    设为封面
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="yyt-cg-result-item" style={{ backgroundImage: `url(${res.webFormatURL})`}}></div>
                                                    </div>)
                                                    :
                                                    this.renderTagNoFound("tag", "ellipsis horizontal","暂无结果，尝试切换标签或使用搜索功能。")
                                                }
                                            </div>
                                            : 
                                            this.renderTagNoFound("tags", "close icon","无推荐结果，尝试使用搜索功能或返回编辑文章标签内容。")
                                            }
                                        </div>
                                    </div>
                                    <div className="yyt-cg-search" style={{ visibility: this.state.activeNavNum === 2 ? "visible":"hidden" }}>
                                            <div className="part3-search-container">
                                                <div className="yyt-search-container">
                                                    <input className="yyt-search-input" ref="searchInput" placeholder="有时输入英文更准确" onKeyPress={(event) => {
                                                        const charCode = event.which || event.keyCode;
                                                        const currentEl:any = event.target;
                                                        const inputValue = currentEl.value
                                                        if(charCode === 13 && !this.state.isLoadSearchResult) {
                                                            this.doSearch(inputValue)
                                                        }
                                                    }}/>
                                                    <div className="yyt-search-btn" onClick={() => {
                                                        const searchInputEl = (this.refs as any).searchInput
                                                        const inputValue = searchInputEl.value
                                                        if(!this.state.isLoadSearchResult) {
                                                            this.doSearch(inputValue)
                                                        }
                                                    }}>
                                                        <button className="yyt-search-btn-icon">
                                                            { this.state.isLoadSearchResult ? <Icon name="notched circle loading icon" /> : <Icon name="search" /> }
                                                        </button>
                                                        <button className="yyt-search-btn-patch">
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="yyt-search-result-container">
                                                <div className="yyt-search-no-results" style={{ visibility: this.state.activeNavNum === 2 && this.state.searchResult.length <= 0 ? "visible":"hidden" }} >
                                                    <div className="yyt-cg-no-found-icon">
                                                        <div className="yyt-cg-search-icon">
                                                            <Icon name="search" />
                                                        </div>
                                                        <div className="yyt-cg-close-icon">
                                                            <Icon name="close icon" />
                                                        </div>
                                                    </div>
                                                    <div className="yyt-cg-no-found-tip">
                                                        无搜索结果
                                                        {this.state.searchKey ? `（当前关键词：${this.state.searchKey}）`:"，请输入关键词。"}
                                                    </div>
                                                </div>
                                                <div className="yyt-search-results" ref="searchResult" style={{ visibility: this.state.activeNavNum === 2 && this.state.searchResult.length > 0 ? "visible":"hidden" }}>
                                                    {
                                                        this.state.searchResult.map((res, index) => 
                                                            <div key={index} className="yyt-cg-result-item-container" onMouseEnter={() => this.setState({ hoverResultImgNum: index + 1 })} onMouseLeave={() => this.setState({ hoverResultImgNum: 0 })} >
                                                                <div className="yyt-cg-result-item-mask" style={{ display: this.state.hoverResultImgNum === index + 1 ? "flex" : "none" }}>
                                                                    <div className="yyt-cg-choose-icon" onClick={() => {
                                                                        this.pickImg(res)
                                                                    }}>
                                                                        <Icon name="thumbs up" />
                                                                        <div className="yyt-cg-choose-tip">
                                                                            设为封面
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="yyt-cg-result-item" style={{ backgroundImage: `url(${res.webFormatURL})` }}></div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>           
                    </div>
                </div>
                {/* 来自正文 */}
                <div className="yyt-cover-content-undertone" style={{ display: isOpenCoverContent ? "block" : "none" }} >
                </div>
                <div className="yyt-cover-content-wrapper" style={{ display: isOpenCoverContent ? "flex" : "none" }} >
                    <div className="yyt-cover-gallery-main">
                        <div className="yyt-cg-container">
                                <div className="yyt-cg-close" 
                                    onClick={() => {
                                        this.closeCoverContent()
                                    }}>
                                    <Icon name="close icon" />
                                </div>
                                <div className="yyt-cg-header">
                                    <div className="yyt-current-theme">
                                        <Icon name="chart area" />
                                        <div className="yyt-theme-text">来自正文</div>
                                    </div>
                                    选择封面
                                </div>
                                <div className="yyt-cg-showcase">
                                    
                                    { contentImg.length > 0 ? <div className="yyt-cg-cotent-result">
                                        {
                                            contentImg.length > 0 && contentImg.map((res, index) => 
                                                <div key={index} className="yyt-cg-result-item-container" onMouseEnter={() => this.setState({ hoverResultImgNum: index + 1 })} onMouseLeave={() => this.setState({ hoverResultImgNum: 0 })}>
                                                    <div className="yyt-cg-result-item-mask" style={{ display: this.state.hoverResultImgNum === index + 1 ? "flex" : "none" }}>
                                                        <div className="yyt-cg-choose-icon"
                                                            onClick={() => {
                                                                const regex = /\/([a-z0-9-]+)$/i;
                                                                const match = res.match(regex);
                                                                if (match) {
                                                                    const target = match[1]
                                                                    this.selectImgFromContent(target, res)
                                                                    this.closeCoverContent()
                                                                } else {
                                                                    console.error("图片id无法匹配")
                                                                }
                                                            }}>
                                                            <Icon name="thumbs up" />
                                                            <div className="yyt-cg-choose-tip">
                                                                设为封面
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="yyt-cg-result-item" style={{ backgroundImage: `url(${res})` }}></div>
                                                </div>
                                            )
                                        }
                                    </div> 
                                    : 
                                    <div className="yyt-cg-no-result">
                                        <div className="yyt-cg-no-result-icon">
                                            <div className="yyt-cg-image-icon">
                                                <Icon name="image" />
                                            </div>
                                            <div className="yyt-cg-close-icon">
                                                <Icon name="close icon" />
                                            </div>
                                        </div>
                                        <div className="yyt-cg-no-result-tips-container">
                                            <div className="yyt-cg-no-result-tips">正文中无可用做封面的图片</div>
                                            <div className="yyt-cg-no-result-sub-tips">请在正文中插入图片</div>
                                        </div>
                                    </div>
                                    }
                                </div>
                        </div>
                    </div>                          
                </div>
            </>
        )
    }
}
