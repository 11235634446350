import * as React from "react"
import * as qr from "qrcode-generator"
import * as qs from "query-string"
import Notification from "react-notify-toast"

import { Popup } from "."
import * as util from "../util"
import { actions, db, db$ } from "../flux"

interface Props {
    pic: string
    title: string
    description: string
    id: string
    starType: "STAR_JOB" | "STAR_THESIS" | "STAR_CAREER" | "STAR_INFORMATION" | "STAR_EVENT"
    fixed?: boolean
    countComment?: number
}

interface State {
    starred: boolean
    session: any
}

export default class ShareIcons extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            starred: false,
            session: null,
        }
    }

    componentDidMount() {
        db.subscribe(() => {
            if (!actions.local.profile()) return
            actions.api.profile.starredTarget(
                { from: 0, size: 1, target: this.props.id },
                () => this.setState({ starred: true }),
                () => { },
            )
        }, this)
        
        this.setState({
            session: db$.login.findOne({ _id: "session" })
        })
    }

    render() {
        let qrcode = qr(10, "L")
        qrcode.addData(location.href)
        qrcode.make()
        let svg: any = qrcode.createSvgTag()
        let session = this.state.session
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId

        let style: React.CSSProperties = {
            background: "#fff",
            borderRadius: 999,
            padding: 6,
            marginTop: 10,
            display: "block",
            cursor: "pointer",
            position: "relative",
        }

        return (
            <div className="text-minor text-center"
                style={{ fontSize: 12, lineHeight: 1, position: this.props.fixed ? "fixed" : "relative" }}>
                分享
                {util.minHeight(10)}
                <Popup position="right center"
                    header={<span dangerouslySetInnerHTML={{ __html: svg }} />}
                    content={<div className="text-minor text-center" style={{ fontSize: 12 }}>请用微信扫码后分享</div>}
                    trigger={
                        <div style={style}>
                            {util.imageContain(util.iconURL("wechat.svg"), 24)}
                        </div>
                    }
                />
                {util.minHeight(10)}
                <a style={style} target="_blank"
                    href={"http://connect.qq.com/widget/shareqq/index.html?" + qs.stringify({
                        url: location.href,
                        title: this.props.title,
                        summary: this.props.description,
                        pics: this.props.pic,
                    })}>
                    {util.imageContain(util.iconURL("qq.svg"), 24)}
                </a>
                {util.minHeight(10)}
                <a style={style} target="_blank"
                    href={"http://service.weibo.com/share/share.php?" + qs.stringify({
                        url: location.href,
                        title: this.props.title,
                        pic: this.props.pic,
                    })}>
                    {util.imageContain(util.iconURL("weibo.svg"), 24)}
                </a>
                {util.minHeight(20)}
                收藏
                {util.minHeight(10)}
                <div style={style}>
                    {util.imageContain(
                        util.iconURL(this.state.starred ? "starred.svg" : "star.svg"), 24, {}, "",
                        () => {
                            if (isVisitor) {
                                location.href = "/join"
                            }
                            if (this.state.starred) {
                                actions.api.profile.unstar(
                                    { type: this.props.starType, target: this.props.id },
                                    () => this.setState({ starred: false }, () => util.toast.success("取消收藏"))
                                )
                            } else {
                                actions.api.profile.star(
                                    { type: this.props.starType, target: this.props.id, url: location.pathname },
                                    () => this.setState({ starred: true }, () => util.toast.success("收藏成功"))
                                )
                            }
                        }
                    )}
                </div>
                {util.minHeight(20)}
                {this.props.countComment === undefined ? "" : "评论"}
                {util.minHeight(10)}
                {this.props.countComment === undefined ? null :
                    <a style={style} href="#comments"
                        onClick={() => {
                            setTimeout(() => {
                                let root = document.querySelector("body>#root") as HTMLElement
                                root.scrollTop -= 74
                            }, 50)
                        }}>
                        {util.imageContain(util.iconURL("comment.svg"), 24)}
                        {this.props.countComment ?
                            <div style={{ position: "absolute", width: "100%", top: 12, left: 0, fontSize: 10, color: "#d80f1c" }}>
                                {this.props.countComment}
                            </div>
                            : null
                        }
                    </a>
                }
                <Notification />
            </div>
        )
    }
}
