import * as React from "react"
import * as isURL from "is-url"

import * as cache from "../util/cache"
import * as util from "../util"
import FormChecker, { ErrorTooltip } from "./FormChecker"
import SettingPanel from "./SettingPanel"
import UploadFile from "./UploadFile"
import { Icon, Button, Box, Item, elements } from "./index"
import { actions } from "../flux"

interface Props {
    preview?: boolean
    onViewCert?: any
    employer: any
    onFinish: any
    onReturn?: any
    superAdminMode?: boolean
}

interface State {
    form: any
    preview: boolean
    hr: any
}

export default class Edit extends React.Component<Props, State> {
    formChecker = new FormChecker()

    constructor(props: Props) {
        super(props)
        this.state = {
            preview: this.props.preview || false,
            form: this.props.employer.id ? this.props.employer : cache.ensure("EmployerEdit", this.props.employer),
            hr: null,
        }
    }

    componentDidMount() {
        if (!this.props.employer.id) setInterval(() => cache.set("EmployerEdit", this.state.form), 1000)
        actions.api.hr.getById({ id: this.props.employer.ownerId }, resp => {
            this.setState({ hr: resp })
        }, xhr => {
            //console.log(xhr)
        })
    }

    render() {
        let employer = this.state.form
        let owner = this.state.hr ? `${this.state.hr.position} ${this.state.hr.name}` : "管理员"
        let isAdmin = location.pathname.indexOf("/admin") === 0
        let isSuperAdmin = isAdmin && this.props.superAdminMode
        let notMine = employer.ownerId && isAdmin && !isSuperAdmin

        let edit = (
            <div style={{ padding: "2.5rem" }}>
                <Box>
                    <div style={{ width: "25rem" }}>
                        {elements.settingItem(
                            this, "form.name", true,
                            "招聘主体", "", {
                                disabled: !!employer.ownerId && !isSuperAdmin,
                                onBlur: () => this.state.form.name = this.state.form.name.trim()
                            }
                        )}
                        {elements.settingItem(
                            this, "form.teamSize", false,
                            "团队/单位规模", "", {
                                _onChange: util.intRange(1, 1e8),
                                disabled: notMine,
                            },
                        )}
                        {elements.settingItem(
                            this, "form.finance", false,
                            "科研经费/融资情况", "", {
                                disabled: notMine,
                            }
                        )}
                    </div>
                    <Item flex />
                    <div style={{ width: "9rem" }}>
                        <UploadFile title="上传单位logo" reUploadTitle="上传单位logo"
                            style={{ height: "9rem", width: "9rem" }}
                            maxFilesize={1} disabled={notMine}
                            url={this.state.form.logo} onChange={resp => {
                                this.state.form.logo = resp.url
                                this.setState({})
                            }} />
                        <Button basic size="tiny" style={{ padding: ".5em", width: "9rem", margin: "1rem 0" }}
                            onClick={() => {
                                this.state.form.logo = "/assets/images/employer-default.png"
                                this.setState({})
                            }}>
                            使用领研网默认logo
                        </Button>
                        <div className="text-minor" style={{ fontSize: ".8rem", marginTop: "-0.5em" }}>
                            单位logo会显示在首页及职位详情页。上传要求：正方形PNG/JPG图片，大小不超过1M。
                        </div>
                    </div>
                </Box>
                <ErrorTooltip checker={this.formChecker} id="form.teamIntro"
                    getTip={() => {
                        if (!employer.teamIntro) {
                            return null
                        } else if (employer.teamIntro.trim().length < 10) {
                            return "至少10个字符"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.teamIntro", false, "单位介绍", "（1000字符以内）",
                    { type: "textarea", maxlength: 1000, disabled: notMine }
                )}
                <ErrorTooltip checker={this.formChecker} id="form.researchField"
                    getTip={() => {
                        if (!employer.researchField) {
                            return null
                        } else if (employer.researchField.trim().length < 10) {
                            return "至少10个字符"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.researchField", false, "研究方向/业务范围", "（800字符以内）",
                    { type: "textarea", maxlength: 800, disabled: notMine }
                )}
                <ErrorTooltip checker={this.formChecker} id="form.projects"
                    getTip={() => {
                        if (!employer.projects) {
                            return null
                        } else if (employer.projects.trim().length < 10) {
                            return "至少10个字符"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.projects", false, "5年内承担的课题", "（1000字符以内）",
                    { type: "textarea", maxlength: 1000, disabled: notMine }
                )}
                <ErrorTooltip checker={this.formChecker} id="form.theses"
                    getTip={() => {
                        if (!employer.theses) {
                            return null
                        } else if (employer.theses.trim().length < 10) {
                            return "至少10个字符"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.theses", false, "已发表的代表性论文", "（1000字符以内）",
                    { type: "textarea", maxlength: 1000, disabled: notMine }
                )}
                <ErrorTooltip checker={this.formChecker} id="form.patents"
                    getTip={() => {
                        if (!employer.patents) {
                            return null
                        } else if (employer.patents.trim().length < 10) {
                            return "至少10个字符"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.patents", false, "已获专利", "（1000字符以内）",
                    { type: "textarea", maxlength: 1000, disabled: notMine }
                )}
                <ErrorTooltip checker={this.formChecker} id="form.website"
                    getTip={() => {
                        if (!employer.website) {
                            return null
                        } else if (!isURL(employer.website.trim())) {
                            return "无效的 URL"
                        } else {
                            return null
                        }
                    }} />
                {elements.settingItem(
                    this, "form.website", false, "网站链接", "", {
                        disabled: notMine,
                        placeholder: "以 http:// 或 https:// 开头",
                    }
                )}
                {isAdmin ? elements.settingItem(
                    this, "form.tags", false, "标签", "", { type: "tags" }
                ) : null}
                {isAdmin ? elements.settingItem(
                    this, "form.weight", false, "推荐指数", "", { type: "number" }
                ) : null}
                {util.minHeight(".5rem")}
                <Box center>
                    {util.minWidth("1.25rem")}
                    <Button primary style={{ width: "10rem" }}
                        onClick={() => {
                            (document.getElementById("root") as any).scrollTop = 0
                            if (!this.state.form.logo) return
                            this.formChecker.check(() => {
                                actions.api.employer.update({
                                    type: isAdmin ? "ADMIN" : "HR",
                                    form: this.state.form,
                                }, this.props.onFinish)
                            })
                        }}>
                        保存
                    </Button>
                    {util.minWidth("1.25rem")}
                    <Button basic style={{ width: "10rem" }} onClick={this.props.onFinish}>
                        取消
                    </Button>
                    {util.minWidth("1.25rem")}
                    <div className="text-primary clickable"
                        onClick={() => {
                            this.setState({ preview: true }, () => {
                                (document.querySelector("#root") as any).scrollTop = 0
                            })
                        }}>
                        <u>预览</u>
                    </div>
                </Box>
            </div>
        )

        let preview = employer ? (
            <div style={{ padding: "2.5rem", lineHeight: 1 }}>
                <div className="text-center" style={{ fontWeight: 700 }}>
                    <Box centerJustified>
                        {util.imageCenter(employer.logo, { height: "7.5rem", width: "7.5rem" })}
                    </Box>
                    {util.minHeight("1.25rem")}
                    {employer.name}
                    <div className="pre-wrap" style={{ fontSize: ".9rem" }}>
                        {employer.teamSize > 0 || employer.finance ? [
                            util.minHeight("1.25rem"),
                            [employer.teamSize > 0 ? `${employer.teamSize}人` : "", employer.finance].filter(x => !!x).join("  |  ")
                        ] : null}
                        {util.minHeight("1.25rem")}
                        <Box center centerJustified>
                            <Icon name="user" style={{ color: "#ddd", marginRight: ".25em" }} />
                            <div>
                                创建者：{owner}
                            </div>
                        </Box>
                    </div>
                </div>
                <div className="text-gray" style={{ marginTop: "3rem", lineHeight: 2, fontSize: ".8rem", wordBreak: "break-word" }}>
                    <b>单位简介：</b>
                    <div className="pre-wrap">{employer.teamIntro}</div>
                    {util.minHeight("2rem")}
                    <b>研究方向：</b>
                    <div className="pre-wrap">{employer.researchField}</div>
                    {util.minHeight("2rem")}
                    <b>发表论文：</b>
                    <div className="pre-wrap">{employer.theses}</div>
                    {util.minHeight("2rem")}
                    <b>网址：</b>
                    <a href={employer.website}>
                        <div className="text-primary">
                            {employer.website}
                        </div>
                    </a>
                    <div style={{ height: 1, background: "#ebebeb", margin: "2rem 0" }} />
                    <Box center style={{ fontWeight: 700 }}>
                        认证结果： {util.textMinor((() => {
                            switch (employer.certified) {
                                case "FAIL": return "不通过"
                                case "PASS": return "已认证"
                                case "ING": return "认证中"
                                default: return "未提交"
                            }
                        })())}

                        {util.minWidth("3rem")}
                        {this.props.onViewCert && employer.certified && this.props.superAdminMode ? (
                            <div className="text-admin clickable" onClick={this.props.onViewCert}>
                                查看认证资料
                            </div>
                        ) : null}
                    </Box>
                    {util.minHeight("2rem")}
                    <Box centerJustified>
                        <Button basic size="small" style={{ width: "10rem" }}
                            onClick={this.props.onReturn || (() => this.setState({ preview: false }))}>
                            返回
                        </Button>
                    </Box>
                </div>
            </div>
        ) : null

        return (
            <Item relative className="bg-color-1">
                <SettingPanel title="单位设置">
                    {this.state.preview ? preview : edit}
                </SettingPanel>
            </Item>
        )
    }
}
