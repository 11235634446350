import * as React from "react"
import * as _ from 'lodash'
import * as mockxhr from 'mockxhr'
import * as util from "../util"
import {Box, Popup} from "./index"
import {API, actions} from "../flux"
import {Menu, Dropdown, Icon} from 'antd'

import "../styles/desktop/Header4.less"

interface Props {
    tab: string
    search?: string
    eventText?: string
    style?: any
}

interface State {
    session: any
    profile: any
    menu: boolean
    eventMenu: boolean
    rightMenuOpt: any[]
    topMenuOpt: any[]
    isScroll: boolean
    iconSrc: any
}

export default class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            session: null,
            profile: null,
            menu: false,
            eventMenu: false,
            rightMenuOpt: [
                {
                    text: '消息',
                    href: '/my/messages',
                },
                {
                    text: '收藏夹',
                    href: '/my/stars',
                },
                {
                    text: '投递箱',
                    href: '/my/applications',
                },
                {
                    text: '我的简历',
                    href: '/profile/resume',
                },
                {
                    text: '我的论文',
                    href: '/my/theses',
                },
                {
                    text: '帐号设置',
                    href: '/profile/account',
                },
                {
                    text: '发布职位',
                    href: '/login/hr',
                },
                {
                    text: '退出',
                    href: '/logout?redirect=/login',
                },
            ],
            topMenuOpt: [
                {
                    title: '期刊订阅',
                    link: `${location.origin}/information/25cf336e-7893-4745-b015-a707afac9f16`,
                },
                {
                    title: '论文订阅',
                    link: `${location.origin}/information/5252ebfc-1c74-46fe-ab62-f2720afa32cc`,
                },
                /*{
                  title: '会议活动',
                  link: '/meetings',
                },*/
            ],
            isScroll: false,
            iconSrc: {
                user: '/assets/icons/user.svg',
            }
        }
    }

    componentDidMount() {
        actions.login.get(session => this.setState({session}))
        actions.api.profile.get(profile => this.setState({profile}))
        mockxhr.POST(API.promotion.search("HOME_MENU", "_", 99, 0), {}, resp => {
            this.setState({
                topMenuOpt: [...this.state.topMenuOpt, ...resp.hits]
            })
        })
        let root = document.body.querySelector("#root") as HTMLElement
        root.addEventListener("scroll", () => {
            this.setState({isScroll: root.scrollTop > 0 ? true : false})
        })
    }

    entry(text: any, tab: string, href: string, menu?: any, extendStyle?: any) {
        // text: 论文
        // tab：PERIODICAL
        // href：/periodical
        // menu: null
        let style = this.props.style || {}
        let active = this.props.tab === tab
        return (
            <div className="head-menu-tab">
                <a key={tab}
                   href={href === "/search" && location.protocol === "https:" ? "https://jobs.linkresearcher.com/search" : href}
                   style={{display: "block", height: 62, marginRight: 50, fontWeight: 600}}>
                    <Box relative vertical justified style={{height: "100%", color: style.color || "white"}}
                         onMouseEnter={() => menu ? this.setState({eventMenu: true}) : 0}
                         onMouseLeave={() => menu ? this.setState({eventMenu: false}) : 0}>
                        {util.minHeight(4)}
                        <div className={style.textHover || "text-hover-h2"} style={{
                            fontSize: 16,
                            color: active ? "#290382" : extendStyle ? extendStyle.color : '#000'
                        }}>
                            {text}
                        </div>
                        <div style={{minHeight: 4, background: active ? "#290382" : undefined}}/>
                        {menu}
                    </Box>
                </a>
                {
                    text === "期刊" && <div className="new-container">
                        <img className="new" src="https://cdn.linkresearcher.com/main/commerce/new.png" alt="new"/>
                    </div>
                }
            </div>
        )
    }

    render() {
        let {rightMenuOpt, profile, session, topMenuOpt, iconSrc} = this.state
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId
        let style = this.props.style || {}

        return [
            <div key='0' style={{height: 92}}/>,
            <div key='1' className='top-menu'>
                <Box center style={{
                    minWidth: style.minWidth,
                    maxWidth: style.maxWidth,
                    marginLeft: 334,
                }}>
                    {_.map(topMenuOpt, (i, key) => {
                        return <a href={i.link} key={key} onClick={() => {
                            if ('id' in i) {
                                mockxhr.POST(`api/promotions/${i.id}/click`, {})
                            }
                        }}>{i.title}</a>
                    })}
                </Box>
            </div>,
            <div key='2' className="min-view-center main-menu"
                 style={{boxShadow: `${this.state.isScroll ? '' : '0px 1px 3px rgba(51, 51, 51, 0.2)'}`}}>
                <div className="main-menu-center" style={{minWidth: style.minWidth, maxWidth: style.maxWidth}}>
                    <div className="main-menu-left main-menu-block">
                        <div style={{lineHeight: 1, width: 167}}>
                            <a href={'/'}>
                                <img src={'/assets/images/logo.png'} width={98} height={37}/>
                            </a>
                        </div>
                        {this.entry("首页", "INDEX", "/", null)}
                        {/* TODO:上线时先隐藏该导航栏，但是路由仍可访问 */}
                        {this.entry("论文", "THESIS", "/theses", null)}
                        {this.entry("期刊", "PERIODICAL", "/periodical", null)}
                        {this.entry("新闻", "NEWS", "/information", null)}
                        {this.entry("招聘", "JOB", "/search", null)}
                        {this.entry("职业生涯", "CAREER", "/careers", null)}
                        {/* {this.entry("学术公众号数据库", "", "http://rank.linkresearcher.com", null)} */}
                    </div>
                    <div className="main-menu-right main-menu-block">
                        {this.props.search &&
                            <div>
                                <div
                                    onClick={() => {
                                        window.location.href = `/searchall?tab=${this.props.search}`
                                    }}
                                    style={{
                                        display: 'block',
                                        height: '62px',
                                        lineHeight: '62px',
                                        marginRight: '29px',
                                        color: '#fff',
                                        fontSize: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Icon type="search" className='search-button'/>
                                </div>
                            </div>
                        }
                        <Popup position="bottom center" trigger={
                            <div className='official-wechat' style={{fontSize: this.state.profile ? 16 : 14}}>
                                官方微信
                            </div>
                        } content={
                            <Box style={{fontSize: 16}}>
                                <div className="text-center">
                                    <img src={util.imageURL("qrcode-old.jpg")} style={{width: 126, height: 126}}/>
                                    <div>订阅号</div>
                                </div>
                                <div className="text-center" style={{marginLeft: 10}}>
                                    <img src={util.imageURL("qrcode-new.jpg")} style={{width: 126, height: 126}}/>
                                    <div>服务号</div>
                                </div>
                            </Box>
                        }/>
                        <a
                            className='publish-office-button'
                            href="/login/hr"
                        >
                            发布职位
                        </a>
                        {profile ?
                            <Dropdown overlay={
                                <Menu style={{width: '130px'}}>
                                    {_.map(rightMenuOpt, i => {
                                        return (
                                            <Menu.Item key={i.href}>
                                                <a href={i.href}>
                                                    {i.text}
                                                </a>
                                            </Menu.Item>
                                        )
                                    })}
                                </Menu>
                            }>
                                <img
                                    onMouseEnter={() => this.setState({
                                        iconSrc: {
                                            ...this.state.iconSrc,
                                            user: '/assets/icons/user-purple.svg'
                                        }
                                    })}
                                    onMouseLeave={() => this.setState({
                                        iconSrc: {
                                            ...this.state.iconSrc,
                                            user: '/assets/icons/user.svg'
                                        }
                                    })}
                                    className='user-icon'
                                    src={iconSrc.user}/>
                            </Dropdown> :
                            <a href={isVisitor ? '/join' : `/login?redirect${encodeURIComponent(location.pathname)}`}>
                                <img
                                    onMouseEnter={() => this.setState({
                                        iconSrc: {
                                            ...this.state.iconSrc,
                                            user: '/assets/icons/user-purple.svg'
                                        }
                                    })}
                                    onMouseLeave={() => this.setState({
                                        iconSrc: {
                                            ...this.state.iconSrc,
                                            user: '/assets/icons/user.svg'
                                        }
                                    })}
                                    className='user-icon'
                                    src={iconSrc.user}/>
                            </a>
                        }
                    </div>
                </div>
            </div>
        ]
    }
}
