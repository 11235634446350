import * as React from "react"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"

import * as util from "../util"
import { Icon, Popup } from "."

interface Props {
    sourceKey?: number
    skipId?: string
    style?: React.CSSProperties
}

interface State {
    media: any
    total: number
    items: any[]
}

export default class Media extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            media: null,
            total: 0,
            items: [],
        }
    }

    componentDidMount() {
        if(this.props.sourceKey) {
            mockxhr.GET(`/api/source/${this.props.sourceKey}`, {}, resp => {
                this.setState({ media: resp })
            })
            mockxhr.GET(`/api/medium/${this.props.sourceKey}/articles`, {}, resp => {
                this.setState({
                    total: resp.total,
                    items: resp.hits.sort((a, b) => b.onlineTime - a.onlineTime)
                })
            })
        } 
    }

    render() {
        if (!this.state.media) return null
        if (!this.state.media.qrCode && !this.state.media.url) return null

        let buttonStyle = {
            border: "1px solid #290382", fontSize: 12, lineHeight: 1,
            padding: "7px 0", borderRadius: 2, width: 76,
            display: "block", textAlign: "center",
        }
        let media = this.state.media
        return (
            <div id='media' style={{ padding: 24, background: "white", ...this.props.style }}>
                <Box center style={{ marginLeft: '8px' }}>
                    {util.imageContain(media.logo, 60, { borderRadius: 999 })}
                    {util.minWidth(12)}
                    <Item flex style={{ lineHeight: 1, overflow: "hidden", maxWidth: '180px' }}>
                        <div style={{ fontSize: 18, lineHeight: '25px', marginLeft: '4px' }}>
                            {media.sourceName || media.sourceid}
                        </div>
                    </Item>
                </Box>
                <div>
                    <div style={{ display: 'inline-block', marginRight: '8px' }}>
                        {media.qrCode ?
                            <Popup position="bottom right"
                                header={<img src={media.qrCode} style={{ width: 120, verticalAlign: "top" }} />}
                                trigger={
                                    <div className="text-primary" style={buttonStyle}>
                                        关注公众号
                                    </div>
                                }
                            />
                            :
                            <a href={media.url} target="_blank" className="text-primary" style={buttonStyle}>
                                进入网站
                            </a>
                        }
                    </div>
                    <div style={{ display: 'inline-block' }}>
                        <div style={{ fontSize: 12, color: "#999", marginTop: 18 }}>
                            共 {this.state.total} 篇文章
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 12, color: "#999", lineHeight: "20px", marginTop: 10 }}>
                    {media.introduce}
                </div>
                {this.state.items.filter(r => r.id !== this.props.skipId).slice(0, 3).map(r =>
                    <Box key={r.id} style={{ fontSize: 14, lineHeight: "24px", marginTop: 15 }}>
                        <Icon name="caret right" className="text-primary" />
                        <Item flex>
                            <a href={r.url} target="_blank" className="text-hover-primary">
                                {r.title}
                            </a>
                        </Item>
                    </Box>
                )}
                <Box centerJustified style={{ padding: "16px 24px 0px" }}>
                    <a className="text-primary" target="_blank"
                        href={`/searchall?tab=most&query=source: ${media.sourceid || media.sourceName}`}
                        style={{ fontSize: 12, lineHeight: 1 }}>
                        查看更多
                    </a>
                </Box>
            </div>
        )
    }
}
