export const OCCUPATION = [
    { value: "本科生" },
    { value: "硕士生" },
    { value: "博士生" },
    { value: "博士后" },
    { value: "讲师" },
    { value: "助理研究员" },
    { value: "副教授/副研究员" },
    { value: "教授/研究员" },
    { value: "技术人员" },
    { value: "行政人员" },
    { value: "企业研发人员" },
    { value: "其他" },
]

export const DEGREE = [
    { value: "学士" },
    { value: "硕士" },
    { value: "博士" },
]

// 高校只用前两个，所以不能调整列表顺序
export const EXPERIENCE = [
    { value: "不限" },
    { value: "应届" },
    { value: "3年以下" },
    { value: "3~5年" },
    { value: "5~10年" },
    { value: "10年以上" },
]

export const SUBJECT = [
    { value: "数学" },
    { value: "生物" },
    { value: "医学" },
    { value: "物理" },
    { value: "天文学" },
    { value: "计算机" },
    { value: "药学" },
    { value: "农学" },
    { value: "林学" },
    { value: "生态" },
    { value: "环境" },
    { value: "地球科学" },
    { value: "电子" },
    { value: "电气" },
    { value: "通信" },
    { value: "机械" },
    { value: "化学" },
    { value: "材料" },
    { value: "化工" },
    { value: "心理学" },
    { value: "地质" },
    { value: "古生物" },
    { value: "地理学" },
    { value: "经济学" },
    { value: "能源" },
    { value: "其它" },
]

export const ACCOUNT_STATUS = {
    "DISABLED": "封号",
    "SILENT": "禁言",
    "OK": "正常",
}

export const EVENT_PROCESS = {
    "报名中": {
        color: "red",
        background: "#29057e",
    },
    "进行中": {
        color: "green",
        background: "#45cdfa",
    },
    "已结束": {
        color: "grey",
        background: "#999",
    },
}

export const INFORMATION_COLUMN_WEIGHT = {
    "科研圈精选": 224,
    "前沿": 218,
    "人物": 101,
    "Springer Nature OA": 69,
    "科学思想": 46,
    "FUN TO KNOW": 45,
    "征稿信息": 35,
    "诺奖": 33,
    "观察": 32,
    "论文合集": 31,
    "业界": 29,
    "深度": 26,
    "趋势": 26,
    "科学史": 25,
    "人工智能编年史": 18,
    "行业趋势": 18,
    "思想": 16,
    "人才": 11,
    "机器之心": 10,
    "科学哲学": 9,
    "行业报告": 8,
    "趣闻": 6,
    "生物探索": 6,
    "观点": 6,
    "人工智能": 5,
    "原理": 5,
    "基金": 4,
    "出版": 4,
    "神经科学": 3,
    "期刊": 3,
    "学术综述": 3,
    "《环球科学》新刊导读": 3,
    "BioArt": 3,
    "会议见闻": 3,
    "工具": 2,
    "见闻": 2,
    "入门级综述": 2,
    "会议": 2,
    "学术工具": 2,
    "综述": 2,
    "知社学术圈": 1,
    "榜单": 1,
    "中国物理学会期刊网": 1,
    "中科院高能所": 1,
    "科研职位发展": 1,
    "PaperWeekly": 1,
    "机器人": 1,
    "最佳雇主": 1,
    "时间晶体": 1,
}
