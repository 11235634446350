import * as React from "react"

export const util = {
    iconURL(suffix: string) {
        return `/assets/icons/${suffix}`
    },

    avatar(url) {
        return url || this.iconURL("user-avatar.png")
    },

    countAge(yyyymm) {
        if (!yyyymm) return 0
        let year = parseInt(yyyymm.split("-")[0])
        let month = parseInt(yyyymm.split("-")[1])
        let age = new Date().getFullYear() - year
        if (month <= new Date().getMonth() + 1) age += 1
        return Math.max(0, age - 1)
    },

    thesisTitle(item) {
        let list = [
            item.allAuthors,
            item.thesisTitle && <b>{item.thesisTitle}</b>,
            item.periodical && <i>{item.periodical}</i>,
            item.publishDate && item.publishDate.replace(/-/g, "/"),
            item.link && <div><a href={item.link} target="_blank">{item.link}</a></div>
        ].filter(x => !!x)
        let result: any[] = []
        for (let i = 0; i < list.length; i++) {
            if (i > 0) result.push(".")
            result.push(list[i])
        }
        return result
    },

    patentTitle(item) {
        let list = [
            item.authNumber,
            item.name,
            item.allInventors,
        ].filter(x => !!x)
        let result: any[] = []
        for (let i = 0; i < list.length; i++) {
            if (i > 0) result.push(".")
            result.push(list[i])
        }
        return result
    },

    salaryText(job: any) {
        let min = parseFloat(job.minSalary) || 0
        let max = parseFloat(job.maxSalary) || 0
        if (job.salaryType === "negotiate") {
            return "面议"
        } else if (min === max) {
            return min + "万"
        } else if (min > max) {
            return min + "万以上"
        } else {
            return `${min}-${max}万`
        }
    },
}

interface Props {
    profile: any
}

interface State {
}

export default class ResumeDoc extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        setTimeout(() => this.download(), 1000)
    }

    download() {
        (window as any).$(document).googoose({
            filename: this.props.profile.name + ".doc",
            display: "Print",
            lang: "zh-CN",
        })
    }

    tableRow(td1, td2) {
        return (
            <p style={{ fontSize: "11pt", fontFamily: "Microsoft YaHei" }}>
                <div>
                    {td1}
                </div>
                <div style={{ textAlign: "right" }}>
                    {td2}
                </div>
            </p>
        )
    }

    render() {
        let profile = this.props.profile

        let color = "#2f5597"

        let doc = (
            <div className="googoose-wrapper"
                style={{
                    width: "480pt", color: "#000", fontSize: "11pt", fontFamily: "Microsoft YaHei",
                    lineHeight: "120%",
                }}>
                <div style={{ textAlign: "center" }}>
                    <img src={util.avatar(profile.avatar) + "?imageMogr2/thumbnail/400x/gravity/Center/crop/400x400"} width="100" height="100" />
                    <div style={{ fontSize: "18pt", fontWeight: 700 }}>
                        {profile.name}
                    </div>
                    <div style={{ fontSize: "11pt" }}>
                        <div>
                            {profile.gender === 2 ? "女" : "男"}
                            &ensp;
                            |
                            &ensp;
                            {util.countAge(profile.birth)}岁
                            &ensp;
                            |
                            &ensp;
                            {profile.degree}
                        </div>
                        <div>
                            研究方向：{profile.researchField}
                        </div>
                        <div>
                            {profile.major} {profile.occupation}
                            &emsp;
                            |
                            &emsp;
                            {profile.organization} {profile.department}
                            &emsp;
                            |
                            &emsp;
                            {profile.city}
                        </div>
                        <div>
                            求职意向：{(profile.intentions || []).map(i => i.position).join("，")}
                        </div>
                        <div>
                            {profile.phone}
                            &emsp;
                            |
                            &emsp;
                            {profile.email}
                        </div>
                    </div>
                </div>
                <br />
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    教育背景
                </p>
                {(profile.educations || []).map(edu =>
                    <p style={{ fontSize: "11pt" }}>
                        {this.tableRow(
                            <b>{edu.college} - {edu.school}</b>,
                            <span>
                                {edu.startDate.replace("-", ".")}
                                -
                                {edu.endDate === "-" ? "至今" : edu.endDate.replace("-", ".")}
                            </span>
                        )}
                        <div>
                            {edu.major}{edu.degree}
                        </div>
                        <div>
                            导师：{edu.mentor}
                        </div>
                        <div>
                            研究方向：{edu.researchField}
                        </div>
                        <br />
                    </p>
                )}
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    工作经历
                </p>
                {(profile.experiences || []).map(exp =>
                    <p style={{ fontSize: "11pt" }}>
                        {this.tableRow(
                            <b>{exp.employer}</b>,
                            <span>
                                {exp.startDate.replace("-", ".")}
                                -
                                {exp.endDate === "-" ? "至今" : exp.endDate.replace("-", ".")}
                            </span>
                        )}
                        <div>
                            {exp.department} / {exp.position}
                        </div>
                        <div>
                            {exp.work}
                        </div>
                        <br />
                    </p>
                )}
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    课题／项目经历
                </p>
                {(profile.projects || []).map(pro =>
                    <p style={{ fontSize: "11pt" }}>
                        {this.tableRow(
                            <b>{pro.name}</b>,
                            <span>
                                {pro.startDate.replace("-", ".")}
                                -
                                {pro.endDate === "-" ? "至今" : pro.endDate.replace("-", ".")}
                            </span>
                        )}
                        <div>
                            个人职责：{pro.work}
                        </div>
                        <div>
                            项目描述：{pro.description}
                        </div>
                        <div>
                            {pro.employer} | {pro.sponsor} | 负责人：{pro.director}
                        </div>
                        <div>
                            链接：{pro.link}
                        </div>
                        <br />
                    </p>
                )}
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    学术成果
                </p>
                <p style={{ fontSize: "11pt" }}>
                    <div><b>论文</b></div>
                    {profile.thesisList ?
                        (profile.thesisList || []).map((t, i) => <div>[{i + 1}] {util.thesisTitle(t)}</div>)
                        :
                        (profile.theses || []).filter(t => !!t).map((t, i) => <div>{i + 1}. {t}</div>)
                    }
                    <br />
                </p>
                <p style={{ fontSize: "11pt" }}>
                    <div><b>专利</b></div>
                    {profile.patentList ?
                        (profile.patentList || []).map((t, i) => <div>[{i + 1}] {util.patentTitle(t)}</div>)
                        :
                        (profile.patents || []).filter(t => !!t).map((t, i) => <div>{i + 1}. {t}</div>)
                    }
                    <br />
                </p>
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    所获奖励
                </p>
                {(profile.awards || []).map(award =>
                    <p style={{ fontSize: "11pt" }}>
                        {this.tableRow(
                            <b>{award.name}</b>,
                            award.date.replace("-", ".")
                        )}
                        <div>
                            颁发单位：{award.host}
                        </div>
                        <br />
                    </p>
                )}
                <hr />
                {profile.recommendations && profile.recommendations.length ? (
                    <p>
                        {this.tableRow(
                            <span style={{ fontSize: "14pt", fontWeight: 700, color }}>
                                推荐信
                            </span>,
                            <span style={{ color: "#000", fontSize: "12pt" }}>
                                详见附件
                            </span>
                        )}
                        <br />
                    </p>
                ) : null}
                {(profile.recommendations || []).map(re =>
                    <p style={{ fontSize: "11pt" }}>
                        <div>
                            <b>推荐人：{re.name}</b>
                        </div>
                        <div>
                            工作单位：{re.employer}
                        </div>
                        <div>
                            邮箱地址：{re.email}
                        </div>
                        <br />
                    </p>
                )}
                <hr />
                {profile.supplements && profile.supplements.length ? (
                    <p>
                        {this.tableRow(
                            <span style={{ fontSize: "14pt", fontWeight: 700, color }}>
                                补充材料
                            </span>,
                            <span style={{ color: "#000", fontSize: "12pt" }}>
                                详见附件
                            </span>
                        )}
                    </p>
                ) : null}
                {(profile.supplements || []).map(sup =>
                    <p style={{ fontSize: "11pt", fontWeight: 700 }}>
                        {sup.name}
                        <br />
                    </p>
                )}
                <hr />
                <p style={{ fontSize: "14pt", fontWeight: 700, color }}>
                    求职意向
                </p>
                {(profile.intentions || []).map(i =>
                    <p style={{ fontSize: "11pt" }}>
                        <div>
                            <b>{i.position}{i.available ? "（正在求职）" : ""}</b>
                        </div>
                        <div>
                            {i.institute ? "科研" : i.industry} | {i.location} | {util.salaryText(i)}
                        </div>
                        <br />
                    </p>
                )}
            </div>
        )

        return (
            <div style={{ height: 0, overflow: "hidden" }}>
                {doc}
            </div>
        )
    }
}
