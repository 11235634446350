import * as React from "react"

import { Input, Icon, Box } from "./index"
import * as util from "../util"

interface Props {
    total: number
    from: number
    size: number
    onChange?: any
    highlightClass?: string
}

interface State {
    page: number
}

const style = {
    cursor: "pointer",
    fontSize: "1.2rem",
    lineHeight: "1em",
    color: "#999",
}

export default class Pager extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            page: Math.floor(this.props.from / this.props.size) + 1
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.from !== this.props.from) {
            this.setState({
                page: Math.floor(this.props.from / this.props.size) + 1
            })
        }
    }

    handleNumber(n: number) {
        if (this.props.onChange && n) {
            this.props.onChange(this.props.size, this.props.size * (n - 1))
        }
    }

    handlePrev() {
        if (this.props.onChange && this.props.from >= this.props.size) {
            this.setState({ page: this.state.page - 1 })
            this.props.onChange(this.props.size, this.props.from - this.props.size)
        }
    }

    handleNext() {
        if (this.props.onChange && this.props.from + this.props.size < this.props.total) {
            this.setState({ page: this.state.page + 1 })
            this.props.onChange(this.props.size, this.props.from + this.props.size)
        }
    }

    render() {
        let total = Math.max(1, Math.ceil(this.props.total / this.props.size))
        return (
            <Box centerJustified center style={{ height: "1.25rem", fontSize: ".9rem" }}>
                <Icon name="angle left" style={{ ...style }} onClick={() => this.handlePrev()} />
                {util.minWidth(".5em")}
                <Input size="mini" value={this.state.page} style={{ width: "3rem" }} className="pager-input"
                    onBlur={() => {
                        this.handleNumber(this.state.page)
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13) this.handleNumber(this.state.page)
                    }}
                    onChange={(e, { value }) => {
                        let page = parseInt(value)
                        if (page) {
                            page = Math.max(page, 1)
                            page = Math.min(page, total)
                            this.setState({ page })
                        } else {
                            this.setState({ page: "" as any })
                        }
                    }} />
                {util.minWidth(".5em")}
                <div className="text-minor">
                    / {total}
                </div>
                {util.minWidth(".5em")}
                <Icon name="angle right" style={{ ...style }} onClick={() => this.handleNext()} />
            </Box>
        )
    }
}
