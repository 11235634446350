import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"

interface Props {
    relevant: any[]
    hide?: boolean
}

export default class Relevant extends React.Component<Props, {}> {
    render() {
        let { relevant, hide } = this.props
        if (!relevant || !relevant.length || !relevant.some(r => r.id)) return null

        let that = this
        relevant.filter(r => r.id && r.timestamp === 0).forEach(r => {
            r.timestamp = Date.now()
            let url = (r.source || "")
                .replace("/careers", "/information")
                .replace("/lectures", "/meetings")
                .replace("/trainings", "/meetings")
                .replace("/lives", "/meetings")
                .replace("/studytours", "/meetings")
                .replace("/contests", "/meetings")
            mockxhr.GET("/api" + url, {}, res => {
                r.onlineTime = res.onlineTime
                r.cover = res.cover || res.wxShareImage
                r.sourceName = util.getSourceName(res.source)
                that.setState({})
            })
        })

        if (hide) return null

        let items = relevant.filter(r => !!r.id)

        return (
            <div style={{ marginBottom: 24, background: "white" }}>
                <div className="text-primary"
                    style={{
                        fontSize: 18, padding: 24, lineHeight: 1, textAlign: "left",
                        borderBottom: "1px solid #f2f2f2", fontWeight: 500,
                    }}>
                    相关推荐
                </div>
                {items.slice(0, 3).map((item, i) =>
                    <a target="_blank" href={item.source} key={item.id}
                        style={{ padding: "24px 0", margin: "0 24px", display: "block", borderTop: i > 0 ? "1px solid #f2f2f2" : "none" }}>
                        {util.imageMask(item.cover, 278, 169, () => 0)}
                        <div className="link-hover"
                            style={{ fontSize: 16, lineHeight: "24px", marginTop: 10, overflow: "hidden", maxHeight: 48, color: "#333" }}>
                            {item.target}
                        </div>
                        <div className="ellipsis"
                            style={{ margin: "8px 0 -2px", fontSize: 12, color: "#333", cursor: "default", lineHeight: "16px" }}>
                            {item.sourceName} &middot; {util.textMinor(util.formatTs(item.onlineTime))}
                        </div>
                    </a>
                )}
            </div>
        )
    }
}
