import * as HttpStatus from "http-status-codes"
import * as React from "react"
import * as mockxhr from "mockxhr"
import * as _ from "lodash"

import * as util from "../util"
import Modal, { Props as ModalProps } from "./Modal"
import { Box, Item, Icon, Popup, elements } from "."
import { actions } from "../flux"
import { 
    message, 
    Select,
    DatePicker,
} from 'antd';
import * as moment from 'moment'

const { Option } = Select;

interface Props {
    update?: boolean
    subjects: subject[]
}

interface subject {
    value: string
}

interface State {
    paper: any
    summaryEditor: any
    handleSubmit: any
    periodicalList: IPeriodical[],
    optionList: optionItem[],
    isLoading: boolean
}

interface IPeriodical {
    periodicalId: string | number,
    checkname: string,
}

interface optionItem {
    periodicalId: string | number,
    checkname: string
}

export default class Edit extends React.Component<Props, State> {
    fetching = false

    constructor(props) {
        super(props)
        this.state = {
            paper: {},
            summaryEditor: null,
            handleSubmit: null,
            periodicalList: [],
            optionList: [],
            isLoading: false
        }
    }
    
    componentDidMount() {
        mockxhr.POST("/api/periodical/do?from=0&size=2000&type=SEARCH", {
            periodicalDao: {name: ""},
            sort: [],
        }, resp => {
            this.setState({
                periodicalList: _.map(resp.hits.content, i => {
                    return {
                        periodicalId: i.id,
                        checkname: i.checkname,
                        pname: i.pname,
                        pchname: i.chname,
                        pdname: i.pdname,
                    }
                })
            })
        })
        mockxhr.GET("api/periodical/checkname/match?checkName=", {
        }, resp => {
            this.setState({
                optionList: _.map(resp, i => {
                    return {
                        periodicalId: i.id,
                        checkname: i.checkname,
                    }
                })
            })

        })
    }

    show(paper, handleSubmit?) {
        this.setState({ paper, handleSubmit: handleSubmit || null }, () => {
            (this.refs.modal as Modal).show()
            const editor = this.refs.summaryEditor as HTMLDivElement;
            // 删除原有编辑器内容（避免循环嵌套生成）
            if (editor) {
                editor.innerHTML = '';       
            }
            this.setState({
                summaryEditor: util.createWangEditor(this, "summaryEditor", "paper.summary"),
            }, () => {
                // 将原有内容放回编辑器中
                this.state.summaryEditor.txt.html(this.state.paper.summary)
            })
        })
    }

    canSubmit() {
        let { title, checkname, authors, publishDate, summary, subjects, doi } = this.state.paper
        // put 请求 没有checkname字段 临时的解决方法
        if(!checkname) {
            checkname = util.getCacheObjItem('paperEditDoiCheckname', doi)
        }
        return !!title && title.length <= 300 && !!checkname && !! this.isValidDateFormat(publishDate) && !!summary &&
        // return !!title && !!checkname && !!publishDate && !!summary &&
            !!authors && authors.length > 0 &&
            !!subjects && subjects.length > 0
    }

    checkHtmlEmpty (htmlContent: string) {
        const dom = document. createElement ('div' ) ;
        dom. innerHTML = htmlContent;
        return (dom. textContent || '').trim() == ''
    }

    isValidDateFormat(dateStr) {  
        // 爬虫爬的格式为："2024-03-19T00:00:00"
        var regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?$/;  
        return regex.test(dateStr);  
    }

    render() {
        let that = this
        let modalProps: ModalProps = {
            title: "编辑论文",
            scrollContent: true,
            confirmText: "保存",
            cancelText: true,
            disabled: !this.canSubmit(),
            onConfirm() {
                try {
                    if (that.state.isLoading) return

                    let periodicalId

                    if (_.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.checkname : that.state.paper.journal === i.checkname)[0]) {
                        periodicalId = _.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.checkname : that.state.paper.journal === i.checkname)[0].periodicalId
                    } else if (_.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.pname : that.state.paper.journal === i.pname)[0]) {
                        periodicalId = _.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.pname : that.state.paper.journal === i.pname)[0].periodicalId
                    } else if (_.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.pchname : that.state.paper.journal === i.pchname)[0]) {
                        periodicalId = _.filter(that.state.periodicalList, i => that.state.paper.checkname ? that.state.paper.checkname === i.pchname : that.state.paper.journal === i.pchname)[0].periodicalId
                    } else if (_.filter(that.state.periodicalList, i => that.state.paper.checkname ? i.pdname.includes(that.state.paper.checkname) : i.pdname.includes(that.state.paper.journal))[0]) {
                        periodicalId = _.filter(that.state.periodicalList, i => that.state.paper.checkname ? i.pdname.includes(that.state.paper.checkname) : i.pdname.includes(that.state.paper.journal))[0].periodicalId
                    } else {
                        throw Error
                    }                    

                    that.setState({
                        paper: {
                            ...that.state.paper,
                            periodicalId,
                            publishDate: moment(that.state.paper.publishDate).format('YYYY-MM-DD'),
                        },
                        isLoading: true
                    }, () => {

                        // if (!that.isValidDateFormat(that.state.paper.publishDate)) {
                        //     util.toast.error("日期格式有误，请重新输入")
                        //     return
                        // }
                        
                        /*if (that.checkHtmlEmpty(that.state.paper.summary) ) {
                            that.setState({isLoading: false})
                            util.toast.error("论文摘要信息不能为空")
                            return
                        }*/

                        if (that.props.update) {
                            mockxhr.PUT("/api/papers/" + that.state.paper.id, that.state.paper, res => {
                                that.setState({isLoading: false})
                                if (that.state.handleSubmit) that.state.handleSubmit(res)
                                util.addCacheObjItem(`paperEditDoiCheckname`, res.doi, res.journal)
                            }, xhr => {
                                that.setState({isLoading: false})
                                util.toast.error(xhr.statusText)
                            })
                        } else {
                            mockxhr.POST("/api/papers", that.state.paper, res => {
                                that.setState({isLoading: false})
                                if (that.state.handleSubmit) that.state.handleSubmit(res)
                            }, xhr => {
                                that.setState({isLoading: false})
                                if (xhr.status === HttpStatus.CONFLICT) {
                                    util.toast.error("论文已存在")
                                } else {
                                    util.toast.error(xhr.statusText)
                                }
                            })
                        }
                    })

                    util.addCacheObjItem(`paperEditDoiCheckname`, that.state.paper.doi, that.state.paper.checkname)

                } catch (error) {
                    console.log(error)
                    that.setState({isLoading: false})
                    message.error('该期刊不存在，无法提交')
                }
            },
            content: (
                <div style={{ fontSize: "1rem" }}>
                    <Box center style={{ lineHeight: 1 }}>
                        {elements.settingTitle(
                            null,
                            <span>
                                论文链接
                                <a href={this.state.paper.link} target="_blank"
                                    style={{ verticalAlign: "text-bottom", marginLeft: ".5em" }}>
                                    <Icon name="external alternate" />
                                </a>
                            </span>
                            , ""
                        )}
                        <Item flex />
                        <Popup position="bottom center" on="hover"
                            trigger={
                                <Icon name={this.fetching ? "ellipsis horizontal" : "question circle outline"}
                                    style={{ color: "#999", lineHeight: ".9rem" }} />
                            }
                            content={
                                <div style={{ whiteSpace: "pre-wrap" }}>
                                    目前已经支持的网站有：
                                    http://journals.plos.org
                                    http://www.pnas.org
                                    http://www.sciencemag.org
                                    http://www.tandfonline.com
                                    https://academic.oup.com
                                    https://arxiv.org
                                    https://journals.aps.org
                                    https://link.springer.com
                                    https://pubs.acs.org
                                    https://www.biomedcentral.com
                                    https://www.cell.com
                                    https://www.nature.com
                                    https://www.sciencedirect.com
                                    https://www.thelancet.com
                                    https://pubs.acs.org/
                                    https://www.ahajournals.org/
                                    https://www.frontiersin.org
                                    https://onlinelibrary.wiley.com/
                                    https://gut.bmj.com/
                                    https://www.biorxiv.org/
                                    https://jamanetwork.com
                                    https://journals.sagepub.com
                                    https://iopscience.iop.org
                                    https://www.jci.org/（已完成 没有标签缩写）
                                    https://www.bmj.com
                                    https://ieeexplore.ieee.org
                                    https://dl.acm.org/dl.cfm
                                    https://www.geochemicalperspectivesletters.org
                                    https://physics.aps.org
                                    https://www.nejm.org
                                </div>
                            } />
                        <div className="clickable text-primary ml05" style={{ fontSize: ".9rem" }}
                            onClick={() => {
                                if (this.fetching) return
                                const link = this.state.paper.link
                                if (/^https?:\/\//.test(link)) {
                                    this.fetching = true
                                    this.setState({})
                                    actions.api.thesis.fromURL({ url: link }, res => {
                                        let paper = that.state.paper
                                        util.mergePaper(paper, res)
                                        this.state.summaryEditor.txt.html(paper.summary)
                                        this.fetching = false
                                        this.setState({})
                                    }, () => {
                                        util.toast.error("无法从该链接获取论文信息")
                                        this.fetching = false
                                        this.setState({})
                                    })
                                }
                            }}>
                            自动填写论文信息
                        </div>
                    </Box>
                    {elements.formInput(that, "paper.link", {
                        type: "trim",
                        style: { margin: "20px 0" },
                    })}
                    {elements.settingItem(that, "paper.doi", null, "数字识别码", "", {
                        disabled: true
                    })}
                    <div>
                        {elements.settingItem(that, "paper.title", null, "论文标题", "（必填）", {})}
                        {(that.state.paper.title && that.state.paper.title.length > 300) && <span style={{color:'red', fontSize: '14px', position: 'absolute', top: '41.35%', left: '160px'}}>标题字数超过上限，请再次确认</span>}
                    </div>
                    {elements.settingItem(that, "paper.authors", null, "论文作者", "（必填）", {
                        type: "tags",
                        splitable: true,
                    })}
                    {elements.settingTitle4("期刊", `（必填）${that.state.paper.journal ? '选择参考：' + that.state.paper.journal : ''}`)}
                    {
                        <Select
                            showSearch
                            allowClear
                            size='large'
                            style={{ 
                                width: '100%',
                                marginBottom: '20'
                            }}
                            value={that.state.paper.checkname || util.getCacheObjItem('paperEditDoiCheckname', that.state.paper.doi)}
                            onChange={value => {
                                if (!value) {
                                    this.setState({
                                        paper: {
                                            ...this.state.paper,
                                            // journal: '',
                                            checkname: '',
                                            periodicalId: '',
                                        }
                                    })
                                } else {
                                    this.setState({
                                        paper: {
                                            ...this.state.paper,
                                            // journal: value,
                                            checkname: value,
                                            periodicalId: `${_.filter(this.state.optionList, i => i.checkname === value)[0].periodicalId}`,
                                        }
                                    })
                                }
                            }}
                        >
                            {_.map(this.state.optionList, i => {
                                return (
                                    <Option key={i.periodicalId} value={i.checkname}>{i.checkname}</Option>
                                )
                            })}
                        </Select>
                    }
                    {util.minHeight(20)}
                    {/* {elements.settingTitle4('发表日期（必填）')} */}
                    <div style={{display: 'flex'}}>
                        {elements.settingTitle4('发表日期（必填）') }
                        {!that.isValidDateFormat(that.state.paper.publishDate) && <span style={{color:'red', fontSize: '14px'}}>请输入有效日期</span>}
                    </div>

                    {<DatePicker 
                        placeholder="YYYY-MM-DD"
                        size='large'
                        style={{ width: '100%' }}
                        value={ that.state.paper.publishDate ? moment(that.state.paper.publishDate) : undefined }
                        onChange={ (date, dateString) => that.setState({
                            paper: {
                                ...that.state.paper,
                                publishDate: dateString,
                            }
                        }) } 
                    />}
                    {util.minHeight(20)}
                    {elements.settingTitle(null, "论文摘要", "（必填）")}
                    <div ref="summaryEditor" className="w-e-text-container-20" style={{ margin: "1.25rem 0" }}>
                        <p />
                    </div>
                    {elements.settingItem(that, "paper.subjects", null, "学科", "（必填）", {
                        type: "tags",
                        upward: true,
                        couldNotEdit: true,
                        options: this.props.subjects.map(s => ({ value: s.value, text: s.value })),
                    })}
                    {elements.settingItem(that, "paper.tags", null, "标签", "", {
                        type: "tags",
                        upward: true,
                    })}
                </div>
            )
        }
        return <Modal ref="modal" {...modalProps} />
    }
}
