import * as React from "react"
import * as ReactDOM from "react-dom"

import * as util from "../util"
import { Button, Box, Item } from "./index"

export interface Props {
    innerWidth?: string
    title: string
    confirmText?: any
    cancelText?: any
    onConfirm?: any
    onCancel?: any
    content: any
    scrollContent?: boolean
    disabled?: boolean
    style?: any
}

interface State {
    open: boolean
    rerender: boolean
}

export default class Modal extends React.Component<Props, State> {
    isMobile = window.innerHeight > window.innerWidth

    constructor(props: Props) {
        super(props)
        this.state = {
            open: false,
            rerender: false,
        }
    }

    show(rerender?) {
        this.setState({ open: true, rerender }, () => {
            let body = ReactDOM.findDOMNode(this.refs.body) as HTMLElement
            if (body) body.scrollTop = 0
        })
    }

    hide() {
        this.setState({ open: false })
    }

    render() {
        if (this.state.rerender) {
            setTimeout(() => this.setState({ rerender: false }), 0)
        }

        let style = {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(0,0,0,0.45)",
        }
        let modalStyle = {
            maxHeight: this.isMobile ? "90%" : "80%",
            width: this.props.innerWidth || "60%",
            maxWidth: "90%",
            ...this.props.style
        }
        let twoButtons = !!(this.props.confirmText && this.props.cancelText)

        let isAdmin = location.pathname === "/admin"

        return (
            <Box center centerJustified style={style as any}
                className={this.state.open ? "fade-in-500" : "fade-out-500"}>

                <Box vertical style={modalStyle as any} className="panel">
                    <div className={`bg-color-${isAdmin ? "admin" : "primary"} text-center`}
                        style={{ fontSize: "1rem", lineHeight: 3.75, color: "white" }}>
                        {this.props.title}
                    </div>

                    <Item relative ref="body"
                        style={{
                            padding: this.isMobile ? "1rem 1rem 0" : "3rem 3rem 0",
                            overflow: this.props.scrollContent ? "auto" : undefined
                        }}>
                        {this.state.rerender ? null : this.props.content}
                    </Item>

                    <Box center centerJustified flex="1 0" style={{ padding: this.isMobile ? "2rem 1rem" : "2rem 3rem 3rem" }}>
                        {this.props.confirmText ? (
                            <Item flex={twoButtons}>
                                <Button primary={isAdmin ? undefined : true} disabled={this.props.disabled}
                                    fluid size="small" color={isAdmin ? "blue" : undefined}
                                    style={{ minWidth: this.isMobile ? undefined : "10rem" }}
                                    onClick={ async () => {
                                        const isOnConfirmSetBoolean = this.props.onConfirm
                                        const isAsyncOnConfirmSetFnReturn = isOnConfirmSetBoolean && await this.props.onConfirm()
                                        if (!isOnConfirmSetBoolean || !isAsyncOnConfirmSetFnReturn) this.hide()
                                    }}>
                                    {this.props.confirmText === true ? "确定" : this.props.confirmText}
                                </Button>
                            </Item>
                        ) : null}
                        {twoButtons ? util.minWidth("2rem") : null}
                        {this.props.cancelText ? (
                            <Item flex={twoButtons}>
                                <Button basic size="small" fluid style={{ minWidth: this.isMobile ? undefined : "10rem" }}
                                    className="button-box-shadow"
                                    onClick={() => {
                                        if (!this.props.onCancel || !this.props.onCancel()) this.hide()
                                    }}>
                                    {this.props.cancelText === true ? "取消" : this.props.cancelText}
                                </Button>
                            </Item>
                        ) : null}
                    </Box>
                </Box>
            </Box>
        )
    }
}
