import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import { Box } from "./index"
import { API, actions } from "../flux"

interface State {
    partners: any[]
}

export default class Partners extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            partners: [],
        }
    }
    componentDidMount() {
        mockxhr.POST(API.promotion.search("PARTNER", "_", 99, 0), {}, resp => {
            this.setState({ partners: resp.hits })
        })
    }

    partnerLogo(i) {
        let item: any = this.state.partners[i]
        if (!item) return null
        let inner = <img src={item.image} alt={item.title} title={item.title}
            style={{ width: 183, verticalAlign: "bottom", cursor: "pointer" }} />
        return <a target="_blank" href={item.link} onClick={() => actions.clickPromotion(item.id)}>{inner}</a>
    }

    render() {
        return (
            <div className="min-view-center" style={{ paddingTop: 30, paddingBottom: 30, background: "#f9f9f9" }}>
                <div>
                    {util.lineHeight1("合作伙伴", 18, { fontWeight: 700 })}
                    <Box style={{ marginTop: 30 }}>
                        {this.partnerLogo(0)}
                        {util.minWidth(20)}
                        {this.partnerLogo(1)}
                        {util.minWidth(20)}
                        {this.partnerLogo(2)}
                        {util.minWidth(20)}
                        {this.partnerLogo(3)}
                        {util.minWidth(20)}
                        {this.partnerLogo(4)}
                        {util.minWidth(20)}
                        {this.partnerLogo(5)}
                    </Box>
                    {this.state.partners.length > 6 ? util.minHeight(20) : null}
                    <Box>
                        {this.partnerLogo(6)}
                        {util.minWidth(20)}
                        {this.partnerLogo(7)}
                        {util.minWidth(20)}
                        {this.partnerLogo(8)}
                        {util.minWidth(20)}
                        {this.partnerLogo(9)}
                        {util.minWidth(20)}
                        {this.partnerLogo(10)}
                        {util.minWidth(20)}
                        {this.partnerLogo(11)}
                    </Box>
                </div>
            </div>
        )
    }
}
