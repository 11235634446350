import * as React from "react"
import * as _ from 'lodash'
import * as util from '../util'
import { Menu, Dropdown, Input, Icon  } from 'antd'

import "../styles/desktop/Header5.less"

const { Search } = Input

interface Props {
  style?: any
  search?: string
}

interface State {
    iconSrc: any
    leftMenuOpt: any[]
}

export default class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            iconSrc: {
              user: '/assets/icons/user.svg',
            },
            leftMenuOpt: [
              {
                text: '首页',
                tab: 'INDEX',
                href: '/',
                icon: 'home',
              },
              {
                text: '论文',
                tab: 'THESIS',
                href: '/theses',
                icon: 'file-text',
              },
              {
                text: '招聘',
                tab: 'JOB',
                href: '/search',
                icon: 'user-add',
              },
              {
                text: '新闻',
                tab: 'NEWS',
                href: '/information',
                icon: 'profile',
              },
              {
                text: '职业生涯',
                tab: 'CAREERS',
                href: '/careers',
                icon: 'careers',
              },
              {
                text: '论文润色',
                tab: 'POLISH',
                href: `${location.origin}/verify/pagejumper?hmsr=1&hmpl=11&hmcu=&hmkw=&hmci=`,
                icon: 'form',
              },
              {
                text: '会议',
                tab: 'MEETINGS',
                href: '/meetings',
                icon: 'flag',
              },
            ],
        }
    }

    componentDidMount() {
    }

    render() {
        let style = this.props.style || {}
        let { leftMenuOpt } = this.state
        let { search, children } = this.props

        return [
            <div key='0' style={{ height: 70 }} />,
            <div key='1' className="min-view-center main-menu" style={{boxShadow: '0 4px 8px 0 rgba(7, 17, 27, 0.1)'}}>
                <div className="main-menu-center" style={{minWidth: style.minWidth, maxWidth: style.maxWidth}}>
                  <div className="main-menu-left main-menu-block">
                    <Dropdown overlay={
                      <Menu
                        style={{width: '150px'}}
                      >
                        <Search
                          style={{width: '130px', margin: '10px 0 0 10px'}}
                          ref='searcher'
                          onSearch={() => location.href = `/searchall?tab=${search}`}
                          onClick={() => location.href = `/searchall?tab=${search}`}
                        />
                        {_.map(leftMenuOpt, i => {
                          return (
                            <Menu.Item key={i.tab}>
                              <a href={(i.href === '/search' && location.protocol === 'https:') ? 'https://jobs.linkresearcher.com/search' : i.href}>
                                <img src={`/assets/icons/${i.icon}.svg`} width='16' height='16' style={{marginRight: '14px', marginTop: '-4px'}} />
                                {i.text}
                              </a>
                            </Menu.Item>
                          )
                        })}
                      </Menu>
                    }>
                      <div style={{ lineHeight: 1, width: 160 }}>
                        {util.logo({ width: '116px', height: '42px', margin: '14px 10px 14px 0' }, typeof style.logo === "undefined" ? "" : style.logo)}
                        <Icon type="caret-down" style={{color: '#333'}} />
                      </div>
                    </Dropdown>
                  </div>
                  <div className="main-menu-right">
                    { children }
                  </div>
                </div>
            </div>
        ]
    }
}
