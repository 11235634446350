import "./styles/default.less"

import * as mockxhr from "mockxhr"
import * as qr from "qrcode-generator"
import * as qs from "query-string"
import nanoid from "nanoid-base58"

interface Props {
    id: string
    appid: string
    scope: "snsapi_userinfo"
    state: "HR" | "PROFILE"
    done_uri: string
}

export default class WxLogin {
    login_token = nanoid(40)

    constructor(props: Props) {
        const url = `https://open.weixin.qq.com/connect/oauth2/authorize?` + qs.stringify({
            appid: props.appid,
            scope: props.scope,
            redirect_uri: location.origin + "/wechat/login_token?login_token=" + this.login_token,
            state: props.state,
            response_type: "code",
        }) + "#wechat_redirect"

        let div = document.querySelector("#" + props.id)
        if (div) {
            let qrcode = qr(12, "L")
            qrcode.addData(url)
            qrcode.make()
            let svg = qrcode.createSvgTag(4, 0)
            div.innerHTML = svg
        }

        setInterval(() => {
            mockxhr.POST("/login/wechat/code", {
                login_token: this.login_token,
            }, res => {
                if (res.code) {
                    location.href = `${props.done_uri}?code=${res.code}&state=${props.state}`
                }
            }, () => { })
        }, 3000)
    }
}

window["WxLogin"] = WxLogin
