import * as React from "react"

import * as util from "../util"
import countryCodes from "../misc/countryCodes"
import { Box, Item, Dropdown, Input } from "./index"

interface Props {
    countryCode: string
    phoneNumber: string
    placeholder?: string
    onChange: any
    useNativeSelect?: boolean
}

export default class InputMobile extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props)
        this.handleCountryCodeChange = this.handleCountryCodeChange.bind(this)
    }

    handleCountryCodeChange(countryCode) {
        this.props.onChange(countryCode, this.props.phoneNumber)
    }

    render() {
        return (
            <Item relative className="kyq-phone">
                <Box center
                    style={{
                        position: "absolute", top: 0, left: 0,
                        height: "100%", minWidth: "6rem",
                        padding: "0 1em", zIndex: 1,
                    }}>
                    <Item flex>
                        {this.props.useNativeSelect ?
                            <select value={this.props.countryCode}
                                style={{ background: "transparent", border: "none" }}>
                                {countryCodes.map(c =>
                                    <option value={c.value}>{c.value}</option>
                                )}
                            </select>
                            :
                            <CountryCodeSelector
                                countryCode={this.props.countryCode}
                                onChange={this.handleCountryCodeChange} />
                        }
                    </Item>
                </Box>
                <Input fluid placeholder={this.props.placeholder || "您的手机"} value={this.props.phoneNumber}
                    onKeyDown={util.inputOnlyNumber}
                    onChange={(e, { value }) => {
                        if (/^\d{0,11}$/.test(value)) this.props.onChange(this.props.countryCode, value)
                    }} />
            </Item>
        )
    }
}

interface CountryCodeSelectorProps {
    countryCode: string
    onChange: any
}

class CountryCodeSelector extends React.Component<CountryCodeSelectorProps, {}> {
    // 减少 Dropdown options 的渲染次数
    shouldComponentUpdate(nextProps) {
        if (nextProps.countryCode !== this.props.countryCode) return true
        if (nextProps.onChange !== this.props.onChange) return true
        return false
    }

    render() {
        return (
            <Dropdown scrolling fluid
                value={this.props.countryCode} text={this.props.countryCode}
                onChange={(e, { value }) => this.props.onChange(value)}
                options={countryCodes.map(cc => ({
                    key: cc.key,
                    value: cc.value,
                    text: (
                        <Box center>
                            {cc.text}
                            <Item flex style={{ minWidth: "1em" }} />
                            {cc.value}
                        </Box>
                    )
                }))} />
        )
    }
}
