import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import { Input, Box } from "./index"
import { actions, API } from "../flux"

interface Props {
    placeholder: string
    action?: string
    tabs?: string[]
    buzzword?: any[]
    onClick?: any
    onSwitchTab?: any
    defaultValue?: string
    defaultTab?: number
    bgClass?: string
}

interface State {
    tab?: number
    keyword: string
    buzzwords?: string[]
}

export default class Search extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            keyword: this.props.defaultValue || "",
            tab: this.props.defaultTab || 0,
        }
    }

    tab(i: number, title: string) {
        let active = this.state.tab === i
        let className = "clickable"
        let style: any = {
            fontSize: ".85rem",
            height: "1.875rem",
            padding: "0 .75em",
        }
        if (active) {
            style.color = "white"
            className += " bg-color-primary"
        } else {
            className += " text-primary"
        }
        return (
            <Box centerJustified center style={style} className={className}
                onClick={() => this.setState({ tab: i }, () => this.props.onSwitchTab(i))}>
                {title}
            </Box>
        )
    }

    handleSearch() {
        this.setState({ keyword: this.state.keyword.trim() }, () => {
            this.props.onClick(this.state.keyword)
        })
    }

    render() {
        return (
            <Box centerJustified className={this.props.bgClass}>
                <div>
                    {util.minHeight("1.875rem")}
                    <Box>
                        {(this.props.tabs || []).map((t, i) => this.tab(i, t))}
                    </Box>
                    <div style={{ width: "48.75rem" }}>
                        <Input fluid className="kyq-search" style={{ height: "2.375rem" }}
                            value={this.state.keyword} action={(
                                <button className="ui button" onClick={() => this.handleSearch()}>
                                    <div style={{ width: "8.25rem", lineHeight: ".9rem" }}>
                                        {this.props.action || "搜    索"}
                                    </div>
                                </button>
                            )}
                            placeholder={this.props.placeholder}
                            onKeyDown={e => {
                                if (e.keyCode === 13) this.handleSearch()
                            }}
                            onChange={(e, { value }) => this.setState({ keyword: value })} />

                        {this.props.buzzword ? (
                            <Box className="line-height text-minor" style={{ fontSize: ".75rem", marginTop: ".625rem" }}>
                                热门搜索：
                                {this.props.buzzword.map(word =>
                                    <div className="clickable" style={{ margin: "0 1em" }}>
                                        {word}
                                    </div>
                                )}
                            </Box>
                        ) : null}
                    </div>
                    {util.minHeight("1.25rem")}
                </div>
            </Box>
        )
    }
}

export class Search2 extends React.Component<Props, State> {
    promotionStore = {}

    constructor(props: Props) {
        super(props)
        this.state = {
            keyword: this.props.defaultValue || "",
            buzzwords: [],
        }
    }

    componentDidMount() {
        mockxhr.POST(API.promotion.search("SEARCH_BUZZWORD", "_", 99, 0), {}, resp => {
            this.setState({
                buzzwords: resp.hits.map(x => {
                    this.promotionStore[x.id] = x
                    return x.id
                })
            })
        })
    }

    render() {
        let handleSearch = () => this.props.onClick(this.state.keyword)
        return [
            <Box centerJustified>
                <input className="border-color-v2" placeholder={this.props.placeholder} value={this.state.keyword}
                    onChange={e => this.setState({ keyword: e.target.value })}
                    onKeyDown={e => e.keyCode === 13 && handleSearch()}
                    style={{
                        width: 660, height: 40, borderRadius: 0, background: "white", padding: "0 20px",
                        fontSize: 14, borderRight: "none",
                        borderTopLeftRadius: 3, borderBottomLeftRadius: 3,
                    }} />
                <button className="text-primary" onClick={handleSearch}
                    style={{
                        width: 134, height: 40, borderRadius: 0, background: "white",
                        border: "1px solid #290382", borderLeft: "none",
                        borderTopRightRadius: 3, borderBottomRightRadius: 3,
                    }}>
                    <Box center justified style={{ fontSize: 16, whiteSpace: "nowrap" }}>
                        <div>开</div>
                        <div>始</div>
                        <div>搜</div>
                        <div>索</div>
                        <img src={util.iconURL("search.png")} style={{
                            height: 24, lineHeight: 1,
                        }} />
                    </Box>
                </button>
            </Box>
            ,
            util.minHeight(10),
            <Box centerJustified>
                <Box style={{ width: 794, lineHeight: 1, fontSize: 14, color: "#999" }}>
                    <span className="nowrap" style={{ marginRight: 40, marginTop: 10 }}>
                        热门搜索：
                    </span>
                    <div>
                        {(this.state.buzzwords || []).map(w => {
                            let title = this.promotionStore[w].title
                            let onClick = () => actions.clickPromotion(w, () =>
                                this.setState({ keyword: title }, () => handleSearch())
                            )
                            return (
                                <span className="text-hover-v2 clickable"
                                    style={{ marginRight: 30, display: "inline-block", marginTop: 10 }} onClick={onClick}>
                                    {title}
                                </span>
                            )
                        })}
                    </div>
                </Box>
            </Box>
        ]
    }
}
