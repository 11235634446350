import * as React from "react"
import * as ReactDOM from "react-dom"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import Modal from "./Modal"
import { Box, Icon } from "./index"
import { actions, db, db$, API } from "../flux"

export function createJobModal(that) {
    return (
        <Modal ref="createJob" title="提示" innerWidth="30rem" confirmText="发布职位" cancelText="取消"
            onConfirm={that.state.createJobHandler}
            content={
                <div className="text-gray text-center" style={{ lineHeight: 2 }}>
                    您今天还能发布
                    <span className="ml05 mr05" style={{ color: "#e63e0f", fontWeight: 700 }}>
                        {Math.max(0, 4 - that.state.jobsCreatedToday.length)}
                    </span>
                    条新职位！
                </div>
            } />
    )
}

export function forbidNewJobModal() {
    return (
        <Modal ref="forbidNewJob" title="提示" innerWidth="30rem" confirmText="关闭"
            content={
                <div className="text-gray text-center" style={{ lineHeight: 2 }}>
                    您今天已经发布了4条新职位，明天再来吧！
                </div>
            } />
    )
}

export function handleCreateJob(that, handler) {
    mockxhr.GET(`/api/hr/filters/CREATE_JOB/${util.formatTs(Date.now(), "%Y-%m-%d")}`, {}, resp => {
        if (resp.length < 4) {
            that.setState({
                jobsCreatedToday: resp,
                createJobHandler: handler,
            }, () => that.refs.createJob.show())
        } else {
            that.refs.forbidNewJob.show()
        }
    })
}

interface Props {
}

interface State {
    dropdown: boolean
    name: string
    countUnreadMessages: number
    createJobHandler: any
    jobsCreatedToday: any[]
}

export default class HeaderHR extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            dropdown: false,
            name: "",
            countUnreadMessages: 0,
            createJobHandler: null,
            jobsCreatedToday: [],
        }
    }

    componentDidMount() {
        window.addEventListener("click", e => {
            if (!this.refs || !this.refs.menu || !e.target) return
            if (!(this.refs.menu as any).contains(e.target)) {
                this.setState({ dropdown: false })
            }
        })

        db.subscribe(() => {
            let hr = db$.login.findOne({ _id: "hr" })
            if (hr) {
                this.setState({ name: hr.name }, () => {
                    mockxhr.GET(API.hr.unreadMessages(), {}, resp => {
                        this.setState({ countUnreadMessages: resp.length })
                    }, () => { })
                })
            }
        }, this)

        actions.login.get()
        actions.api.hr.get()
        actions.api.hr.employer()
    }

    tab(text: string, url: string, handleClick?) {
        let selected = location.pathname === url
        return (
            <Box vertical justified selfStretch style={{ marginLeft: "3.75rem" }}>
                {util.minHeight("2px")}
                <div className={`clickable ${selected ? "text-primary" : ""}`} style={{ fontSize: ".95rem" }}
                    onClick={handleClick || (() => location.href = url)}>
                    {text}
                </div>
                <div className={selected ? "bg-color-primary" : ""}>
                    {util.minHeight("2px")}
                </div>
            </Box>
        )
    }

    menu(text: string, onClick?: any) {
        return (
            <div className="hover-primary clickable" onClick={onClick}
                style={{ fontSize: ".85rem", padding: "1.25rem 1.5rem", whiteSpace: "nowrap", minWidth: "11rem" }}>
                {text}
            </div>
        )
    }

    scrollIntoView() {
        ReactDOM.findDOMNode(this.refs.root).scrollIntoView()
    }

    render() {
        return (
            <Box ref="root" centerJustified className="bg-color-2" style={{ minHeight: "4.75rem" }}>
                <Box justified className="fix-view">
                    <Box center>
                        {util.logo({ height: "2.4rem", verticalAlign: "bottom" })}
                        <div className="text-primary"
                            style={{ fontSize: ".85rem", margin: "0.8rem -1.2rem 0px 1rem" }}>
                            雇主版
                        </div>
                        {this.tab("职位管理", "/hr/jobs")}
                        {this.tab("发布职位", "", () => handleCreateJob(this, () => location.href = "/hr/job/edit"))}
                        {this.tab("浏览简历", "/hr/resumes")}
                    </Box>
                    <Box center style={{ fontSize: ".85rem", lineHeight: 1 }}>
                        <div className={"clickable " + (location.pathname === "/hr/messages" ? "text-primary" : "")}
                            style={{ padding: "0 1.25rem", borderRight: "1px solid" }}>
                            <span style={{ position: "relative" }}
                                onClick={() => location.href = this.state.countUnreadMessages ? "/hr/messages?tab=1&type=UNREAD" : "/hr/messages"}>
                                消息{this.state.countUnreadMessages ? `(${this.state.countUnreadMessages})` : ""}
                            </span>
                        </div>
                        <div className={"clickable " + (location.pathname === "/hr/employer/edit" ? "text-primary" : "")}
                            style={{ padding: "0 1.25rem", borderRight: "1px solid" }}
                            onClick={() => location.href = "/hr/employer/edit"}>
                            单位设置
                        </div>
                        <Box relative center selfStretch className="clickable"
                            onClick={() => {
                                if (this.state.dropdown) {
                                    this.setState({ dropdown: false })
                                } else {
                                    setTimeout(() => this.setState({ dropdown: true }), 100)
                                }
                            }}
                            style={{ paddingLeft: "1.25rem" }}>
                            {this.state.name}
                            <div className="text-center" style={{ width: "1.5rem" }}>
                                <Icon name="caret down" />
                            </div>

                            {this.state.dropdown ? (
                                <div className="panel" ref="menu"
                                    style={{
                                        position: "absolute", top: "4.75rem",
                                        overflow: "hidden", right: 0,
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        zIndex: 2,
                                    }}>
                                    {this.menu("帐号设置", () => location.href = "/hr/account")}
                                    {this.menu("退出", () => location.href = "/logout?redirect=/login/hr")}
                                </div>
                            ) : null}
                        </Box>
                    </Box>
                </Box>

                {createJobModal(this)}
                {forbidNewJobModal()}
            </Box>
        )
    }
}
