
export function addCacheObjItem(key: string, id: string, val: string) {
    let cacheObj = {}
    const cacheStr = _getCacheObjValue(key)
    if (cacheStr) {
        cacheObj = JSON.parse(cacheStr)
    }
    cacheObj[id] = val
    localStorage.setItem(key, JSON.stringify(cacheObj))
}

function _getCacheObjValue(key: string) {
    return localStorage.getItem(key)
}

export function getCacheObjItem(key: string, id: string) {
    const cacheStr = _getCacheObjValue(key)
    if (!cacheStr) return ''
    const cacheObj = JSON.parse(cacheStr);
    return id in cacheObj ? cacheObj[id] : ''
}

export function removeCacheObjItem(key: string) {
    localStorage.removeItem(key)
}
