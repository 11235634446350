import * as React from "react"
import { Button } from "./index"

interface Props {
    onClick: any
    size?: string
    style?: any
    disabled?: boolean
}

interface State {
    lastTime: number
}

export default class SMSButton extends React.Component<Props, State> {
    timer: any
    localStorageKey = "verify_code:" + location.pathname
    lastClick = 0

    constructor(props: Props) {
        super(props)
        this.state = {
            lastTime: parseInt(window.localStorage && localStorage.getItem(this.localStorageKey) as string) || 0,
        }
    }

    componentDidMount() {
        this.timer = setInterval(() => this.setState({}), 100)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        let that = this
        let wait = Math.ceil((this.state.lastTime + 60000 - Date.now()) / 1000)
        let text = wait > 0 ? `重新发送 (${wait}秒)` : "发送验证码"
        let props: any = {
            primary: true,
            size: this.props.size || "small",
            style: { minWidth: "10em", ...this.props.style },
            disabled: this.props.disabled || wait > 0,
            onClick() {
                if (Date.now() - that.lastClick < 1000) return
                that.lastClick = Date.now()
                that.props.onClick(() => {
                    let ts = Date.now()
                    try {
                        localStorage.setItem(that.localStorageKey, ts.toString())
                    } catch (ex) {
                    }
                    that.setState({ lastTime: ts })
                })
            }
        }
        return <Button {...props}>{text}</Button>
    }
}
