import * as React from "react"
import * as reduxdb from "reduxdb"

import { Label } from "./index"

export default class FormChecker {
    store = reduxdb.use(Math.random().toString(16))
    private checking = false

    constructor() {
        this.store.createCollection("field", { index: "id" })
    }

    getState(id) {
        return this.store.getCollection("field").findOne({ id })
    }

    register(id) {
        this.store.getCollection("field").save({
            id,
            checked: true,
            message: null,
        })
    }

    update(item) {
        this.store.getCollection("field").save(item)
    }

    check(callback, fail?) {
        if (this.checking) return
        let collection = this.store.getCollection("field")
        let items = collection.find({})
        items.forEach(item => item.checked = false)
        items.forEach(item => collection.save({
            id: item.id,
            checked: false,
            message: null,
        }))

        let handler = setInterval(() => {
            if (collection.findOne({ checked: false }) === null) {
                clearInterval(handler)
                this.checking = false

                let ok = true
                collection.find({}).forEach(item => {
                    if (item.message) ok = false
                })
                ok ? callback() : fail()
            }
        }, 100)
    }
}

interface Props {
    checker: FormChecker
    id: string
    getTip: any
    arrowDirection?: "left" | "right" | "below" | "above" | undefined
    style?: any
}

export class ErrorTooltip extends React.Component<Props, {}> {
    componentDidMount() {
        this.props.checker.store.subscribe(() => this.setState({}), this)
        this.props.checker.register(this.props.id)
    }

    render() {
        let state = this.props.checker.getState(this.props.id)
        if (!this.state) return null

        if (!state.checked) {
            this.props.checker.update({
                id: this.props.id,
                checked: true,
                message: this.props.getTip(),
            })
        }
        

        const arrowDirection = this.props.arrowDirection || "right"

        return state.checked && state.message ? (
            <div className="form-error" style={{ position: "absolute", marginTop: "-.5em", ...this.props.style }}>
                <div style={{ position: "relative" }}>
                    <div style={{ position: "absolute", right: "100%", marginRight: "-1em", whiteSpace: "nowrap" }}>
                        <Label id="errorTip" basic color="red" pointing={arrowDirection}>
                            {state.message}
                        </Label>
                    </div>
                </div>
            </div>
        ) : null
    }
}
