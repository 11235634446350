import * as React from "react"
import * as xss from "xss"
import { set } from "object-path"

import { actions } from "../flux"

export function htmlToPlain(html, mergeWhiteSpace = false) {
    if (!html) return ""
    let escape = new xss.FilterXSS({
        whiteList: [],
        stripIgnoreTag: true,
        stripIgnoreTagBody: ["script", "style"],
    } as any).process(html)
    let span = document.createElement("span")
    span.innerHTML = escape
    return mergeWhiteSpace ? (span.innerText || "").replace(/\s+/g, " ").trim() : (span.innerText || "")
}

export function beautifyHTML(html) {
    let span = document.createElement("span")
    span.innerHTML = html
    // 全文无文字
    if (!span.innerText.trim()) return html
    let walk = document.createTreeWalker(span, NodeFilter.SHOW_TEXT, null as any, false);
    let node: any
    while (node = walk.nextNode()) {
        let newNode = document.createElement("span")
        if (node.nodeValue) {
            newNode.innerHTML = node.nodeValue.trim().replace(/\n/g, "<br/>")
            node.parentNode.replaceChild(newNode, node)
        }
    }
    // 删除开头的空行
    while (true) {
        let firstChild = span.firstChild as HTMLElement
        if (firstChild && typeof firstChild.innerText === "string" && firstChild.innerText.trim() === "") {
            span.removeChild(firstChild)
        } else {
            break
        }
    }
    // 删除最后的空行
    while (true) {
        let lastChild = span.lastChild as HTMLElement
        if (lastChild && typeof lastChild.innerText === "string" && lastChild.innerText.trim() === "") {
            span.removeChild(lastChild)
        } else {
            break
        }
    }
    return span.innerHTML
}

export function filterXSS(html, beautify = false) {
    let whiteList = xss.getDefaultWhiteList()
    whiteList["audio"] = [...(whiteList["audio"] || []), "controlslist"]
    whiteList["div"] = [...(whiteList["div"] || []), "class"]
    whiteList["figure"] = ["style"]
    whiteList["input"] = ["type", "name", "value"]
    whiteList["font"] = ["color", "size", "style"]
    whiteList["i"] = [...(whiteList["i"] || []), "class"]
    whiteList["iframe"] = ["width", "height", "src", "style",  "frameborder", "class", "title", "marginheight", "marginwidth", "allowtransparency", "allowfullscreen", "scrolling"]
    whiteList["img"] = (whiteList["img"] || []).filter(attr => attr !== "height")
    whiteList["p"] = [...(whiteList["p"] || []), "class"]
    whiteList["section"] = (whiteList["section"] || []).filter(att => att !== "style")
    whiteList["table"] = (whiteList["table"] || []).filter(att => att !== "align")
    new Array("h1", "h2", "h3", "h4", "h5", "h6", "h7").forEach(k => delete whiteList[k])
    Object.keys(whiteList).forEach(prop => whiteList[prop].push("style"))
    return new xss.FilterXSS({
        allowCommentTag: false,
        stripIgnoreTag: true,
        stripIgnoreTagBody: ["script", "style"],
        whiteList,
        css: {
            whiteList: {
                "color": true,
                "font-size": true,
                "max-width": true,
                "min-height": true,
                "text-align": true,
                "width": true,
                "height": true,
                "overflow": true,
                "display": true,
                "margin": true,
                "padding": true,
                "border": true,
            }
        },
    } as any).process(beautify ? beautifyHTML(html) : html)
}

export function safeInnerHTML(html) {
    return <span dangerouslySetInnerHTML={{ __html: filterXSS(html) }} />
}

export function createWangEditor(that, refKey, stateKey, menus?) {
    const isAdmin = actions.local.session() && /^\/admin\/?/.test(location.pathname)
    const wangEditor = (window as any).wangEditor
    const editor = new wangEditor(that.refs[refKey])
    editor.customConfig.menus = menus || [
        isAdmin ? 'textFormat' : undefined,
        isAdmin ? "foreColor" : undefined,
        isAdmin ? "fontSize" : undefined,
        "bold",
        'underline',
        "italic",
        "superscript",
        "subscript",
        isAdmin ? "quote" : undefined,
        isAdmin ? "collapse" : undefined,
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "imgCenter",
        isAdmin ? "dataViz" : undefined,
        "removeFormat",
        "hr",
        "link",
        "image",
        isAdmin ? "audio" : undefined,
        isAdmin ? "video" : undefined,
        "table",
    ].filter(m => !!m)
    editor.customConfig.zIndex = 0
    editor.customConfig.uploadImgServer = "/api/upload/wangeditor"
    editor.customConfig.accessOnlineImg = "/api/material/image/search"
    editor.customConfig.isAdmin = isAdmin
    editor.customConfig.uploadImgMaxLength = 20
    editor.customConfig.uploadFileName = "file"
    editor.customConfig.uploadImgParams = {
        _csrf: (document.querySelector("meta[name='_csrf']") as any)["content"]
    }
    editor.customConfig.onchange = html => {
        set(that.state, stateKey, filterXSS(html))
        that.setState({})
    }
    editor.create()
    return editor
}

window.addEventListener("click", e => {
    if (e.target) {
        let target = e.target as HTMLElement
        if (target.className === "w-e-collapse-title-up" || target.className === "w-e-collapse-title-down") {
            if (target.className.indexOf("-up") > 0) {
                target.className = target.className.replace("-up", "-down")
            } else if (target.className.indexOf("-down") > 0) {
                target.className = target.className.replace("-down", "-up")
            }
            let icon = target.querySelector(".fa.w-e-collapse") as HTMLElement
            if (icon) target = icon
        }
        let parentNode = target.parentNode as HTMLElement
        if (target.className === "fa fa-angle-double-down w-e-collapse") {
            target.className = "fa fa-angle-double-up w-e-collapse"
            if (parentNode.className === "w-e-collapse-title-down") {
                parentNode.className = "w-e-collapse-title-up"
            }
        } else if (target.className === "fa fa-angle-double-up w-e-collapse") {
            target.className = "fa fa-angle-double-down w-e-collapse"
            if (parentNode.className === "w-e-collapse-title-up") {
                parentNode.className = "w-e-collapse-title-down"
            }
        }
    }
})
