import * as React from "react"

import { Box, Item } from "./index"

interface Props {
    titles: string[]
    active: number
    style?: React.CSSProperties
}

interface State {
}

export default class Steps extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
    }

    step(i: number, title: string) {
        let style = {
            width: "2em",
            height: "2em",
            borderRadius: "99em",
            color: "white",
            fontSize: "1em",
        }
        return (
            <Box center centerJustified relative style={style}
                className={this.props.active >= i ? "bg-color-primary" : "bg-color-2"}>
                <div style={{ lineHeight: 1 }}>
                    {i + 1}
                </div>
                <div
                    className={this.props.active >= i ? "text-primary" : "text-minor"}
                    style={{
                        fontSize: "0.9em",
                        textAlign: "center",
                        position: "absolute",
                        whiteSpace: "nowrap",
                        bottom: "-1.8em",
                        lineHeight: 1,
                    }}>
                    {title}
                </div>
            </Box>
        )
    }

    render() {
        let content = [];
        this.props.titles.forEach((t, i) => {
            content.push(this.step(i, t) as never)
            if (i !== this.props.titles.length - 1) {
                content.push((
                    <Item flex style={{ height: 2 }}
                        className={this.props.active > i ? "bg-color-primary" : "bg-color-2"} />
                ) as never)
            }
        })

        return (
            <div style={{ marginBottom: 0.9 * 1.8 + "em" }}>
                <Box center style={this.props.style}>
                    {content}
                </Box>
            </div>
        )
    }
}
