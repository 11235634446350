let all = {
    "综合": {
        WEIXIN: {
            "_": "",
            "gh_ff93f845912e":"数据实战派",
            "BioGossip":"BioArt",
            "cpsjournals":"中国物理学会期刊网",
            "casihep":"中科院高能所",
            "paperweekly":"Paperweekly",
            "ITP-CAS":"中科院理论物理所",
            "principia1687":"原理",
            "zhishexueshuquan":"知社学术圈",
            "wileychina":"Wiley威立",
            "AIFrontier":"人工智能前沿讲习",
            "deeptechchina":"DeepTech深科技",
            "WuXiAppTecChina":"药明康德",
            "CellPressChina":"CellPress细胞科学",
            "Nature-Research":"Nature自然科研",
            "Global_Academia":"学术经纬",
            "gh_57a73922cc8b":"柔性电子与智能技术",
            "keyanquan":"科研圈",
            "biodiscover":"生物探索",
            "BMC2000":"BMC期刊",
            "steamforkids":"把科学带回家",
            "QbitAI":"量子位",
            "swarma_org":"集智俱乐部",
            "geekheal_com":"奇点网",
            "NatlSciRev":"国家科学评论",
            "fitee_cae":"信息与电子工程前沿FITEE",
            "Science-AAAS":"ScienceAAAS",
            "BNU-PHYS":"京师物理",
            "Science-Magazine":"科学杂志",
            "SciTouTiao":"学术头条",
            "Microeco2016":"微生太",
            "gh_5f30dbf97617":"求是风采",
            "huanqiukexue":"环球科学",
            "NEJM-YiXueQianYan":"NEJM医学前沿",
            "materialsviews ":"MaterialsViews",
            "cas-iop":"中科院物理所",
            "almosthuman2014":"机器之心",

        },
        WEBSITE: {
            "_": "",
        },
    },
    "招聘": {
        WEIXIN: {
            "_": "",
            "gaoxiao_survey":"硕博就业出国",
            "yxqnxz":"国际青年学者",
            "sxsrlzysc":"山西省人力资源市场",
            "yunnanrenshiKS":"云南人事人才考试网",
            "cnxyzp":"应届生校园招聘网",
            "gzqzzp-vip":"广州求职招聘中心",
            "kexuerencai":"科学人才",
            "met-info":"一展云图",
            "HKCampusRecruitment":"香港校招",
            "gh_e33d61d2d680":"高层次人才圈",
            "boshinet":"博士人才网",

        },
        WEBSITE: {
            "_": "",
            "ohr.sustc.edu.cn": "南方科技大学",
            "hr.zju.edu.cn": "浙江大学人事处",
            "muchong.com": "小木虫",
        },
    },
}

export const MP_ACCOUNTS = {
    ...all["综合"].WEIXIN,
    ...all["招聘"].WEIXIN,
    ...all["招聘"].WEBSITE,
}

export default all
