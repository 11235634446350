import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import { API, actions } from "../flux"

interface State {
    ads: any
    jobs: any[]
    employers: any
}

interface Props {
}

export default class AdRight extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            ads: {},
            jobs: [],
            employers: {},
        }
    }

    componentDidMount() {
        mockxhr.POST(API.promotion.search("AD", "_", 99, 0), {}, resp => {
            (resp.hits || []).forEach(x => {
                this.state.ads[x.type1] = x
            })
            this.setState({})
        })

        actions.api.job.search({
            type: "OVERALL",
            from: 0,
            size: 10,
            query: "",
            sort: [],
            filters: {},
        }, resp => {
            this.setState({ jobs: resp.hits })
        })
    }

    fetchEmployer(id) {
        let employer = this.state.employers[id]
        if (!employer) {
            employer = this.state.employers[id] = {}
            mockxhr.GET(API.employer.one(id), {}, resp => {
                this.state.employers[id] = resp
                this.setState({})
            })
            return {}
        } else {
            return employer
        }
    }

    render() {
        return (
            <div style={{ width: "18.75rem" }}>
                <div className="panel2 box-shadow"
                    style={{ borderRadius: 4, padding: "1.875rem", position: "relative" }}>
                    <div className="bg-color-primary"
                        style={{ height: 2, position: "absolute", top: 0, left: 0, width: "100%" }} />
                    <div style={{ fontWeight: 700 }}>
                        推荐职位
                    </div>
                    <div className="bg-color-2" style={{ height: 1, marginTop: "1.25rem" }} />
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => {
                        let item = this.state.jobs[i]
                        return item ? (
                            <div key={i} style={{ marginTop: "1.25rem", cursor: "pointer" }} onClick={() => location.href = `/jobs/${item.id}`}>
                                <b style={{ lineHeight: 1.4 }}>{item.name}</b>
                                <div className="text-minor" style={{ fontSize: ".85rem", lineHeight: 1.6, margin: ".4em 0" }}>
                                    {util.fullEmployerName(this.fetchEmployer(item.employerId).name, item.department)}
                                </div>
                                <div className="pre-wrap text-minor" style={{ fontSize: ".75rem" }}>
                                    <span className="text-primary">
                                        {util.salaryText(item)}
                                    </span>
                                    {`  /  ${item.city}  /  ${item.minDegree}  /  ${item.form}`}
                                </div>
                            </div>
                        ) : null
                    })}
                </div>
                {[1, 2, 3].map(i => {
                    let item = this.state.ads[`OTHER_${i}`]
                    return item ? (
                        <img src={item.image} onClick={() => actions.clickPromotion(item.id, () => location.href = item.link)}
                            style={{ width: "100%", marginTop: "1.25rem" }} />
                    ) : null
                })}
            </div>
        )
    }
}
