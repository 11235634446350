export default {
    admin: {
        users: {
            status: (id, status) => `/api/admin/users/${id}/status/${status}`,
            password: (id) => `/api/admin/users/${id}/password`,
        }
    },
    account: {
        phone: {
            sendResetSMS: () => `/api/account/phone/reset/sms`,
            verifyResetSMS: code => `/api/account/phone/reset/sms/verify?code=${code}`,
        }
    },
    profile: {
        delete: id => `/api/profile/${id}`,
        tags: id => `/api/profile/${id}/tags`,
        status: (id, status) => `/api/profile/${id}/status/${status}`,
        blockedEmployers: () => `/api/profile/employer/block`,
        filters: (type, target) => `/api/profile/filters/${type}/${target}`,
        messageThread: (id) => `/api/profile/messages/threads/${id}`,
        messageRead: (id) => `/api/profile/messages/threads/${id}/read`,
        messageTrash: () => `/api/profile/messages/trash`,
        unreadMessages: () => `/api/profile/messages/unread`,
    },
    hr: {
        messageThread: (id) => `/api/hr/messages/threads/${id}`,
        messageRead: (id) => `/api/hr/messages/threads/${id}/read`,
        messageTrash: () => `/api/hr/messages/trash`,
        unreadMessages: () => `/api/hr/messages/unread`,
        filters: {
            job: () => `/api/hr/filters/job`,
        }
    },
    resume: {
        delete: id => `/api/resumes/${id}`,
        comment: (id, type) => `/api/resumes/${id}/comments?type=` + type
    },
    job: {
        stats: id => `/api/jobs/${id}/stats`,
        star: id => `/api/jobs/${id}/star`,
    },
    promotion: {
        list: () => `/api/promotions`,
        one: id => `/api/promotions/${id}`,
        status: (id, status) => `/api/promotions/${id}/status/${status}`,
        searchFilters: (type1, type2) => `/api/promotions/searchFilters/${type1}/${type2}`,
        search: (kind, type, size, from) => `/api/promotions/search/${kind}?type=${type}&from=${from}&size=${size}`,
    },
    employer: {
        one: id => `/api/employers/${id}`,
    },
    subject: {
        common: () => `/api/subject/do`,
        update: () => `/api/subject/do?type=UPDATE`,
    },
    display: {
        common: () => `/api/subject/do`,
        update: () => `/api/subject/do?type=UPDATE`,
    },
    source: {
        common: () => `/api/source/do`,
        update: () => `/api/source/save`,
    },
    column: {
        common: () => `/api/column/do`,
        update: () => `/api/column/save`,
    },
    periodical: {
        common: () => `/api/periodical/do`,
        update: () => `/api/periodical/do?type=UPDATE&from=0&size=10`,
    },
}
