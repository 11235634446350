export const issues = {
    "201810": {
        careers: [
            "df561410-34e0-4bb9-b38e-c9a0bacd566f",
            "0b805f19-c6d3-490e-8150-507d957e745f",
            "cf80e3c1-a788-4525-8898-6878cab0f99a",
            "d971c9c1-6b6e-4dd0-8649-36d368eb6ec3",
        ],
        jobs: [
            "d3dc81d9-e2e5-4c09-af6f-81af28392ef6",
            "e9536bf3-cac5-45e4-9637-33c6f6ee8ccf",
            "429b5eb6-9cf1-4561-84ae-c1b29366144b",
            "6972e081-c15f-4576-9d15-6190a71e7540",
            "338a83f9-3ad8-4a2b-9bcf-3e6f1e08ba8b",
            "6378cf21-f7c0-48e8-b47c-54b0518b9a19",
            "40dcba54-dab9-474d-820c-bd3e4287023f",
        ],
    },
    "201811": {
        jobs: [
            "7a809753-e23f-49c5-b034-ae5515e98eed",
            "c7f452bd-0898-45fa-aa60-ba5b6dd9bfc4",
            "5b5f2f126b067c7e555b16e8",
            "6972e081-c15f-4576-9d15-6190a71e7540",
            "0482dfdb-7191-4ab2-9cad-17da8699475c",
        ],
    },
}
