import * as HttpStatus from "http-status-codes"
import * as mockxhr from "mockxhr"
import * as queryString from "query-string"
import * as reduxdb from "reduxdb"

import api from "./api"
import csrfToken from "../util/csrf"
import toast from "../util/toast"
import { message } from 'antd'

export const db = reduxdb.use("keyanquan")
export const db$ = db as any as reduxdb.DB$
db.createCollection("login")
db.createCollection("event")

mockxhr.setHeaders({ "X-XSRF-TOKEN": csrfToken() })

mockxhr.ajaxSuccess((res, done, fail, xhr) => {
    if (typeof done === "function") {
        if (res) {
            done(JSON.parse(JSON.stringify(res).replace(
                /\/\/ovg0npt4p\.bkt\.clouddn\.com\//g,
                "//cdn.linkresearcher.com/"
            )))
        } else {
            done(res, xhr)
        }
    }
})

mockxhr.ajaxFailure((xhr: any, fail, done) => {
    if (fail) {
        fail(xhr)
    } else if (xhr.status === HttpStatus.UNAUTHORIZED) {
        location.href = "/login?redirect=" + encodeURIComponent(location.pathname)
    } else if (xhr.status === HttpStatus.FORBIDDEN) {
        if (actions.local.session()) {
            try {
                toast.error("不允许该操作")
            } catch (e) {
                console.warn(xhr)
            }
        } else {
            location.href = "/login?redirect=" + encodeURIComponent(location.pathname)
        }
    } else if (xhr.status === HttpStatus.TOO_MANY_REQUESTS) {
        toast.error("请求太过频繁")
    } else if (xhr.status === HttpStatus.CONFLICT) {
        toast.error("已存在相同的信息")
    } else if (xhr.status === 610) {
        toast.error("已存在相同信息，请更换招聘职位名称/所属二级部门/研究方向（职位所属行业）后重试")
    }else {
        try {
            // toast.error(xhr.statusText)
            console.warn("该报错已被隐藏，来源mockxhr.ajaxFailure")
        } catch (e) {
            console.warn(xhr)
        }
    }
})

export const API = api

interface SearchRequest {
    type: string
    request: object
    from: number
    size: number
    df?: string
}

export const actions = {
    local: {
        session() {
            return db$.login.findOne({ _id: "session" })
        },
        profile() {
            return db$.login.findOne({ _id: "profile" })
        },
        hr() {
            return db$.login.findOne({ _id: "hr" })
        },
    },

    clickPromotion(id, done?, clickPos?) {
        if (!id) return
        mockxhr.POST(
            `/api/promotions/${id}/click?${clickPos ? ('clickPos=' + clickPos) : ''}`, {},
            typeof done === "string" ? (() => location.href = done) : done
        )
    },

    join: {
        sms({ countryCode, phoneNumber, role }, done?, fail?) {
            if (phoneNumber.length < 5) {
                toast.error("手机号码不正确")
                return
            }
            mockxhr.POST("/join/sms/register?" + queryString.stringify({
                countryCode, phoneNumber, role
            }), {}, done, fail)
        },
        hr(params, done?, fail?) {
            const {
                countryCode,
                phoneNumber,
                smsCode,
                password,
                form,
            } = params
            form.password = password
            mockxhr.POST("/join/hr?" + queryString.stringify({
                countryCode, phoneNumber, smsCode,
            }), form, done, fail)
        },
        profile(params, done?, fail?) {
            const {
                countryCode,
                phoneNumber,
                smsCode,
                password,
                form,
            } = params
            form.password = password
            mockxhr.POST("/join?" + queryString.stringify({
                countryCode, phoneNumber, smsCode,
            }), form, done, fail)
        }
    },

    login: {
        get(done?, fail?) {
            mockxhr.GET("/login/session", {}, resp => {
                db$.login.save({ _id: "session", ...resp })
                if (done) done(resp)
            }, xhr => {
                if (fail) fail(xhr)
            })
        },
        asAdmin({ email, password }, done?, fail?) {
            mockxhr.POST("/login/session/admin", { email, password }, () => location.href = "/admin", fail)
        },
        withPhone(params, done?, fail?) {
            const { role, countryCode, phoneNumber, password } = params
            if (!phoneNumber || !password) return
            mockxhr.POST("/login/session/phone", {
                role, countryCode, phoneNumber, password,
            }, done, xhr => {
                toast.error(xhr.status === HttpStatus.FORBIDDEN ? "帐号已被禁用" : "帐号或密码不正确")
            })
        },
        withEmail(params, done?, fail?) {
            const { role, email, password } = params
            if (!email || !password) return
            mockxhr.POST("/login/session/email", {
                role, email, password,
            }, done, xhr => {
                toast.error(xhr.status === HttpStatus.FORBIDDEN ? "帐号已被禁用" : "帐号或密码不正确")
            })
        },
        sendSMS({ countryCode, phoneNumber, role }, done?, fail?) {
            if (phoneNumber.length < 5) {
                toast.error("手机号码不正确")
                return
            }
            mockxhr.POST("/login/sms?" + queryString.stringify({
                countryCode, phoneNumber, role
            }), {}, () => {
                toast.success("验证码发送成功")
                if (done) done()
            }, xhr => {
                if (xhr.status === HttpStatus.FORBIDDEN) {
                    toast.error("手机号不存在")
                } else {
                    console.warn("该报错已被隐藏，来源sendSMS")
                    // toast.error(xhr.statusText)
                }
            })
        },
        keyanquanSMS({ countryCode, phoneNumber }, done?, fail?) {
            if (phoneNumber.length < 5) {
                toast.error("手机号码不正确")
                return
            }
            mockxhr.POST("/login/keyanquan/sms?" + queryString.stringify({
                countryCode, phoneNumber,
            }), {}, () => {
                toast.success("验证码发送成功")
                if (done) done()
            }, xhr => {
                if (xhr.status === HttpStatus.CONFLICT) {
                    toast.error("手机号已被其他用户使用")
                } else {
                    console.warn("该报错已被隐藏，来源keyanquanSMS")
                    // toast.error(xhr.statusText)
                }
            })
        },
        resetPassword({ countryCode, phoneNumber, smsCode, password }, done?, fail?) {
            mockxhr.POST("/login/password/reset", {
                countryCode, phoneNumber, smsCode, password,
            }, () => {
                toast.success("密码已重置")
            }, xhr => {
                if (xhr.status === HttpStatus.FORBIDDEN) {
                    toast.error("验证码不正确")
                } else {
                    console.warn("该报错已被隐藏，来源resetPassword")
                    // toast.error(xhr.statusText)
                }
            })
        }
    },

    api: {
        article: {
            search({ type, from, size, request, df }: SearchRequest, done?, fail?) {
                mockxhr.POST(
                    "/api/articles/search?" + queryString.stringify({ type, from, size, df }),
                    request, done, fail
                )
            }
        },
        information: {
            create(params, done?, fail?) {
                mockxhr.POST("/api/information", params, done, fail)
            },
            update(params, done?, fail?) {
                mockxhr.PUT("/api/information", params, done, fail)
            },
            status({ id, status }, done?, fail?) {
                mockxhr.PUT(`/api/information/${id}/status/${status}`, {}, () => {
                    toast.success("已更新状态")
                    if (done) done()
                }, fail)
            },
            delete({ id }, done?, fail?) {
                mockxhr.DELETE(`/api/information/${id}`, {}, done, fail)
            },
            get({ id }, done?, fail?) {
                mockxhr.GET(`/api/information/${id}`, {}, done, fail)
            },
            search({ size, from, type, request }, done?, fail?) {
                mockxhr.POST(`/api/information/search?` + queryString.stringify({
                    size, from, type
                }), request, done, fail)
            },
        },

        meeting: {
            create(params, done?, fail?) {
                mockxhr.POST("/api/meetings", params, done, fail)
            },
            update(params, done?, fail?) {
                mockxhr.PUT("/api/meetings", params, done, fail)
            },
            status({ id, status }, done?, fail?) {
                mockxhr.PUT(`/api/meetings/${id}/status/${status}`, {}, () => {
                    toast.success("已更新状态")
                    if (done) done()
                }, fail)
            },
            delete({ id }, done?, fail?) {
                mockxhr.DELETE(`/api/meetings/${id}`, {}, done, fail)
            },
            get({ id }, done?, fail?) {
                mockxhr.GET(`/api/meetings/${id}`, {}, done, fail)
            },
            search({ size, from, type, request }, done?, fail?) {
                mockxhr.POST(`/api/meetings/search?` + queryString.stringify({
                    size, from, type
                }), request, done, fail)
            },
            signUp({ id }, done?, fail?) {
                mockxhr.POST(`/api/meetings/${id}/signup`, {}, done, fail)
            },
            signUpStatus({ id }, done?, fail?) {
                mockxhr.GET(`/api/meetings/${id}/signup`, {}, done, fail)
            },
        },

        nickname: {
            get({ id }, done?, fail?) {
                mockxhr.GET(`/api/profile/${id}/nickname`, {}, done, fail)
            }
        },

        filters: {
            list(params, done?, fail?) {
                let { table, field, size } = params
                if (size === undefined) size = 100
                mockxhr.GET(`/api/filters/${table}/${field}?size=${size}`, {}, done, fail)
            }
        },

        admin: {
            employer: {
                certify({ id, msgType, content }, done?, fail?) {
                    mockxhr.POST(`/api/admin/employers/${id}/certify`, {
                        msgType, content
                    }, done, fail)
                },
            },
            user: {
                session(done?, fail?) {
                    mockxhr.GET("/api/admin/session", {}, resp => {
                        db$.login.insert({ _id: "session", ...resp })
                        if (done) done(resp)
                    }, fail)
                },
                get({ id }, done?, fail?) {
                    mockxhr.GET("/api/admin/users/" + id, {}, done, fail)
                },
                list({ from, size }, done?, fail?) {
                    mockxhr.GET("/api/admin/users?" + queryString.stringify({
                        from, size
                    }), {}, done, fail)
                },
                create(params, done?, fail?) {
                    mockxhr.POST("/api/admin/users", params, () => {
                        toast.success("保存成功")
                        if (done) done()
                    }, fail)
                },
                update(params, done?, fail?) {
                    mockxhr.PUT("/api/admin/users", params, () => {
                        toast.success("保存成功")
                        if (done) done()
                    }, fail)
                },
                delete({ id }, done?, fail?) {
                    mockxhr.DELETE("/api/admin/users/" + id, {}, done, fail)
                },
                search({ type, request }, done?) {
                    mockxhr.POST("/api/admin/users/search?type=" + type, {
                        query: request.query
                    }, done)
                }
            },
            hr: {
                refreshEmailToken({ id }, done?, fail?) {
                    mockxhr.POST(`/api/admin/hr/${id}/email/token`, {}, done, fail)
                }
            },
            message: {
                send({ msgType, title, content, contextId, receiverId }, done?) {
                    mockxhr.POST("/api/admin/messages", {
                        msgType, title, content, contextId, receiverId,
                    }, done)
                },
                search({ size, from, type, request }, done?, fail?) {
                    mockxhr.POST(`/api/admin/messages/search?` + queryString.stringify({
                        size, from, type
                    }), request, done, fail)
                },
                group({ role, action, ...params }, done?) {
                    mockxhr.POST(`/api/admin/groups/${role}/${action}`, params, done)
                },
            },
            crawler: {
                deletePage({ id }, done?, fail?) {
                    mockxhr.DELETE(`/api/admin/crawler/pages/${id}`, {}, done, fail)
                }
            },
        },

        action: {
            get({ type, targetId }, done?, fail?) {
                mockxhr.GET(`/api/actions/${type}/${targetId}`, {}, done, fail)
            },
            list({ type }, done?, fail?) {
                mockxhr.GET(`/api/actions/${type}`, {}, done, fail)
            },
        },

        account: {
            sendSMSToNewPhone({ countryCode, phoneNumber }, done?, fail?) {
                if (phoneNumber.length < 5) {
                    toast.error("手机号码不正确")
                    return
                }
                mockxhr.POST("/api/account/phone/update/sms?" + queryString.stringify({
                    countryCode, phoneNumber,
                }), {}, () => {
                    toast.success("验证码发送成功")
                    if (done) done()
                }, xhr => {
                    if (xhr.status === HttpStatus.CONFLICT) {
                        toast.error("手机号已被使用")
                    } else {
                        console.warn("该报错已被隐藏，来源sendSMSToNewPhone")
                        // toast.error(xhr.statusText)
                    }
                })
            },
            updatePhone({ smsCode, verifyCode }, done?, fail?) {
                mockxhr.PUT(`/api/account/phone?smsCode=${smsCode}&verifyCode=${verifyCode}`, {}, () => {
                    toast.success("重置成功")
                    if (done) done()
                }, xhr => {
                    if (xhr.status === HttpStatus.FORBIDDEN) {
                        toast.error("验证码不正确")
                    } else {
                        toast.error("重置失败")
                    }
                })
            },
            updatePassword({ newPassword, password }, done?, fail?) {
                mockxhr.PUT("/api/account/password", {
                    password, newPassword,
                }, () => {
                    toast.success("保存成功")
                }, () => {
                    toast.error("密码错误")
                })
            },
            unbindWechat(done?, fail?) {
                mockxhr.DELETE(`/api/account/wechat`, {}, () => {
                    location.reload()
                }, fail)
            },
            updateNavLinks(items: any[], done?, fail?) {
                mockxhr.PUT(`/api/account/navlinks`, items, done, fail)
            },
        },

        profile: {
            get(done?, fail?) {
                mockxhr.GET("/api/profile", {}, resp => {
                    db$.login.save({ _id: "profile", ...resp })
                    if (done) done(resp)
                }, xhr => {
                    //console.log(xhr)
                })
            },
            unbindEmail(done?, fail?) {
                mockxhr.DELETE(`/api/profile/email`, {}, () => {
                    location.reload()
                }, fail)
            },
            updateEmail({ email }, done?, fail?) {
                if (isEmail(email.trim())) {
                    mockxhr.PUT("/api/profile/email?email=" + email, {}, done, xhr => {
                        if (xhr.status === HttpStatus.CONFLICT) {
                            toast.error("邮箱已被其他用户使用")
                        } else {
                            console.warn("该报错已被隐藏，来源updateEmail")
                            // toast.error(xhr.statusText)
                        }
                    })
                } else {
                    toast.error("邮箱格式不正确")
                }
            },
            updateAccount(params, done?, fail?) {
                mockxhr.PUT("/api/profile/account", params, resp => {
                    toast.success("保存成功")
                    db$.login.save({ _id: "profile", ...params })
                    if (done) done(resp)
                }, fail)
            },
            updateResume(done?, fail?) {
                let profile = db$.login.findOne({ _id: "profile" })
                mockxhr.PUT("/api/profile/resume", { profileZh: profile.profileZh }, resp => {
                    toast.success("保存成功")
                    profile.updatedAt = Date.now()
                    db$.login.save(profile)
                    if (done) done(resp)
                }, fail)
            },
            star({ type, target, url }, done?, fail?) {
                mockxhr.POST("/api/profile/starred", { type, target, url }, done, fail)
            },
            unstar({ type, target }, done?, fail?) {
                mockxhr.DELETE(`/api/profile/starred/${type}/${target}`, {}, done, fail)
            },
            starred({ from, size }, done?, fail?) {
                mockxhr.GET(`/api/profile/starred?from=${from}&size=${size}`, {}, done, fail)
            },
            starredTarget({ from, size, target }, done?, fail?) {
                mockxhr.GET(`/api/profile/starred?from=${from}&size=${size}&target=${target}`, {}, res => {
                    if (res.total === 0) {
                        if (fail) fail()
                    } else if (done) {
                        done(res)
                    }
                }, fail)
            },
        },

        job: {
            get({ id }, done?, fail?) {
                mockxhr.GET("/api/jobs/" + id, {}, resp => {
                    resp._name = resp.name
                    if (done) done(resp)
                }, fail)
            },
            search({ from, size, filters, query, sort, type }: any, done?, fail?) {
                mockxhr.POST("/api/jobs/search?" + queryString.stringify({ from, size, type }), {
                    query, filters, sort,
                }, done, fail)
            },
            create(params, done?, fail?) {
                mockxhr.POST("/api/jobs", params, resp => {
                    message.success('发布成功')
                    if (done) setTimeout(() => done(resp), 2000)
                }, fail)
            },
            update(params, done?, fail?) {
                mockxhr.PUT("/api/jobs", params, resp => {
                    toast.success("保存成功")
                    if (done) setTimeout(() => done(resp), 2000)
                }, fail)
            },
            online({ id, value }, done?, fail?) {
                mockxhr.PUT(`/api/jobs/${id}/online/${value}`, {}, done, fail)
            },
            delete({ id }, done?, fail?) {
                mockxhr.DELETE(`/api/jobs/${id}`, {}, done, fail)
            },
            similar(job, done?, fail?) {
                mockxhr.POST("/api/jobs/similar", job, done, fail)
            },
        },

        hr: {
            status({ id, status, message }, done) {
                mockxhr.PUT(`/api/hr/${id}/status/${status}`, {}, () => {
                    toast.success(message)
                    done()
                })
            },
            get(done?, fail?) {
                mockxhr.GET("/api/hr", {}, resp => {
                    db$.login.save({ _id: "hr", ...resp })
                    if (done) done(resp)
                }, fail)
            },
            getById({ id }, done?, fail?) {
                mockxhr.GET("/api/hr/" + id, {}, done, fail)
            },
            update(params, done?, fail?) {
                mockxhr.PUT("/api/hr", params, resp => {
                    toast.success("保存成功")
                    db$.login.save({ _id: "hr", ...params })
                    if (done) done(resp)
                }, fail)
            },
            employer(done?, fail?) {
                mockxhr.GET("/api/hr/employer", {}, done, fail)
            },
            updateEmployerCert(params, done?, fail?) {
                if (params.type === "COLLEGE") {
                    params.idCardBack = undefined
                    params.idCardFront = undefined
                    params.businessLicense = undefined
                } else {
                    params.workPermitBack = undefined
                    params.workPermitFront = undefined
                }
                mockxhr.PUT("/api/hr/employer/cert", params, done, fail)
            },
            updateEmail({ email }, done?, fail?) {
                if (isEmail(email.trim())) {
                    mockxhr.PUT("/api/hr/email?email=" + email, {}, done, xhr => {
                        if (xhr.status === HttpStatus.CONFLICT) {
                            toast.error("邮箱已被其他用户使用")
                        } else {
                            console.warn("该报错已被隐藏，来源updateEmail")
                            // toast.error(xhr.statusText)
                        }
                    })
                } else {
                    toast.error("邮箱格式不正确")
                }
            },
        },

        employer: {
            get(params, done?, fail?) {
                mockxhr.GET("/api/employers/" + params.id, {}, done, fail)
            },
            block({ targetIds }, done?, fail?) {
                mockxhr.POST("/api/employers/block", targetIds, done, fail)
            },
            update({ type, form }, done?, fail?) {
                mockxhr.PUT("/api/employers?type=" + type, form, () => {
                    toast.success("保存成功")
                    if (done) done()
                }, fail)
            },
        },

        thesis: {
            create(params, done?, fail?) {
                mockxhr.POST(`/api/theses`, params, done, fail)
            },
            update(params, done?, fail?) {
                mockxhr.PUT(`/api/theses`, params, done, fail)
            },
            delete({ id }, done?, fail?) {
                mockxhr.DELETE(`/api/theses/${id}`, {}, done, fail)
            },
            get({ id }, done?, fail?) {
                mockxhr.GET(`/api/theses/${id}`, {}, done, fail)
            },
            fromURL({ url }, done?, fail?) {
                mockxhr.POST(`/api/theses/from/url?url=` + encodeURIComponent(url), {}, done, fail)
            },
            search({ from, size, type, request }, done?, fail?) {
                mockxhr.POST("/api/theses/search?" + queryString.stringify({
                    type, from, size
                }), request, done, fail)
            },
            status({ id, status }, done?, fail?) {
                mockxhr.PUT(`/api/theses/${id}/status/${status}`, {}, () => {
                    toast.success("已更新状态")
                    if (done) done()
                }, fail)
            },
            star({ id }, done?, fail?) {
                mockxhr.POST(`/api/theses/${id}/star`, {}, () => {
                    toast.success("关注成功")
                    if (done) done()
                }, fail)
            },
            unstar({ id }, done?, fail?) {
                mockxhr.DELETE(`/api/theses/${id}/star`, {}, done, fail)
            },
            comment({ id, contextId, content, anonymous }, done?, fail?) {
                mockxhr.POST(`/api/theses/${id}/comments`, {
                    contextId, content, anonymous,
                }, done, fail)
            },
            deleteComment({ id, commentId }, done?, fail?) {
                mockxhr.DELETE(`/api/theses/${id}/comments/${commentId}`, {}, done, fail)
            },
            reply({ id, msgId, content, anonymous }, done?, fail?) {
                mockxhr.POST(`/api/theses/${id}/replies/${msgId}`, {
                    content, anonymous,
                }, done, fail)
            },
            comments({ id, from, size }, done?, fail?) {
                mockxhr.GET(`/api/theses/${id}/comments?` + queryString.stringify({
                    from, size,
                }), {}, done, fail)
            },
            searchComment({ type, from, size }, done?, fail?) {
                mockxhr.POST(`/api/theses/comments/search?` + queryString.stringify({
                    from, size, type
                }), {}, done, fail)
            },
            countOriPaperClickNum({ id, paperId, type }, done?, fail?) {
                mockxhr.POST(`/api/theses/jump/original/click`, {
                    id, paperId, type
                }, done, fail)
            },
            countJournalOfficialSiteClickNum({ id, paperId, type }, done?, fail?) {
                mockxhr.POST(`/api/theses/jump/journal/click`, {
                    id, paperId, type
                }, done, fail)
            }
        },

        resume: {
            search({ from, size, filters, query, sort, type }, done?, fail?) {
                mockxhr.POST("/api/resumes/search?" + queryString.stringify({ from, size, type }), {
                    query, filters, sort
                }, done, fail)
            },
            submit(params, done?, fail?) {
                let { jobId, isMobile } = params
                mockxhr.POST("/api/resumes?jobId=" + jobId, {}, resp => {
                    if (done) done(resp)
                    if (!isMobile) toast.success("投递成功")
                }, fail)
            },
            get({ id }, done?, fail?) {
                mockxhr.GET("/api/resumes/" + id, {}, done, fail)
            },
            status({ id, status }, done?, fail?) {
                mockxhr.PUT(`/api/resumes/${id}/${status}`, {}, done, fail)
            },
            list({ }, done?, fail?) {
                mockxhr.GET("/api/resumes", {}, done, fail)
            },
        },

        subject: {
            delete(parm, done?, fail?) {
                mockxhr.POST(`/api/subject/do?type=DELECT`, parm, done, fail)
            }
        },

        source: {
            delete(parm, done?, fail?) {
                mockxhr.POST(`/api/source/do?type=DELECT`, parm, done, fail)
            }
        },

        column: {
            delete(parm, done?, fail?) {
                mockxhr.POST(`/api/column/do?type=DELECT`, parm, done, fail)
            }
        },

        periodical: {
            delete(parm, done?, fail?) {
                mockxhr.POST(`/api/periodical/do?type=DELECT&from=0&size=10`, parm, done, fail)
            },
           clickJumpStat({ checkName,clickType, platformType }, done?, fail?) {
                mockxhr.POST(`/api/periodical/click/stat`, {
                    checkName,clickType, platformType
                }, done, fail)
            }
        },

        dispay: {
            delete(parm, done?, fail?) {
                mockxhr.POST(`/api/subject/do?type=DELECT`, parm, done, fail)
            }
        }
    },
}

function isEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}
