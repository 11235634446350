import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import { actions } from "../flux"

interface State {
    items: any[]
}

export default class AdDetailRight extends React.Component<{}, State> {
    constructor(props) {
        super(props)
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        mockxhr.POST("/api/promotions/search/AD?type=_&from=0&size=99", {}, res => {
            (res.hits || []).filter(r => {
                if (r.type === "AD" && r.type1 === "PC_DETAIL_1") this.state.items[0] = r
                if (r.type === "AD" && r.type1 === "PC_DETAIL_2") this.state.items[1] = r
                if (r.type === "AD" && r.type1 === "PC_DETAIL_3") this.state.items[2] = r
                this.setState({ items: this.state.items.filter(r => !!r) })
            })
        })
    }

    render() {
        return this.state.items.map((ad, i) =>
            <div key={i} style={{ marginBottom: i === this.state.items.length - 1 ? 0 : 24 }}>
                {util.imageMask(ad.image, "100%", 214, () => actions.clickPromotion(ad.id, ad.link))}
            </div>
        )
    }
}
