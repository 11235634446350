import * as React from "react"

import * as util from "../util"
import { Box, Item, Icon } from "./index"
import { actions } from "../flux"

interface Option {
    value: string
    text?: string
    promotionId?: string
}

interface Key {
    text: string
    key: string
    options: (Option | string)[]
    subKey?: string
    fetchSubOptions?: any
    offlineOptions?: (Option | string)[]
    noMore?: boolean
}

interface Props {
    keys: Key[]
    defaultSelected?: any
    onChange?: any
}

interface State {
    selected: any
    showMore: any
    showOther: any
    subOptions: any
}

export default class Filter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            selected: this.props.defaultSelected || {},
            showMore: {},
            showOther: {},
            subOptions: {},
        }
    }

    componentDidMount() {
        this.props.keys.forEach(k => {
            let value = this.state.selected[k.key]
            if (k.fetchSubOptions && value) {
                k.fetchSubOptions(value, results => {
                    this.state.subOptions[k.key] = results
                    this.setState({})
                })
            }
        })
    }

    filter(k: Key) {
        let showMore = this.state.showMore[k.text]
        let showOther = this.state.showOther[k.text]
        let arr = showOther && k.offlineOptions && k.offlineOptions.length > 0 ? [...k.options, ...k.offlineOptions, "其他"] : k.offlineOptions && k.offlineOptions.length > 0 ? [...k.options, "其他"] : k.options
        let optionStyle = {
            padding: "0.5em 1em",
            borderRadius: 2,
            cursor: "pointer",
            display: "inline-block",
            marginBottom: ".25rem",
        }
        let row = (
            <Box start
                style={{
                    fontSize: ".85rem",
                    lineHeight: 1,
                    overflow: "hidden",
                    height: k.offlineOptions && k.offlineOptions.length > 0 ? "auto" : showMore ? "auto" : undefined,
                    maxHeight: k.offlineOptions && k.offlineOptions.length > 0 ? undefined : showMore ? undefined : "2em",
                    marginBottom: k.offlineOptions && k.offlineOptions.length > 0 ? 1 : showMore ? 1 : ".25rem",
                }}>
                <div className="nowrap" style={{ fontWeight: 700, lineHeight: 2 }}>
                    {k.text}
                </div>
                <div style={{ width: "1.25rem" }} />
                <Item flex>
                    <div className={this.state.selected[k.key] ? "" : "bg-color-primary"}
                        style={{ ...optionStyle, color: this.state.selected[k.key] ? "inherit" : "white" }}
                        onClick={() => {
                            delete this.state.selected[k.key]
                            if (k.subKey) {
                                delete this.state.selected[k.subKey]
                                delete this.state.subOptions[k.key]
                            }
                            this.setState({}, () => this.props.onChange && this.props.onChange(this.state.selected))
                        }}>
                        全部
                    </div>
                    {
                        arr.map(opt => {
                            let value, text
                            if (typeof opt === "string") {
                                value = text = opt
                            } else {
                                value = opt.value
                                text = opt.text || opt.value
                            }
                            let active = this.state.selected[k.key] === value
                            let style = { color: active ? "white" : "inherit" }
                            let onClick = () => {
                                if (typeof opt !== "string" && opt.promotionId) {
                                    setTimeout(() => actions.clickPromotion(opt.promotionId), 0)
                                }
                                this.state.selected[k.key] = value
                                this.setState({}, () => this.props.onChange && this.props.onChange(this.state.selected))
                                if (k.fetchSubOptions) {
                                    k.fetchSubOptions(value, results => {
                                        this.state.subOptions[k.key] = results
                                        this.setState({})
                                    })
                                }
                            }
                            return (
                                <div 
                                    className={active ? "bg-color-primary" : ""}
                                    style={{ ...optionStyle, ...style }} 
                                    onClick={onClick}>
                                    {text}
                                </div>
                            )
                        })
                    }
                </Item>
                <div style={{ width: "1.25rem" }} />
                {   
                    k.offlineOptions && k.offlineOptions.length > 0 ?
                    <div 
                        style={{ ...optionStyle }}
                        onClick={() => {
                            this.state.showOther[k.text] = !showOther
                            this.setState({})
                        }}>
                        <span className="text-gray">
                            {showOther ? "收起" : "更多"}
                        </span>
                        {showOther ?
                            <Icon name="caret up ml05 text-primary" /> :
                            <Icon name="caret down ml05 text-primary" />
                        }
                    </div> :
                    null
                }
                {
                    !k.noMore ?
                    (<div className="clickable" style={{ lineHeight: 2 }}
                        onClick={() => {
                            this.state.showMore[k.text] = !showMore
                            this.setState({})
                        }}>
                        <span className="text-gray">
                            {showMore ? "收起" : "更多"}
                        </span>
                        {showMore ?
                            <Icon name="caret up ml05 text-primary" /> :
                            <Icon name="caret down ml05 text-primary" />
                        }
                    </div>) :
                    null
                }
            </Box>
        )

        return (
            <div>
                {row}
                {this.state.subOptions[k.key] ? (
                    <Box className="line-height">
                        {util.minWidth("4em")}
                        {util.minWidth("1.25rem")}
                        <Item flex style={{ background: "white", padding: ".5em", fontSize: ".85rem" }}>
                            {this.state.subOptions[k.key].map(x => {
                                let selected = this.state.selected[k.subKey as any] === x.value
                                let style = {
                                    display: "inline-block",
                                    padding: "0.5em 1em",
                                    background: selected ? "#e5e5e5" : "white",
                                    cursor: "pointer",
                                }
                                let onClick = () => {
                                    if (x.promotionId) setTimeout(() => actions.clickPromotion(x.promotionId), 0)
                                    this.state.selected[k.subKey as any] = x.value
                                    this.setState({}, () => this.props.onChange && this.props.onChange(this.state.selected))
                                }
                                return (
                                    <div style={style}>
                                        <span onClick={onClick}>
                                            {x.text}
                                        </span>
                                        {selected ?
                                            <Icon name="remove circle" className="clickable text-primary ml05"
                                                style={{ fontSize: "1.1em", verticalAlign: "middle", marginLeft: ".2em" }}
                                                onClick={e => {
                                                    delete this.state.selected[k.subKey as any]
                                                    this.setState({}, () => this.props.onChange && this.props.onChange(this.state.selected))
                                                }} />
                                            : null
                                        }
                                    </div>
                                )
                            })}
                        </Item>
                    </Box>
                ) : null}
            </div>
        )
    }

    render() {
        let filters: any[] = [this.filter(this.props.keys[0])]
        for (let i = 1; i < this.props.keys.length; i++) {
            filters.push(<div style={{ height: ".325rem" }} />)
            filters.push(this.filter(this.props.keys[i]))
        }

        return (
            <Box centerJustified>
                <div className="panel2 fix-view text-gray"
                    style={{ padding: "1.25rem 1.25rem 1rem", background: "#fafafa" }}>
                    {filters}
                </div>
            </Box>
        )
    }
}
