import * as React from "react"

import * as util from "../util"
import { Box, Item, Select } from "."
import { actions } from "../flux"
import { Input } from 'antd'

interface Props {
    relevant: any[]
    length: number
    expandOption?: any[]
    hideType?: string
    done?: () => any
    sclecterChange?: () => any
}

interface State {
    hits: any
    options: any[][]
}

export default class Edit extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            options: [],
            hits: {},
        }
    }

    render() {
        for (let i = 0; i < this.props.length; i++) {
            this.props.relevant[i] = this.props.relevant[i] || { type: "THESIS" }
            this.state.options[i] = this.state.options[i] || []
            let id = this.props.relevant[i].id
            if (!this.state.options[i].some(opt => opt.value === id) && id) {
                this.state.options[i].push({
                    value: id, text: this.props.relevant[i].target,
                    className: "ellipsis",
                })
            }
            if (id) this.state.hits[id] = this.state.hits[id] || this.props.relevant[i]
        }
        return (
            <div>
                {Array.apply(null, { length: this.props.length }).map((_, i) =>
                    <Box key={i} style={{ marginBottom: "1rem" }}>
                        <Item style={{ minWidth: "8.75rem" }}>
                            <Select fluid value={this.props.relevant[i].type}
                                options={
                                    this.props.expandOption ?
                                    this.props.expandOption
                                    :
                                    [
                                        { value: "THESIS", text: "论文" },
                                        { value: "JOB", text: "招聘" },
                                        { value: "CAREER", text: "生涯" },
                                        { value: "INFORMATION", text: "新闻" },
                                        { value: "EVENT", text: "活动" },
                                        { value: "", text: "未选择" },
                                    ]
                                }
                                onChange={(_, data) => {
                                    this.props.relevant[i].type = data.value
                                    this.props.relevant[i].id = ""
                                    this.props.relevant[i].source = ""
                                    this.props.relevant[i].target = ""
                                    this.props.relevant[i].logo = ''
                                    this.state.options[i] = []
                                    this.setState({})
                                    if (this.props.done) this.props.done()
                                    if (this.props.sclecterChange) this.props.sclecterChange()
                                }} />
                        </Item>
                        {util.minWidth(".625rem")}
                        <Item flex>
                            {this.props.relevant[i].type !==this.props.hideType ? <Select 
                                fluid 
                                search 
                                icon={null}
                                value={this.props.relevant[i].id || ""}
                                disabled={!this.props.relevant[i].type}
                                options={this.state.options[i]}
                                onSearchChange={(_, value) => {
                                    switch (this.props.relevant[i].type) {
                                        case "THESIS":
                                            actions.api.thesis.search({
                                                from: 0, size: 20, type: "ADMIN",
                                                request: {
                                                    query: value,
                                                    filters: { "thesis.status": true },
                                                    sort: [{ onlineTime: "DESC" }],
                                                }
                                            }, res => {
                                                res.hits.forEach(hit => this.state.hits[hit.id] = hit)
                                                this.state.options[i] = res.hits.map(hit => ({
                                                    value: hit.id, text: hit.title,
                                                    className: "ellipsis",
                                                }))
                                                this.setState({})
                                            })
                                            break
                                        case "JOB":
                                            actions.api.job.search({
                                                from: 0, size: 20, type: "ADMIN",
                                                query: value,
                                                filters: { online: true },
                                                sort: [{ onlineTime: "DESC" }],
                                            }, res => {
                                                res.hits.forEach(hit => this.state.hits[hit.id] = hit)
                                                this.state.options[i] = res.hits.map(hit => ({
                                                    value: hit.id, text: util.jobTitle(hit),
                                                    className: "ellipsis",
                                                }))
                                                this.setState({})
                                            })
                                            break
                                        case "CAREER":
                                            actions.api.article.search({
                                                from: 0, size: 20, type: "CAREER", df: "title",
                                                request: {
                                                    query: value,
                                                    filters: { status: true },
                                                    sort: [{ onlineTime: "DESC" }],
                                                }
                                            }, res => {
                                                res.hits.forEach(hit => this.state.hits[hit.id] = hit)
                                                this.state.options[i] = res.hits.map(hit => ({
                                                    value: hit.id, text: hit.title,
                                                    className: "ellipsis",
                                                }))
                                                this.setState({})
                                            })
                                            break
                                        case "INFORMATION":
                                            actions.api.article.search({
                                                from: 0, size: 20, type: "NEWS", df: "title",
                                                request: {
                                                    query: value,
                                                    filters: { status: true },
                                                    sort: [{ onlineTime: "DESC" }],
                                                }
                                            }, res => {
                                                res.hits.forEach(hit => this.state.hits[hit.id] = hit)
                                                this.state.options[i] = res.hits.map(hit => ({
                                                    value: hit.id, text: hit.title,
                                                    className: "ellipsis",
                                                }))
                                                this.setState({})
                                            })
                                            break
                                        case "EVENT":
                                            actions.api.meeting.search({
                                                from: 0, size: 20, type: "ADMIN",
                                                request: {
                                                    query: value,
                                                    filters: { "meeting.status": true },
                                                    sort: [{ onlineTime: "DESC" }],
                                                }
                                            }, res => {
                                                res.hits.forEach(hit => this.state.hits[hit.id] = hit)
                                                this.state.options[i] = res.hits.map(hit => ({
                                                    value: hit.id, text: hit.title,
                                                    className: "ellipsis",
                                                }))
                                                this.setState({})
                                            })
                                            break
                                        default:
                                            throw "Unknown Information.relevant type"
                                    }
                                }}
                                onChange={(_, data) => {
                                    let r = this.state.hits[data.value as string]
                                    if (typeof r.source === "string" && typeof r.target === "string") {
                                        this.props.relevant[i] = util.cloneJSON(r)
                                        this.setState({})
                                        return
                                    }
                                    this.props.relevant[i].id = r.id
                                    this.props.relevant[i].logo = r.cover || ''
                                    switch (this.props.relevant[i].type) {
                                        case "THESIS":
                                            this.props.relevant[i].source = "/theses/" + r.id
                                            this.props.relevant[i].target = r.title
                                            if (this.props.done) this.props.done()
                                            break
                                        case "JOB":
                                            this.props.relevant[i].source = "/jobs/" + r.id
                                            this.props.relevant[i].target = util.jobTitle(r)
                                            if (this.props.done) this.props.done()
                                            break
                                        case "CAREER":
                                            this.props.relevant[i].source = "/careers/" + r.id
                                            this.props.relevant[i].target = r.title
                                            if (this.props.done) this.props.done()
                                            break
                                        case "INFORMATION":
                                            this.props.relevant[i].source = "/information/" + r.id
                                            this.props.relevant[i].target = r.title
                                            if (this.props.done) this.props.done()
                                            break
                                        case "EVENT":
                                            this.props.relevant[i].source = `/${r.type.toLowerCase()}s/` + r.id
                                            this.props.relevant[i].target = r.title
                                            if (this.props.done) this.props.done()
                                            break
                                        default:
                                            throw "Unknown Information.relevant type"
                                    }
                                    this.setState({})
                                }} /> :
                                <Input 
                                    size="large" 
                                    defaultValue={this.props.relevant[0].target}
                                    onChange={e => {
                                        this.props.relevant[i].target = e.target.value
                                }}/>
                            }
                        </Item>
                    </Box>
                )}
            </div>
        )
    }
}
