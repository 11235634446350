function $(key) {
    return "cache:" + key
}

export function get(key) {
    let content: any = localStorage.getItem($(key))
    if (!content) return undefined
    content = JSON.parse(content)
    if (Date.now() > content.expire) {
        localStorage.removeItem($(key))
        return undefined
    } else {
        return content.value
    }
}

export function set(key, value, expire = 72 * 3600 * 1000) {
    localStorage.setItem($(key), JSON.stringify({
        value,
        expire: Date.now() + expire,
    }))
}

export function remove(key) {
    localStorage.removeItem($(key))
}

export function ensure(key, defaultValue, expire = 72 * 3600 * 1000) {
    let value = get(key)
    if (value) {
        return { ...value, ...defaultValue }
    } else {
        set(key, defaultValue, expire)
        return defaultValue
    }
}
