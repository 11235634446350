import * as React from "react"
import { Box } from "react-polymer-layout"

import * as util from "../util"

export default class PopularAndLatest extends React.Component<any, any> {
    constructor(props) {
        super(props)
        this.state = {
            tab: 0,
        }
    }

    render() {
        let { onClick0, onClick1 } = this.props

        return (
            <Box style={{ padding: "0 1.875rem", height: "3rem" }} className="box-shadow">
                <Box vertical justified className="clickable" onClick={() => this.setState({ tab: 0 }, onClick0)}>
                    {util.minHeight("3px")}
                    <div className={this.state.tab == 0 ? "text-primary" : ""}>最新</div>
                    <div style={{ height: 3 }} className={this.state.tab === 0 ? "bg-color-primary" : ""} />
                </Box>
                {util.minWidth("3.75rem")}
                <Box vertical justified className="clickable" onClick={() => this.setState({ tab: 1 }, onClick1)}>
                    {util.minHeight("3px")}
                    <div className={this.state.tab == 1 ? "text-primary" : ""}>推荐</div>
                    <div style={{ height: 3 }} className={this.state.tab === 1 ? "bg-color-primary" : ""} />
                </Box>
            </Box>
        )
    }
}
