import * as React from "react"
import * as mockxhr from "mockxhr"
import { Box, Item } from "react-polymer-layout"
import { get } from "object-path"

import * as util from "../util"
import Modal from "./Modal"
import { Button, Icon, elements } from "."
import { actions, db$ } from "../flux"

interface Props {
    item: any
    preview?: boolean
    employer?: any
}

interface State {
    submitted: boolean
    submits: number
    session: any
}

export default class JobDetail extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            submitted: false,
            submits: 0,
            session: null,
        }
    }

    componentDidMount() {
        if (this.props.preview) {
        } else {
            mockxhr.GET(`/api/resumes/jobs/${this.props.item.id}`, {}, res => {
                if (res.length > 0) {
                    let resume = res[0]
                    if (util.formatTs(resume.createdAt) === util.formatTs(Date.now())) {
                        this.setState({ submitted: true })
                    }
                }
            }, () => { })
        }
        
        this.setState({
            session: db$.login.findOne({ _id: "session" })
        })
        
    }

    field(title, content) {
        if (!content || content === "无") return null
        return (
            <div style={{ marginTop: 40 }}>
                <Box style={{ lineHeight: 1, fontSize: 18 }}>
                    <div className="bg-color-primary" style={{ width: 4, marginRight: 10 }} />
                    {title}
                </Box>
                {util.minHeight(16)}
                <div className="text-gray user-html">
                    {content}
                </div>
            </div>
        )
    }

    render() {
        let item = this.props.item
        let session = this.state.session
        let isVisitor = session && session.id && session.openid && !session.profileId && !session.hrId

        return (
            <div style={{ background: "white" }}>
                <div style={{ padding: 24, borderBottom: "1px solid #ebebeb" }}>
                    <div style={{ fontSize: 28, lineHeight: "40px", marginTop: -6 }}>
                        {util.fullEmployerName(item.employerName || get(this.props, "employer.name"), item.department)}
                    </div>
                    <Box center style={{ marginTop: 18, lineHeight: 1 }}>
                        <div className="text-primary" style={{ fontSize: 20 }}>
                            {item.name}
                        </div>
                        {util.minWidth(24)}
                        <div style={{ fontSize: 16, whiteSpace: "pre" }}>
                            <span className="text-primary">
                                {util.salaryText(item)}
                            </span>
                            {"  /  "}
                            {item.city}
                            {"  /  "}
                            {item.minDegree}
                        </div>
                    </Box>
                    <Box end>
                        <Item flex style={{ overflow: "hidden" }}>
                            <div className="text-minor" style={{ fontSize: 14, lineHeight: "24px", marginTop: 20 }}>
                                {util.researchFields(item)}
                            </div>
                            <Box center start style={{ lineHeight: 1, marginTop: 10, color: "#999", fontSize: 14 }}>
                                <div style={{ lineHeight: "20px" }}>
                                    {item.employerType === "COLLEGE" ? "所属学科" : "所属行业"}：
                                </div>
                                <Box flex wrap style={{ overflow: "hidden", margin: "-5px 0" }}>
                                    {item.employerType === "COLLEGE" ?
                                        [...item.subjects].map(t =>
                                            <a key={t} className="text-primary border-color-primary nowrap"
                                                href={`/searchall?tab=jobs&filters.employerType=COLLEGE&filters.subjects=${encodeURIComponent(t)}`}
                                                target="_blank" style={{
                                                    fontSize: 12, borderWidth: 1, borderStyle: "solid", borderRadius: 2,
                                                    margin: "5px 10px", lineHeight: "20px", padding: "0 4px", display: "block",
                                                }}>
                                                {t}
                                            </a>
                                        )
                                        :
                                        [...item.industries].map(t =>
                                            <a key={t} className="text-primary border-color-primary nowrap"
                                                href={`/searchall?tab=jobs&filters.employerType=OTHER&filters.industries=${encodeURIComponent(t)}`}
                                                target="_blank" style={{
                                                    fontSize: 12, borderWidth: 1, borderStyle: "solid", borderRadius: 2,
                                                    margin: "5px 10px", lineHeight: "20px", padding: "0 4px", display: "block",
                                                }}>
                                                {t}
                                            </a>
                                        )
                                    }
                                </Box>
                            </Box>
                            <Box className="text-minor" style={{ fontSize: 14, lineHeight: 1, marginTop: 14 }}>
                                {`${util.formatTs(item.onlineTime)} 上线`}
                                {util.minWidth("1em")}
                                {`截止至 ${util.formatSystemDate(item.applicationDeadline)}`}
                            </Box>
                        </Item>

                        {util.minWidth(24)}

                        <Button primary style={{ height: 42, width: 114 }} disabled={this.props.preview}
                            onClick={() => {
                                // let DEGREE = ["学士", "硕士", "博士"]
                                if (isVisitor) {
                                    location.href = "/join"
                                }
                                if (this.state.submitted) {
                                    return
                                } else if (!actions.local.profile()) {
                                    location.href = "/login?redirect=" + encodeURIComponent(location.pathname + location.search)
                                } 
                                // else if (DEGREE.indexOf(actions.local.profile().profileZh.degree) < DEGREE.indexOf(item.minDegree)) {
                                //     (this.refs as any).degreeCheck.show()
                                // } 
                                else if (item.userType !== 1) {
                                    (this.refs as any).submitInfo.show()
                                } else if (util.resumeCompleted(actions.local.profile()) < 80) {
                                    (this.refs as any).completeCheck.show()
                                } else {
                                    let date = util.formatTs(Date.now(), "%Y-%m-%d")
                                    mockxhr.GET(`/api/profile/filters/SUBMIT_RESUME/${date}`, {}, res => {
                                        this.setState({ submits: res.length })
                                        if (this.state.submits >= 3) {
                                            (this.refs as any).submitLimit.show()
                                        } else {
                                            (this.refs as any).submit.show()
                                        }
                                    })
                                }
                            }}>
                            {this.state.submitted ? "已投递" : "投递简历"}
                        </Button>
                    </Box>
                </div>

                <div style={{ padding: 24 }}>
                    <div style={{ marginTop: -40 }} />
                    {this.field("职位亮点", item.advantage)}
                    {this.field("背景要求", util.jobRequirements(item, true))}
                    {this.field("工作经验", item.experience)}
                    {this.field("招聘详情", (
                        <div style={{ whiteSpace: "normal" }}
                            dangerouslySetInnerHTML={{ __html: util.filterXSS(item.description, true) }} />
                    ))}
                    {item.attachments && item.attachments.length ? (
                        <div className="inner-line-height" style={{ marginTop: 16 }}>
                            {item.attachments.map(att =>
                                <p key={att.url} style={{ marginTop: ".5em" }}>
                                    <a target="_blank" href={att.url + "?attname=" + att.filename}>
                                        <Icon name={`file ${util.getIconName(att.filename)} outline`}
                                            style={{ fontSize: "1.2em", marginRight: ".5em" }} />
                                        {att.filename}
                                    </a>
                                </p>
                            )}
                        </div>
                    ) : null}
                    {this.field("申请材料", item.applicationMaterial)}
                    {this.field("福利待遇", item.benefit)}
                    {elements.wechatQRCode(item.source, { marginTop: 40 })}
                </div>

                <Modal ref="degreeCheck" title="学历不符合要求" innerWidth="30rem"
                    confirmText="查找其他职位" cancelText
                    onConfirm={() => location.href = "/search"}
                    content={
                        <div className="text-gray text-center">
                            对不起，您的学历未能达到该职位的基本要求，
                            <br />
                            因此无法投递该职位。
                        </div>
                    } />

                <Modal ref="submitInfo" title="投递该职位" innerWidth="30rem"
                    confirmText="确认" scrollContent
                    content={
                        <div className="pre-wrap" style={{ textAlign: "left", lineHeight: 1.6 }}>
                            <b style={{ fontSize: "1.1rem" }}>申请方式</b>
                            <div style={{ margin: ".5rem 0 1.5rem" }}>
                                {item.applicationEmail}
                            </div>
                            <span className="text-minor">
                                投递简历时，邮件标题请注明“来自领研网”。
                            </span>
                        </div>
                    } />

                <Modal ref="completeCheck" title="简历完成度较低" innerWidth="30rem"
                    confirmText="提高简历完成度" cancelText
                    onConfirm={() => location.href = "/profile/resume?hint=true"}
                    content={
                        <div className="inner-line-height">
                            <p>对不起，您的简历完整程度没有达到投递要求。请您完善简历后再进行投递。</p>
                            {util.minHeight("2.5rem")}
                            <p>投递要求：简历完成度达到 80%</p>
                        </div>
                    } />

                <Modal ref="submitLimit" title="投递次数已用完" innerWidth="30rem"
                    confirmText="确认"
                    content={
                        <div className="text-gray text-center">
                            对不起，您今天已投递了 3 次简历。
                            <br />
                            请明天再来！
                        </div>
                    } />

                <Modal ref="submit" title="投递该职位" innerWidth="30rem"
                    confirmText="确认" cancelText="取消"
                    onConfirm={() => {
                        actions.api.resume.submit({ jobId: item.id }, () => this.setState({ submitted: true }))
                    }}
                    content={
                        <div className="pre-wrap" style={{ textAlign: "left", lineHeight: 1.6 }}>
                            <b style={{ fontSize: "1.1rem" }}>职位要求</b>
                            <div style={{ margin: ".5rem 0 1.5rem" }}>
                                背景要求：{util.jobRequirements(item)}
                                {util.minHeight(".5rem")}
                                学历要求：{item.minDegree}
                            </div>
                            <span className="text-minor">
                                您今天还有 {3 - this.state.submits} 次投递机会！
                            </span>
                        </div>
                    } />
            </div>
        )
    }
}
