import * as React from "react"
import "./index.less"

export default class IconPicker extends React.Component<any, any> {
    constructor(props) {
        super(props)

    }

    render() {
        let { isSelected, pickId } = this.props
        if (pickId === "1") return <DefaultIcon isSelected={isSelected} />
        if (pickId === "3") return <WinIcon isSelected={isSelected} />
        if (pickId === "4") return <ForkIcon isSelected={isSelected} />
        if (pickId === "5") return <SearchIcon isSelected={isSelected} />
        if (pickId === "6") return <StarIcon isSelected={isSelected} />
        if (pickId === "7") return <FakeIcon isSelected={isSelected} />
        if (pickId === "8") return <ReviewIcon isSelected={isSelected} />
        if (pickId === "9") return <ImportIcon isSelected={isSelected} />
        if (pickId === "10") return <GlobalIcon isSelected={isSelected} />
        if (pickId === "11") return <ResourceIcon isSelected={isSelected} />
        if (pickId === "12") return <AudioIcon isSelected={isSelected} />
        if (pickId === "13") return <ReadIcon isSelected={isSelected} />
        if (pickId === "14") return <CompassIcon isSelected={isSelected} />
        if (pickId === "15") return <WriteIcon isSelected={isSelected} />
        if (pickId === "16") return <ToolIcon isSelected={isSelected} />
        if (pickId === "17") return <RelationIcon isSelected={isSelected} />
        return <DefaultIcon isSelected={isSelected} />
    }
}

/**
 * pickId 3 “全部”图标
 */
export class WinIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M4.5,0h-3A1.5,1.5,0,0,0,0,1.5v3A1.5,1.5,0,0,0,1.5,6h3A1.5,1.5,0,0,0,6,4.5v-3A1.5,1.5,0,0,0,4.5,0ZM5,4.5a.5.5,0,0,1-.5.5h-3A.5.5,0,0,1,1,4.5v-3A.5.5,0,0,1,1.5,1h3a.5.5,0,0,1,.5.5Z" />
                            <path d="M11.5,0h-3A1.5,1.5,0,0,0,7,1.5v3A1.5,1.5,0,0,0,8.5,6h3A1.5,1.5,0,0,0,13,4.5v-3A1.5,1.5,0,0,0,11.5,0ZM12,4.5a.5.5,0,0,1-.5.5h-3A.5.5,0,0,1,8,4.5v-3A.5.5,0,0,1,8.5,1h3a.5.5,0,0,1,.5.5Z" />
                            <path d="M4.5,7h-3A1.5,1.5,0,0,0,0,8.5v3A1.5,1.5,0,0,0,1.5,13h3A1.5,1.5,0,0,0,6,11.5v-3A1.5,1.5,0,0,0,4.5,7ZM5,11.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-3A.5.5,0,0,1,1.5,8h3a.5.5,0,0,1,.5.5Z" />
                            <path d="M11.5,7h-3A1.5,1.5,0,0,0,7,8.5v3A1.5,1.5,0,0,0,8.5,13h3A1.5,1.5,0,0,0,13,11.5v-3A1.5,1.5,0,0,0,11.5,7Zm.5,4.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-3A.5.5,0,0,1,8.5,8h3a.5.5,0,0,1,.5.5Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 4 “岔路口”图标
 */
export class ForkIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M9.33,3.5,7.47,4a.14.14,0,0,0-.06.23l.19.19L6.5,5.52,5.4,4.42l.19-.19A.14.14,0,0,0,5.53,4L3.67,3.5a.15.15,0,0,0-.17.17L4,5.53a.14.14,0,0,0,.23.06l.19-.19L5.81,6.79V9a.47.47,0,0,0,.46.46h.46A.47.47,0,0,0,7.19,9V6.79L8.58,5.4l.19.19A.14.14,0,0,0,9,5.53l.5-1.86A.15.15,0,0,0,9.33,3.5Z" />
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm0,12A5.5,5.5,0,1,1,12,6.5,5.51,5.51,0,0,1,6.5,12Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 5 “学术求职”图标
 */
export class SearchIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,12h-5a.5.5,0,0,1-.5-.5v-6H6.5v-1H1v-1h9v1a.5.5,0,0,0,1,0v-3A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h5a.5.5,0,0,0,0-1ZM1.5,1h8a.5.5,0,0,1,.5.5v1H1v-1A.5.5,0,0,1,1.5,1Z" />
                            <path d="M12.91,12.21l-1.59-1.6a3,3,0,1,0-.71.71l1.6,1.59a.29.29,0,0,0,.42,0l.28-.28A.29.29,0,0,0,12.91,12.21ZM7.47,10.3a2,2,0,1,1,2.83,0A2,2,0,0,1,7.47,10.3Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 6 星级图标，如”Springer Nature OA“、”IEEE学术快报“、”KeAi Journals“
 */
export class StarIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-star-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M12.9,5.28a.33.33,0,0,0-.17-.56l-4-.6a.33.33,0,0,1-.23-.18L6.78.18a.31.31,0,0,0-.56,0L4.45,3.94a.33.33,0,0,1-.23.18l-3.95.6a.33.33,0,0,0-.17.56L3,8.2A.31.31,0,0,1,3,8.49l-.67,4.12a.31.31,0,0,0,.45.35l3.53-2a.32.32,0,0,1,.3,0l3.53,2a.31.31,0,0,0,.45-.35L10,8.49a.31.31,0,0,1,.09-.29Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 7 “学术造假”图标
 */
export class FakeIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M8.5,12h-7a.5.5,0,0,1-.5-.5v-6h9a.5.5,0,0,0,1,0v-4A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h7a.5.5,0,0,0,0-1ZM1,4.5v-1h9v1ZM1.5,1h8a.5.5,0,0,1,.5.5v1H1v-1A.5.5,0,0,1,1.5,1Z" />
                            <path d="M11,6.5a2,2,0,0,0-2,2,.5.5,0,0,0,1,0,1,1,0,1,1,1,1,.5.5,0,0,0,0,1,2,2,0,0,0,0-4Z" />
                            <circle cx="11" cy="12" r="1" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 8 “同行评审”图标
 */
export class ReviewIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,12h-5a.5.5,0,0,1-.5-.5v-6h9a.5.5,0,0,0,1,0v-4A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h5a.5.5,0,0,0,0-1ZM1,1.5A.5.5,0,0,1,1.5,1h8a.5.5,0,0,1,.5.5v1H1Zm0,2h9v1H1Z" />
                            <path d="M9.5,6A3.5,3.5,0,1,0,13,9.5,3.5,3.5,0,0,0,9.5,6Zm0,6A2.5,2.5,0,1,1,12,9.5,2.5,2.5,0,0,1,9.5,12Z" />
                            <path d="M10.48,8.24a.29.29,0,0,0-.42,0L8.36,9.93l-.21.92.92-.21,1.69-1.7a.29.29,0,0,0,0-.42Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 9 “论文投稿”图标
 */
export class ImportIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M8.5,12h-7a.5.5,0,0,1-.5-.5v-6h9v2a.5.5,0,0,0,1,0v-6A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h7a.5.5,0,0,0,0-1ZM1,4.5v-1h9v1ZM1.5,1h8a.5.5,0,0,1,.5.5v1H1v-1A.5.5,0,0,1,1.5,1Z" />
                            <path d="M13,12.69s0,0,0,0a.45.45,0,0,0,0-.16h0v-2a.5.5,0,0,0-.5-.5.5.5,0,0,0-.5.5v.79L9.32,8.61a.5.5,0,0,0-.71.71L11.29,12H10.5a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h2a.41.41,0,0,0,.16,0h0a.36.36,0,0,0,.16-.11h0A.36.36,0,0,0,13,12.69Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 10 “更平等的世界”图标
 */
export class GlobalIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm5.42,5.6a22.67,22.67,0,0,1-2.25.58,11.74,11.74,0,0,0-1.8-5A5.51,5.51,0,0,1,11.92,5.6ZM6.5,1h0A10.72,10.72,0,0,1,8.71,6.35a17.19,17.19,0,0,1-4.43.09A10.72,10.72,0,0,1,6.46,1Zm-1.37.18A11.64,11.64,0,0,0,3.32,6.3a10.91,10.91,0,0,1-2.25-.64A5.52,5.52,0,0,1,5.13,1.18ZM1,6.71a11.63,11.63,0,0,0,2.29.61,7.86,7.86,0,0,0,1.62,4.45A5.51,5.51,0,0,1,1,6.71ZM6.5,12H6.44A7.3,7.3,0,0,1,4.29,7.46,15.6,15.6,0,0,0,6,7.56a18.94,18.94,0,0,0,2.68-.21A7.2,7.2,0,0,1,6.56,12Zm1.58-.23A7.78,7.78,0,0,0,9.7,7.18,23.16,23.16,0,0,0,12,6.61,5.49,5.49,0,0,1,8.08,11.77Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 11 “学习资源”图标
 */
export class ResourceIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M11.5,2H9V1.5A1.5,1.5,0,0,0,7.5,0h-6A1.5,1.5,0,0,0,0,1.5v8A1.5,1.5,0,0,0,1.5,11H4v.5A1.5,1.5,0,0,0,5.5,13h6A1.5,1.5,0,0,0,13,11.5v-8A1.5,1.5,0,0,0,11.5,2ZM4,10H1.5A.5.5,0,0,1,1,9.5v-1H4ZM4,7.5H1v-1H4Zm0-4v2H1v-4A.5.5,0,0,1,1.5,1h6a.5.5,0,0,1,.5.5V2H5.5A1.5,1.5,0,0,0,4,3.5Zm8,8a.5.5,0,0,1-.5.5h-6a.5.5,0,0,1-.5-.5v-1h7Zm0-2H5v-1h7Zm0-2H5v-4A.5.5,0,0,1,5.5,3h6a.5.5,0,0,1,.5.5Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 12 “科学60”秒图标
 */
export class AudioIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm0,12A5.5,5.5,0,1,1,12,6.5,5.51,5.51,0,0,1,6.5,12Z" />
                            <path d="M5.46,4.16A.31.31,0,0,0,5,4.43V8.57a.31.31,0,0,0,.46.27L9.05,6.76a.3.3,0,0,0,0-.52Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 13 “论文阅读”图标
 */
export class ReadIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M5.5,12h-4a.5.5,0,0,1-.5-.5v-4h9a.5.5,0,0,0,1,0v-6A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h4a.5.5,0,0,0,0-1ZM1,6.5v-1h9v1Zm0-2v-1h9v1ZM1.5,1h8a.5.5,0,0,1,.5.5v1H1v-1A.5.5,0,0,1,1.5,1Z" />
                            <path d="M9.5,9C7.5,9,6,9.86,6,11s1.5,2,3.5,2,3.5-.86,3.5-2S11.5,9,9.5,9ZM7,11c0-.41,1-1,2.5-1a1,1,0,0,0,0,2C8,12,7,11.41,7,11Zm2.5,1h0a1,1,0,0,0,0-2h0c1.53,0,2.5.59,2.5,1S11,12,9.5,12Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 14 “求生指南”图标
 */
export class CompassIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm0,12A5.5,5.5,0,1,1,12,6.5,5.51,5.51,0,0,1,6.5,12Z" />
                            <path d="M7.25,5.2h0L3.59,4.5A.29.29,0,0,0,3.31,5L5.75,7.8h0l3.66.7A.29.29,0,0,0,9.69,8ZM8.4,7.92l-2-.39a.3.3,0,0,1-.2-.44l.68-1.18a.3.3,0,0,1,.48,0L8.68,7.43A.3.3,0,0,1,8.4,7.92Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 15 “论文写作”图标
 */
export class WriteIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M8.5,12h-7a.5.5,0,0,1-.5-.5v-4H6.5v-1H1v-1h9v2a.5.5,0,0,0,1,0v-6A1.5,1.5,0,0,0,9.5,0h-8A1.5,1.5,0,0,0,0,1.5v10A1.5,1.5,0,0,0,1.5,13h7a.5.5,0,0,0,0-1ZM1,4.5v-1h9v1ZM1.5,1h8a.5.5,0,0,1,.5.5v1H1v-1A.5.5,0,0,1,1.5,1Z" />
                            <path d="M12.91,12.21,9.09,8.39l-.92-.22.22.92,3.82,3.82a.29.29,0,0,0,.42,0l.28-.28A.29.29,0,0,0,12.91,12.21Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 16 “效率提升”图标
 */
export class ToolIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M9.8,3.52s0,0,0,0a.57.57,0,0,0-.11-.16h0a.57.57,0,0,0-.16-.11h0a.8.8,0,0,0-.15,0h-2a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h.79L3.32,9a.49.49,0,0,0,.7.7l4.81-4.8v.79a.5.5,0,0,0,.5.5.5.5,0,0,0,.5-.5v-2h0A.8.8,0,0,0,9.8,3.52Z" />
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm0,12A5.5,5.5,0,1,1,12,6.5,5.51,5.51,0,0,1,6.5,12Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 * pickId 17 “师生关系”图标
 */
export class RelationIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M6.5,0A6.5,6.5,0,1,0,13,6.5,6.51,6.51,0,0,0,6.5,0Zm3.95,10.32a.29.29,0,0,0,.05-.13A3.07,3.07,0,0,0,8.27,7.25a2.15,2.15,0,0,0-.85-4.13,2.33,2.33,0,0,0-.53.07.3.3,0,0,0-.11-.32,2.15,2.15,0,1,0-2,3.76,3.08,3.08,0,0,0-2.23,3,.31.31,0,0,0,.62,0A2.46,2.46,0,0,1,5.58,7.12a2,2,0,0,1,.49,0,.34.34,0,0,0,.24-.06,2.29,2.29,0,0,0,.27.14,3.06,3.06,0,0,0-2.23,2.94.3.3,0,0,0,.3.31A.31.31,0,0,0,5,10.19a2.46,2.46,0,0,1,4.92,0,.31.31,0,0,0,.31.31.29.29,0,0,0,.13-.05,5.51,5.51,0,1,1,.13-.13ZM5.27,5.27a2.13,2.13,0,0,0,.21.91,1.53,1.53,0,0,1,.1-3.06,1.62,1.62,0,0,1,.85.25A2.14,2.14,0,0,0,5.27,5.27Zm.61,0A1.54,1.54,0,1,1,7.42,6.81,1.54,1.54,0,0,1,5.88,5.27Z" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}

/**
 *  默认图标
 */
export class DefaultIcon extends React.Component<any, any> {
    constructor(props) {
        super(props)
    }

    render() {
        let { isSelected } = this.props
        return (
            <div className={(isSelected ? "column-icon-active" : "column-icon") + " column-icon-container"}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13">
                    <g id="图层_2" data-name="图层 2">
                        <g id="图层_1-2" data-name="图层 1">
                            <path d="M4.5,11h-3A1.5,1.5,0,0,1,0,9.5v-8A1.5,1.5,0,0,1,1.5,0h6A1.5,1.5,0,0,1,9,1.5v1H8v-1A.5.5,0,0,0,7.5,1h-6a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h3Z" />
                            <rect x="0.5" y="2.5" width="4" height="1" />
                            <rect x="0.5" y="4.5" width="4" height="1" />
                            <rect x="0.5" y="6.5" width="4" height="1" />
                            <path d="M11.5,13h-6A1.5,1.5,0,0,1,4,11.5v-8A1.5,1.5,0,0,1,5.5,2h6A1.5,1.5,0,0,1,13,3.5v8A1.5,1.5,0,0,1,11.5,13ZM5.5,3a.5.5,0,0,0-.5.5v8a.5.5,0,0,0,.5.5h6a.5.5,0,0,0,.5-.5v-8a.5.5,0,0,0-.5-.5Z" />
                            <rect x="4.5" y="4.5" width="8" height="1" />
                            <rect x="4.5" y="6.5" width="8" height="1" />
                            <rect x="4.5" y="8.5" width="3.63" height="1" />
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}