import { notify } from "react-notify-toast"

const toast = {
    success(message: string, time = 3000) {
        notify.show(message, "custom", time, {
            background: location.pathname === "/admin" ? "#315c82" : "#290382",
            text: "#ffffff",
        })
    },
    error(message: string, time = 3000) {
        notify.show(message, "custom", time, {
            background: "#db2828",
            text: "#ffffff",
        })
    },
    serverError() {
        toast.error("服务器错误")
    },
}

export default toast
