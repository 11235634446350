import * as React from "react"
import * as mockxhr from "mockxhr"

import * as util from "../util"
import { API, actions } from "../flux"
import { Box, Item } from "./index"
import '../styles/desktop/Footer2.less'

interface Props {
}

interface State {
    friendLinks: any[]
}

export default class Footer extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            friendLinks: [],
        }
    }

    componentDidMount() {
        mockxhr.POST(API.promotion.search("FRIEND_LINK", "_", 99, 0), {}, resp => {
            this.setState({ friendLinks: resp.hits })
        })
    }

    link(text: string, url: string) {
        return <a href={url} style={{ color: "inherit", marginTop: 20 }}>{util.lineHeight1(text)}</a>
    }

    partnerLink(i: number, j: number) {
        let link1: any = this.state.friendLinks[i]
        let link2: any = this.state.friendLinks[j]
        let props1 = {
            style: { marginBottom: 22, display: "block", color: "inherit" },
            target: "_blank",
            href: link1 && link1.link,
            onClick: () => actions.clickPromotion(link1.id),
        }
        let props2 = {
            style: { marginBottom: 22, display: "block", color: "inherit" },
            target: "_blank",
            href: link2 && link2.link,
            onClick: () => actions.clickPromotion(link2.id),
        }
        return (
            <div style={{ fontSize: 16, lineHeight: 1 }}>
                {link1 ? <a {...props1} className="clickable">{link1.title}</a> : null}
                {link2 ? <a {...props2} className="clickable">{link2.title}</a> : null}
            </div>
        )
    }

    render() {
        return (
            <div className="min-view-center"
                style={{
                    background: "#333", color: "#999",
                    paddingTop: 40, paddingBottom: 30,
                }}>
                <div>
                    <Box>
                        {util.logo({ height: 75, marginTop: -5 }, "-baidu")}
                        {util.minWidth(120)}
                        <Box vertical style={{ width: 360 }}>
                            <div style={{ lineHeight: "40px", fontSize: 18, fontWeight: 700, color: "#fff" }}>
                                联系我们
                            </div>
                            <Item flex />
                            {util.lineHeight1("电话：010-85325810", 16, { marginTop: 20 })}
                            {util.lineHeight1("邮箱：contact@linkresearcher.com", 16, { marginTop: 20 })}
                            {util.lineHeight1("工作日：周一至周五 9:00-17:30", 16, { marginTop: 20 })}
                        </Box>
                        <Box vertical>
                            <div style={{ lineHeight: "40px", fontSize: 18, fontWeight: 700, color: "#fff" }}>
                                服务
                            </div>
                            <Item flex />
                            <Box>
                                {this.link("广告投放", "/about/ad")}
                                {util.minWidth("4em")}
                                {this.link("上传论文", "/about/thesis")}
                            </Box>
                            <Box>
                                {this.link("发布招聘", "/about/recruit")}
                                {util.minWidth("4em")}
                                {this.link("联系我们", "/about/contact")}
                            </Box>
                            <Box>
                                {this.link("帮助中心", "/about/help")}
                                {util.minWidth("4em")}
                                {this.link("关于我们", "/about/us")}
                            </Box>
                        </Box>
                        <Item flex />
                        <div className="text-center" style={{ marginRight: '15px' }}>
                            <div style={{ lineHeight: "34px", fontSize: 10 }}>
                                关注领研网微信服务号
                            </div>
                            <div style={{ lineHeight: 1 }}>
                                <img src={util.imageURL("qrcode-new.jpg")} style={{ height: 105, marginTop: 6 }} />
                            </div>
                        </div>
                        <div className="text-center">
                            <div style={{ lineHeight: "34px", fontSize: 10 }}>
                                关注科研圈微信公众号
                            </div>
                            <div style={{ lineHeight: 1 }}>
                                <img src={util.imageURL("qrcode-2.jpg")} style={{ height: 105, marginTop: 6 }} />
                            </div>
                        </div>
                    </Box>
                    {util.line("#4e4e4e", 1, { margin: "30px 0" })}
                    <div>
                        {util.lineHeight1("友情链接", 18, { color: "#fff", fontWeight: 700 })}
                        <Box justified style={{ marginTop: 30, marginBottom: 6, paddingRight: 150 }}>
                            {this.partnerLink(0, 8)}
                            {this.partnerLink(1, 9)}
                            {this.partnerLink(2, 10)}
                            {this.partnerLink(3, 11)}
                            {this.partnerLink(4, 12)}
                            {this.partnerLink(5, 13)}
                            {this.partnerLink(6, 14)}
                            {this.partnerLink(7, 15)}
                        </Box>
                    </div>
                    <div style={{ fontSize: 12, lineHeight: 1 }}>
                        www.linkresearcher.com 领研©<a href='http://beian.miit.gov.cn' target='blank' className='record-link'>京ICP备16043319号</a>  / Powered by 领研
                    </div>
                </div>
            </div>
        )
    }
}
